import React, { useEffect, useRef, useState } from "react";
import "./AdvancedFilter.css";
import { useOutsideAlerter } from "../../helpers/componentsHelper";
import { ILocationDropdown, IMarketDropdown } from "../../interfaces/IMarket";
import { useCompanyMarketsDropdownQuery } from "../../hooks/useCompanyMarketDropdown";
import { handleGetChargersByLocationId } from "../../stores/chargerStore";
import { getLocationsDropdown } from "../../stores/locationStore";
import { IChargerDropdown } from "../../interfaces/ICharger";
import { useTranslation } from "react-i18next";

interface AdvancedFilterProps {
  handleSelection: Function;
}

const AdvancedFilter: React.FC<AdvancedFilterProps> = ({ handleSelection }) => {
  const { t } = useTranslation();
  const { data: marketsData } = useCompanyMarketsDropdownQuery();

  const [expanded, setExpanded] = useState(false);
  const [markets, setMarkets] = useState<IMarketDropdown[]>([]);
  const [chargers, setChargers] = useState<IChargerDropdown[]>([]);
  const [locations, setLocations] = useState<ILocationDropdown[]>([]);
  const [selected, setSelected] = useState({
    markets: [] as IMarketDropdown[],
    locations: [] as ILocationDropdown[],
    chargers: [] as IChargerDropdown[],
  });
  const [activeElements, setActiveElements] = useState({
    market: true,
    location: false,
    chargers: false,
  });
  const [expandedElements, setExpandedElements] = useState({
    market: false,
    location: false,
    chargers: false,
  });

  useEffect(() => {
    if (marketsData) {
      setMarkets(marketsData);
      setSelected((prev) => ({
        ...prev,
        markets: marketsData,
      }));
      handleSelection({ markets: marketsData, locations: [], chargers: [] });
    }
  }, [marketsData]);

  useEffect(() => {
    if (selected.markets.length === 1) {
      setActiveElements((prev) => ({ ...prev, location: true }));
      const fetchLocations = async () => {
        const data = await getLocationsDropdown(selected.markets[0].id);
        setLocations(data);
        setSelected((prev) => ({ ...prev, locations: data }));
      };
      fetchLocations();
    } else {
      setActiveElements((prev) => ({
        ...prev,
        location: false,
        chargers: false,
      }));
      setExpandedElements((prev) => ({
        ...prev,
        location: false,
        chargers: false,
      }));
      setSelected((prev) => ({ ...prev, locations: [], chargers: [] }));
    }
  }, [selected.markets]);

  useEffect(() => {
    if (selected.locations.length === 1) {
      setActiveElements((prev) => ({ ...prev, chargers: true }));
      const fetchChargers = async () => {
        const data = await handleGetChargersByLocationId(
          selected.locations[0].id
        );
        setChargers(data);
        setSelected((prev) => ({ ...prev, chargers: data }));
      };
      fetchChargers();
    } else {
      setActiveElements((prev) => ({ ...prev, chargers: false }));
      setExpandedElements((prev) => ({ ...prev, chargers: false }));
      setSelected((prev) => ({ ...prev, chargers: [] }));
    }
  }, [selected.locations]);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setExpanded(false));

  const handleFilterChange = (
    filterName: "markets" | "locations" | "chargers",
    element: IMarketDropdown | ILocationDropdown | IChargerDropdown
  ) => {
    const currentSelection = selected[filterName] as (
      | IMarketDropdown
      | ILocationDropdown
      | IChargerDropdown
    )[];

    if (currentSelection.some((m) => m.id === element.id)) {
      if (currentSelection.length > 1) {
        const newArray = currentSelection.filter((m) => m.id !== element.id);
        setSelected((prev) => ({ ...prev, [filterName]: newArray }));
      }
    } else {
      setSelected((prev) => ({
        ...prev,
        [filterName]: [...currentSelection, element],
      }));
    }
  };

  const handleReset = () => {
    setSelected({
      markets: markets,
      locations: [],
      chargers: [],
    });
  };

  const handleApply = () => {
    handleSelection(selected);
    setExpanded(false);
  };

  return (
    <div
      className="flex flex-col justify-start items-end gap-3 relative"
      ref={wrapperRef}
    >
      <button
        className={`advanced-filter-btn ${
          expanded ? "advanced-filter-btn-active" : ""
        }`}
        onClick={() => {
          setExpanded((prev) => !prev);
        }}
      >
        <p>{t("advancedFilter.title")}</p>
        <img
          src={
            expanded
              ? "/icons/arrow/arrowUpWhite.svg"
              : "/icons/arrow/arrowDownDarkBlue.svg"
          }
          alt=""
        />
      </button>
      {expanded && (
        <div className="advanced-filter-container">
          <div className="flex flex-col justify-start w-[100%] scrollable-container">
            <div
              className={`flex flex-col px-2 py-3 w-[100%] gap-1.5 ${
                activeElements.market
                  ? "filter-component-active"
                  : "filter-component-inactive"
              }`}
            >
              <div
                className={`flex flex-row justify-between ${
                  activeElements.market && "cursor-pointer"
                }`}
                onClick={() =>
                  activeElements.market &&
                  setExpandedElements((prev) => ({
                    ...prev,
                    market: !prev.market,
                  }))
                }
              >
                <p className="text-[14px] font-medium component-name">
                  {t("advancedFilter.market")}
                </p>
                <img
                  src={
                    activeElements.market
                      ? expandedElements.market
                        ? "/icons/arrow/arrowUpDarkBlue.svg"
                        : "/icons/arrow/arrowDownDarkBlue.svg"
                      : "/icons/arrow/arrowDownLightGray.svg"
                  }
                  alt=""
                />
              </div>
              {expandedElements.market && (
                <>
                  <div className="flex gap-5">
                    <p
                      className="choose-all-clear"
                      onClick={() =>
                        setSelected((prev) => ({ ...prev, markets: markets }))
                      }
                    >
                      {t("advancedFilter.choose")}
                    </p>
                    <p
                      className="choose-all-clear"
                      onClick={() =>
                        setSelected((prev) => ({
                          ...prev,
                          markets: markets[0] ? [markets[0]] : [],
                        }))
                      }
                    >
                      {t("advancedFilter.clear")}
                    </p>
                  </div>

                  <div className="flex flex-col gap-1">
                    {marketsData &&
                      marketsData.map((market) => (
                        <div
                          key={market.id}
                          className={`market-dropdown-hoverable ${
                            selected.markets.some((m) => m.id === market.id) &&
                            " market-dropdown-selected"
                          }`}
                          onClick={() => handleFilterChange("markets", market)}
                        >
                          {selected.markets.some((m) => m.id === market.id) ? (
                            <img
                              src="/icons/correct-incorrect-icons/correct-icon-squared.svg"
                              alt=""
                              className="correct-icon"
                            />
                          ) : (
                            <div className="checker-border" />
                          )}
                          <p className="text-left">{market.name}</p>
                        </div>
                      ))}
                  </div>
                </>
              )}
            </div>
            <div
              className={`flex flex-col px-2 py-3 w-[100%] gap-1.5 ${
                activeElements.location
                  ? "filter-component-active"
                  : "filter-component-inactive"
              }`}
            >
              <div
                className={`flex flex-row justify-between ${
                  activeElements.location && "cursor-pointer"
                }`}
                onClick={() =>
                  activeElements.location &&
                  setExpandedElements((prev) => ({
                    ...prev,
                    location: !prev.location,
                  }))
                }
              >
                <p className="text-[14px] font-medium component-name">
                  {t("advancedFilter.location")}
                </p>
                <img
                  src={
                    activeElements.location
                      ? expandedElements.location
                        ? "/icons/arrow/arrowUpDarkBlue.svg"
                        : "/icons/arrow/arrowDownDarkBlue.svg"
                      : "/icons/arrow/arrowDownLightGray.svg"
                  }
                  alt=""
                />
              </div>
              {expandedElements.location && (
                <>
                  <div className="flex gap-5">
                    <p
                      className="choose-all-clear"
                      onClick={() =>
                        setSelected((prev) => ({
                          ...prev,
                          locations: locations,
                        }))
                      }
                    >
                      {t("advancedFilter.choose")}
                    </p>
                    <p
                      className="choose-all-clear"
                      onClick={() =>
                        setSelected((prev) => ({
                          ...prev,
                          locations: locations[0] ? [locations[0]] : [],
                        }))
                      }
                    >
                      {t("advancedFilter.clear")}
                    </p>
                  </div>

                  <div className="flex flex-col gap-1">
                    {locations &&
                      locations.map((location) => (
                        <div
                          key={location.id}
                          className={`market-dropdown-hoverable ${
                            selected.locations.some(
                              (m) => m.id === location.id
                            ) && " market-dropdown-selected"
                          }`}
                          onClick={() =>
                            handleFilterChange("locations", location)
                          }
                        >
                          {selected.locations.some(
                            (m) => m.id === location.id
                          ) ? (
                            <img
                              src="/icons/correct-incorrect-icons/correct-icon-squared.svg"
                              alt=""
                              className="correct-icon"
                            />
                          ) : (
                            <div className="checker-border" />
                          )}
                          <p className="text-left">{location.name}</p>
                        </div>
                      ))}
                  </div>
                </>
              )}
            </div>
            <div
              className={`flex flex-col px-2 py-3 w-[100%] gap-1.5 ${
                activeElements.chargers
                  ? "filter-component-active"
                  : "filter-component-inactive"
              }`}
            >
              <div
                className={`flex flex-row justify-between ${
                  activeElements.chargers && "cursor-pointer"
                }`}
                onClick={() =>
                  activeElements.chargers &&
                  setExpandedElements((prev) => ({
                    ...prev,
                    chargers: !prev.chargers,
                  }))
                }
              >
                <p className="text-[14px] font-medium component-name">
                  {t("advancedFilter.chargers")}
                </p>
                <img
                  src={
                    activeElements.chargers
                      ? expandedElements.chargers
                        ? "/icons/arrow/arrowUpDarkBlue.svg"
                        : "/icons/arrow/arrowDownDarkBlue.svg"
                      : "/icons/arrow/arrowDownLightGray.svg"
                  }
                  alt=""
                />
              </div>
              {expandedElements.chargers && (
                <>
                  <div className="flex gap-5">
                    <p
                      className="choose-all-clear"
                      onClick={() =>
                        setSelected((prev) => ({ ...prev, chargers: chargers }))
                      }
                    >
                      {t("advancedFilter.choose")}
                    </p>
                    <p
                      className="choose-all-clear"
                      onClick={() =>
                        setSelected((prev) => ({
                          ...prev,
                          chargers: chargers[0] ? [chargers[0]] : [],
                        }))
                      }
                    >
                      {t("advancedFilter.clear")}
                    </p>
                  </div>

                  <div className="flex flex-col gap-1">
                    {chargers.map((charger) => (
                      <div
                        key={charger.id}
                        className={`market-dropdown-hoverable ${
                          selected.chargers.some((m) => m.id === charger.id) &&
                          " market-dropdown-selected"
                        }`}
                        onClick={() => handleFilterChange("chargers", charger)}
                      >
                        {selected.chargers.some((m) => m.id === charger.id) ? (
                          <img
                            src="/icons/correct-incorrect-icons/correct-icon-squared.svg"
                            alt=""
                            className="correct-icon"
                          />
                        ) : (
                          <div className="checker-border" />
                        )}
                        <p className="text-left">{charger.ocppChargerId}</p>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex justify-end gap-2 ml-3 mt-5">
            <button className="filter-reset" onClick={handleReset}>
              {t("advancedFilter.reset")}
            </button>
            <button className="filter-apply" onClick={handleApply}>
              {t("advancedFilter.apply")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdvancedFilter;
