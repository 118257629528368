import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  PricingStore,
  handleGetConnectorForPricingGroup,
  handleGetPricingGroupById,
  handleUpdatePricingGroup,
} from "../../../stores/pricingStore";
import { useStore } from "react-stores";
import {
  IPricingGroup,
  IPricingOption,
  IPricingOptionValues,
  UpdatePricingGroupData,
} from "../../../interfaces/IPrices";
import Table from "../../../shared/table/Table";
import Paging from "../../../shared/paging/Paging";
import AddConnectors from "./AddConnectors";
import MoveConnectors from "./MoveConnectors";
import { useToast } from "../../../shared/toast/ToastContent";
import Spinner from "../../../shared/spinner/Spinner";
import { useTranslation } from "react-i18next";

const PricingGroupDetails = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<any>([]);
  const [paging, setPaging] = useState({ page: 1, perPage: 10 });
  const [pricingGroup, setPricingGroup] = useState<IPricingGroup>({
    id: 0,
    name: "",
    companyMarketId: 0,
    companyMarketName: "",
    connectorCount: 0,
    priceAmount: 0,
    priceUnit: "",
  });
  const [requirementsForSave, setRequirementsForSave] = useState(false);
  const [addNewConnectors, setAddNewConnectors] = useState(false);
  const [moveConnectors, setMoveConnectors] = useState(false);
  const [selectedPricingOptions, setSelectedPricingOptions] = useState<
    IPricingOption[]
  >([]);
  const availablePricingOptions: ("pricePerMinute" | "sessionStartPrice")[] = [
    "pricePerMinute",
    "sessionStartPrice",
  ];

  const pricingStore = useStore(PricingStore);
  const { assignedConnectorsSize, pricingGroupById, assignedConnectors } =
    pricingStore;

  const navigate = useNavigate();
  const { showToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams();
  const parsedPricingGroupId = parseInt(id!);

  const tableHeaders = [
    t("commercial.pricingPage.tableHeaders.connectorId"),
    t("commercial.pricingPage.tableHeaders.market"),
    t("commercial.pricingPage.tableHeaders.connectorType"),
    t("commercial.pricingPage.tableHeaders.electricityCurrent"),
    t("commercial.pricingPage.tableHeaders.address"),
  ];

  const tableRowOrder = [
    "connectorName",
    "companyMarketName",
    "connectorType",
    "current",
    "street",
  ];

  useEffect(() => {
    const fetchPricingGroup = async () => {
      const group = await handleGetPricingGroupById(parsedPricingGroupId);
      let optionalPricingOptions: IPricingOption[] = [];
      if (group.gracePeriod && group.parkingFeePrice) {
        optionalPricingOptions.push({
          type: "pricePerMinute",
          values: {
            gracePeriod: group.gracePeriod,
            parkingFee: group.parkingFeePrice,
          },
        });
      }
      group.startFee &&
        optionalPricingOptions.push({
          type: "sessionStartPrice",
          values: {
            sessionStartPrice: group.startFee,
          },
        });
      setSelectedPricingOptions(optionalPricingOptions);
      setPricingGroup(group);
    };
    fetchPricingGroup();
  }, [parsedPricingGroupId]);

  useEffect(() => {
    const fetchConnectors = async () => {
      await handleGetConnectorForPricingGroup(
        parsedPricingGroupId,
        paging.page,
        paging.perPage
      );
    };
    fetchConnectors();

    return () => {
      PricingStore.setState({
        ...PricingStore.state,
        assignedConnectors: [],
        assignedConnectorsSize: 0,
      });
    };
  }, [paging, parsedPricingGroupId]);

  useEffect(() => {
    if (
      pricingGroupById &&
      (pricingGroup!.name !== pricingGroupById?.name ||
        pricingGroup!.priceAmount !== pricingGroupById.priceAmount ||
        (pricingGroupById.gracePeriod &&
          pricingGroupById.parkingFeePrice &&
          (Number(
            selectedPricingOptions.find((el) => el.type === "pricePerMinute")
              ?.values.gracePeriod
          ) || undefined) !== pricingGroupById.gracePeriod &&
          selectedPricingOptions.find((el) => el.type === "pricePerMinute")
            ?.values.gracePeriod &&
          selectedPricingOptions.find((el) => el.type === "pricePerMinute")
            ?.values.parkingFee) ||
        (pricingGroupById.gracePeriod &&
          pricingGroupById.parkingFeePrice &&
          (Number(
            selectedPricingOptions.find((el) => el.type === "pricePerMinute")
              ?.values.parkingFee
          ) || undefined) !== pricingGroupById.parkingFeePrice &&
          selectedPricingOptions.find((el) => el.type === "pricePerMinute")
            ?.values.gracePeriod &&
          selectedPricingOptions.find((el) => el.type === "pricePerMinute")
            ?.values.parkingFee) ||
        (pricingGroupById.startFee &&
          (Number(
            selectedPricingOptions.find((el) => el.type === "sessionStartPrice")
              ?.values.sessionStartPrice
          ) || undefined) !== pricingGroupById.startFee) ||
        (!pricingGroupById.gracePeriod &&
          !pricingGroupById.parkingFeePrice &&
          selectedPricingOptions.find((el) => el.type === "pricePerMinute")
            ?.values.gracePeriod &&
          selectedPricingOptions.find((el) => el.type === "pricePerMinute")
            ?.values.parkingFee) ||
        (!pricingGroupById.startFee &&
          selectedPricingOptions.find((el) => el.type === "sessionStartPrice")
            ?.values.sessionStartPrice))
    ) {
      setRequirementsForSave(true);
    } else {
      setRequirementsForSave(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricingGroup, selectedPricingOptions]);

  const savePricingGroupChanges = async () => {
    setLoading(true);
    const data: UpdatePricingGroupData = {
      id: pricingGroup.id,
      name: pricingGroup.name,
      pricePerKwh: pricingGroup.priceAmount,
    };
    if (selectedPricingOptions.length > 0) {
      const pricePerMinComponent = selectedPricingOptions.find(
        (el) => el.type === "pricePerMinute"
      );
      if (
        pricePerMinComponent &&
        pricePerMinComponent.values.gracePeriod &&
        pricePerMinComponent.values.parkingFee
      ) {
        data.gracePeriod = Number(pricePerMinComponent.values.gracePeriod);
        data.parkingFeePrice = Number(pricePerMinComponent.values.parkingFee);
      }
      const sessionStartComponent = selectedPricingOptions.find(
        (el) => el.type === "sessionStartPrice"
      );
      if (
        sessionStartComponent &&
        sessionStartComponent.values.sessionStartPrice
      )
        data.startFee = Number(sessionStartComponent.values.sessionStartPrice);
    }
    handleUpdatePricingGroup(JSON.stringify(data)).then(async (res) => {
      setLoading(false);
      if (res) {
        showToast("Updated pricing group information is saved", "success");
        setPricingGroup(await handleGetPricingGroupById(parsedPricingGroupId));
      } else {
        showToast("There was an error while updating pricing group", "error");
      }
    });
  };

  const handleSelection = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const addNewPricingComponent = () => {
    if (selectedPricingOptions.length < availablePricingOptions.length) {
      const option = availablePricingOptions.find(
        (option) => !selectedPricingOptions.find((el) => el.type === option)
      );
      if (option) {
        const newOption: IPricingOption = {
          type: option,
          values: {} as IPricingOptionValues,
        };
        const prev = [...selectedPricingOptions];
        prev.push(newOption);
        setSelectedPricingOptions((prev) => prev.concat(newOption));
      }
    }
  };

  const removePricingComponent = (index: number) => {
    const optionsCopy = [...selectedPricingOptions];
    optionsCopy.splice(index, 1);
    setSelectedPricingOptions(optionsCopy);
  };

  const changePricingOption = (
    index: number,
    newType: "pricePerMinute" | "sessionStartPrice"
  ) => {
    const optionsCopy = [...selectedPricingOptions];
    optionsCopy[index].type = newType;
    optionsCopy[index].values = {} as IPricingOptionValues;
    setSelectedPricingOptions(optionsCopy);
    console.log(selectedPricingOptions);
  };

  const handlePricingComponentChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      const optionsCopy = [...selectedPricingOptions];
      optionsCopy[index].values[name] = value;
      setSelectedPricingOptions(optionsCopy);
    }
  };

  const handleErrorMessaging = () => {
    const gracePeriodValue = gracePeriodInput.value.trim();
    const parkingFeeValue = parkingFeeInput.value.trim();

    const messageDiv1 = document.getElementById("parkingFeeDiv");
    const messageDiv2 = document.getElementById("gracePeriodDiv");

    if (gracePeriodValue && !parkingFeeValue) {
      messageDiv1!.style.visibility = "visible";
    } else if (!gracePeriodValue && parkingFeeValue) {
      messageDiv2!.style.visibility = "visible";
    } else {
      messageDiv1!.style.visibility = "hidden";
      messageDiv2!.style.visibility = "hidden";
    }
  };

  const gracePeriodInput = document.getElementById(
    "gracePeriod"
  ) as HTMLInputElement;
  const parkingFeeInput = document.getElementById(
    "parkingFee"
  ) as HTMLInputElement;
  gracePeriodInput?.addEventListener("input", handleErrorMessaging);
  parkingFeeInput?.addEventListener("input", handleErrorMessaging);

  return (
    <div className="flex-1 p-3.5 flex flex-col gap-3.5 items-stretch main-wrapper">
      <div className="bg-white card p-5">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row justify-start items-center flex-wrap gap-6">
            <div
              className="cursor-pointer"
              onClick={() => navigate(`/dashboard/commercial/pricing`)}
            >
              <img src="/icons/arrow/arrowLeft.svg" alt="" />
            </div>
            <div className="pricing-groups-span">
              {t("commercial.pricingPage.titleEdit")}
            </div>
          </div>
          <button
            disabled={!requirementsForSave}
            className={`create-new-pricing-group-btn ${
              requirementsForSave ? "create-new-pricing-group-btn-active" : ""
            }`}
            onClick={() => savePricingGroupChanges()}
          >
            {t("commercial.pricingPage.saveChanges")}
          </button>
        </div>
      </div>
      <div className="bg-white card p-5">
        <div className="flex flex-col gap-5">
          <div className="flex flex-row items-center gap-5">
            <div className="flex flex-col justify-stretch items-center gap-2 w-64">
              <div className="create-edit-header">
                {t("commercial.pricingPage.market")}
              </div>
              <input
                type="text"
                value={pricingGroup?.companyMarketName}
                readOnly
                className="create-edit-input-readonly"
              />
            </div>
            <div className="flex flex-col justify-stretch items-center gap-2 w-64">
              <div className="create-edit-header">
                {t("commercial.pricingPage.groupName")}
              </div>
              <input
                type="text"
                name="name"
                value={pricingGroup?.name}
                className="create-edit-input"
                onChange={(e) =>
                  setPricingGroup((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }))
                }
              />
            </div>
            <div className="flex flex-col justify-stretch items-center gap-2 w-64">
              <div className="create-edit-header">
                {t("commercial.pricingPage.electricityPrice")}
              </div>
              <div className="create-edit-input flex flex row justif-start items-center">
                <input
                  type="number"
                  name="priceAmount"
                  min="0"
                  step="0.01"
                  value={pricingGroup?.priceAmount}
                  className="eletr-price-input"
                  onChange={(e) => {
                    if (/^\d*\.?\d{0,2}$/.test(e.target.value)) {
                      setPricingGroup((prevState) => ({
                        ...prevState,
                        priceAmount: Number(e.target.value),
                      }));
                    }
                  }}
                />
                <div>
                  {pricingGroup?.priceUnit && pricingGroup.priceUnit + "/kWh"}
                </div>
              </div>
            </div>
          </div>
          {selectedPricingOptions.map((option, index) => (
            <div
              className="flex flex-col relative border-2 border-[#B5BAC680] p-4 gap-4"
              key={index}
            >
              <div className="flex items-center gap-5 mb-1">
                <p className="select-pricing-component-text">
                  {t("commercial.pricingPage.selectComponent")}
                </p>
                <div className="flex gap-2 items-center">
                  {option.type === "pricePerMinute" ? (
                    <img
                      src="/icons/correct-incorrect-icons/correct-icon-squared.svg"
                      alt=""
                    />
                  ) : selectedPricingOptions.find(
                      (el) => el.type === "pricePerMinute"
                    ) ? (
                    <div className="checker-border-disabled" />
                  ) : (
                    <div
                      className="checker-border"
                      onClick={() =>
                        changePricingOption(index, "pricePerMinute")
                      }
                    />
                  )}
                  <p
                    className={`pricing-component-text ${
                      option.type === "pricePerMinute"
                        ? "text-[#1E4CDC] "
                        : selectedPricingOptions.find(
                            (el) => el.type === "pricePerMinute"
                          )
                        ? "text-[#B5BAC6]"
                        : "text-[#111F47]"
                    }`}
                  >
                    {t("commercial.pricingPage.pricePerMin")}
                  </p>
                </div>
                <div className="flex gap-2 items-center">
                  {option.type === "sessionStartPrice" ? (
                    <img
                      src="/icons/correct-incorrect-icons/correct-icon-squared.svg"
                      alt=""
                    />
                  ) : selectedPricingOptions.find(
                      (el) => el.type === "sessionStartPrice"
                    ) ? (
                    <div className="checker-border-disabled" />
                  ) : (
                    <div
                      className="checker-border"
                      onClick={() =>
                        changePricingOption(index, "sessionStartPrice")
                      }
                    />
                  )}
                  <p
                    className={`pricing-component-text ${
                      option.type === "sessionStartPrice"
                        ? "text-[#1E4CDC] "
                        : selectedPricingOptions.find(
                            (el) => el.type === "sessionStartPrice"
                          )
                        ? "text-[#B5BAC6]"
                        : "text-[#111F47]"
                    }`}
                  >
                    {t("commercial.pricingPage.sessionStartPrice")}
                  </p>
                </div>
              </div>
              {option.type === "pricePerMinute" ? (
                <div className="flex flex-row gap-5">
                  <div className="flex flex-col justify-stretch items-center gap-2 w-64">
                    <div className="create-edit-header">
                      {t("commercial.pricingPage.gracePeriod")}
                    </div>
                    <div className="create-edit-input flex flex row justif-start items-center">
                      <input
                        type="number"
                        name="gracePeriod"
                        id="gracePeriod"
                        value={option.values.gracePeriod || ""}
                        min={0}
                        onChange={(e) => handlePricingComponentChange(index, e)}
                        placeholder="Grace period"
                        className="eletr-price-input mr-auto"
                      />
                      <div>min</div>
                    </div>
                    <div className="text-[10px] flex flex-col gap-1 pl-1">
                      <p className="text-[#B5BAC6]">
                        {t("commercial.pricingPage.graceText")}
                      </p>
                      <p
                        id="gracePeriodDiv"
                        className="text-[#ED1F0E] font-semibold invisible"
                      >
                        {t("commercial.pricingPage.bothInputs")}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col justify-stretch items-center gap-2 w-64">
                    <div className="create-edit-header">
                      {t("commercial.pricingPage.perMinuteText")}
                    </div>
                    <div className="create-edit-input flex flex row justif-start items-center">
                      <input
                        type="number"
                        min={0}
                        id="parkingFee"
                        name="parkingFee"
                        value={option.values.parkingFee || ""}
                        onChange={(e) => handlePricingComponentChange(index, e)}
                        placeholder={t("commercial.pricingPage.parkingFee")}
                        className="eletr-price-input mr-auto"
                      />
                      <div>{pricingGroup?.priceUnit || ""}</div>
                    </div>
                    <div className="text-[10px] flex flex-col gap-1 ml-1 w-full">
                      <p
                        id="parkingFeeDiv"
                        className="text-[#ED1F0E] font-semibold invisible"
                      >
                        {t("commercial.pricingPage.bothInputs")}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-row gap-5">
                  <div className="flex flex-col justify-stretch items-center gap-2 w-64">
                    <div className="create-edit-header">
                      {t("commercial.pricingPage.sessionStartPriceLower")}
                    </div>
                    <div className="create-edit-input flex flex row justif-start items-center">
                      <input
                        type="number"
                        min={0}
                        name="sessionStartPrice"
                        value={option.values.sessionStartPrice || ""}
                        onChange={(e) => handlePricingComponentChange(index, e)}
                        placeholder={t(
                          "commercial.pricingPage.sessionStartPriceLower"
                        )}
                        className="eletr-price-input mr-auto"
                      />
                      <div>{pricingGroup?.priceUnit || ""}</div>
                    </div>
                  </div>
                </div>
              )}
              <div
                className="absolute bottom-4 right-5 flex items-center gap-1 cursor-pointer"
                onClick={() => removePricingComponent(index)}
              >
                <img src="/icons/delete/trashIconBlue.svg" alt="alt" />
                <div className="text-[14px] text-[#1E4CDC]">Delete</div>
              </div>
            </div>
          ))}
          {selectedPricingOptions.length < availablePricingOptions.length && (
            <div
              className="flex justify-start items-center gap-2 cursor-pointer px-0.5"
              onClick={addNewPricingComponent}
            >
              <img src="/icons/plus/plusDarkBlue.svg" alt="" />
              <p className="add-pricing-component-text">
                {t("commercial.pricingPage.addNewComponent")}
              </p>
            </div>
          )}
          <div className="flex flex-row justify-between items-center flex-wrap p-4 bg-[#F3F4F6]">
            <div className="conn-overview-span">
              {t("commercial.pricingPage.connectorsOverview")}
            </div>
            <div className="flex flex-row justify-between items-center gap-6">
              <button
                className={`connnector-overview-remove-btn ${
                  selected.length !== 0 &&
                  "connnector-overview-remove-btn-active"
                }`}
                onClick={() => selected.length > 0 && setMoveConnectors(true)}
              >
                {t("commercial.pricingPage.move")}{" "}
                {selected.length > 0 && selected.length}{" "}
                {t("commercial.pricingPage.connector")}(
                {t("commercial.pricingPage.se")})
              </button>
              <button
                className="connnector-overview-add-btn flex flex-row justify-center items-center gap-1"
                onClick={() => setAddNewConnectors(true)}
              >
                <img src="/icons/admin/plusIcon.svg" alt="" />
                <p>{t("commercial.pricingPage.addConnectors")}</p>
              </button>
            </div>
          </div>
          <Table
            tableHeaders={tableHeaders}
            tableRowOrder={tableRowOrder}
            tableRows={assignedConnectors ? assignedConnectors : []}
            selected={selected}
            handleSelection={(id) => handleSelection(id)}
            preloadRowNumber={4}
          />
          <Paging
            tableSize={assignedConnectorsSize}
            pageChangeFunc={(page, perPage) =>
              setPaging({ page: page, perPage: perPage })
            }
          />
        </div>
      </div>

      {addNewConnectors && (
        <AddConnectors
          exit={async () => {
            setAddNewConnectors(false);
            setSelected([]);
            setPaging({ page: 1, perPage: 10 });
          }}
          companyMarketId={pricingGroup.companyMarketId}
          pricingGroupId={pricingGroup!.id}
        />
      )}
      {moveConnectors && (
        <MoveConnectors
          exit={async () => {
            setMoveConnectors(false);
            setSelected([]);
            setPaging({ page: 1, perPage: 10 });
          }}
          pricingGroupId={pricingGroup!.id}
          companyMarketId={pricingGroup.companyMarketId}
          connectors={selected}
        />
      )}
      {loading && <Spinner />}
    </div>
  );
};

export default PricingGroupDetails;
