import { useEffect, useMemo, useState } from "react";
import { useStore } from "react-stores";
import Table from "../../shared/table/Table";
import MarketDropdown from "../../shared/marketDropdown/MarketDropdown";
import Paging from "../../shared/paging/Paging";
import { IMarketDropdown } from "../../interfaces/IMarket";
import { GetFeesInfo, SessionStore } from "../../stores/sessionStore";
import { GetSessionsForCSV } from "../../stores/userStore";
import { IFeeDetails } from "../../interfaces/ISession";
import { useTranslation } from "react-i18next";

const FeesAndBilling = () => {
  const { t } = useTranslation();
  const [paging, setPaging] = useState({ page: 1, perPage: 10 });
  const [selected, setSelected] = useState<number>(0);
  const [expanded, setExpanded] = useState<number>(0);
  const [selectedMarkets, setSelectedMarkets] = useState<IMarketDropdown[]>([]);
  const [sort, setSort] = useState({ field: "Period", descending: true });

  const { feesInfo, feesInfoCount } = useStore(SessionStore);
  const feeDetails: IFeeDetails | undefined = useMemo(
    () =>
      expanded ? SessionStore.state.feeDetailsMap.get(expanded) : undefined,
    [expanded]
  );

  const tableHeaders = [
    t("fees.tableHeaders.period"),
    t("fees.tableHeaders.market"),
    t("fees.tableHeaders.date_of_issue"),
    t("fees.tableHeaders.due_date"),
    t("fees.tableHeaders.total_bill"),
  ];

  useEffect(() => {
    GetFeesInfo(
      selectedMarkets.map((market) => market.id),
      sort.field,
      sort.descending,
      paging.page,
      paging.perPage
    );
  }, [
    selectedMarkets,
    sort.field,
    sort.descending,
    paging.page,
    paging.perPage,
  ]);

  const handleSelection = (id: number) => {
    setSelected(selected === id ? 0 : id);
  };

  const handleExpanded = (id: number) => {
    setExpanded(expanded === id ? 0 : id);
  };

  const handleCheckboxClick = (chargerId: number) => {
    handleExpanded(chargerId);
    handleSelection(chargerId);
  };

  const handleSort = async (sortingField, sortingMethod) => {
    if (sortingMethod === "default") {
      setSort({ field: "", descending: true });
    } else {
      setSort({
        field: sortingField,
        descending: sortingMethod === "descending" ? true : false,
      });
    }
  };

  const handleDownloadCSV = async () => {
    const selectedFeeInfo = feesInfo.find((info) => info.id === selected);
    if (selectedFeeInfo) {
      const { timeStart, timeStop } = selectedFeeInfo;

      await GetSessionsForCSV(
        selectedMarkets.map((market) => market.id),
        timeStart,
        timeStop
      );
    } else {
      console.error("No fee info selected or fee info not found.");
    }
  };

  return (
    <div className="flex-1 p-5 flex flex-col gap-3.5 items-stretch main-wrapper">
      <div className="bg-white card p-5">
        <div className="flex flex-row justify-between items-center flex-wrap">
          <div className="operations-remote-management">
            <span>
              <span className="operations-remote-management-span">
                {t("fees.title")}
              </span>
            </span>
          </div>
          <div className="flex flex-row gap-3 flex-wrap">
            <MarketDropdown
              handleSelection={(newMarkets) => {
                setSelectedMarkets(newMarkets);
                setPaging((prev) => ({ page: 1, perPage: prev.perPage }));
              }}
            />
            <button
              className={`invoiceButton ${
                selected ? "invoiceButtonActive" : ""
              } flex flex-row gap-3 items-center justify-center`}
              disabled={!selected}
              onClick={() => handleDownloadCSV()}
            >
              <img
                src={
                  selected
                    ? "/icons/download/download-gray.svg"
                    : "/icons/download/download-darkblue.svg"
                }
                alt=""
              />
              {t("fees.transactionData")}
            </button>
          </div>
        </div>
      </div>

      <div className="p-5 table-container">
        <Table
          tableHeaders={tableHeaders}
          tableRowOrder={tableRowOrder}
          tableRows={feesInfo}
          selected={selected}
          handleSelection={(id) => handleSelection(id)}
          sortColumns={tableHeaders}
          handleSort={(sortingField, sortingMethod) =>
            handleSort(sortingField, sortingMethod)
          }
          expanded={expanded}
          handleExpanded={(id) => handleCheckboxClick(id)}
          preloadRowNumber={5}
        >
          <tr className="subtable-row">
            <td id="initial-td" colSpan={3}>
              <div>
                <table className="w-[100%]">
                  <thead>
                    <tr>
                      <th className="fees-and-billing-subtable-header"> </th>
                      <th className="fees-and-billing-subtable-header"> </th>
                      <th className="fees-and-billing-subtable-header text-left">
                        {t("fees.fee_description")}
                      </th>
                      <th className="fees-and-billing-subtable-header text-right">
                        {t("fees.amount")}
                      </th>
                      <th className="fees-and-billing-subtable-header text-right">
                        {t("fees.fee")}
                      </th>
                      <th className="fees-and-billing-subtable-header text-right">
                        {t("fees.total_cost")}
                      </th>
                      <th className="fees-and-billing-subtable-header"> </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="subtable-tr">
                      <td
                        className="fees-and-billing-subtable-td"
                        colSpan={2}
                      ></td>
                      <td className="fees-and-billing-subtable-td text-left">
                        {t("fees.transaction_fee")}
                      </td>
                      <td className="fees-and-billing-subtable-td text-right">
                        {feeDetails?.transactionFee.amount}
                      </td>
                      <td className="fees-and-billing-subtable-td text-right">
                        {feeDetails?.transactionFee.fee}
                      </td>
                      <td className="fees-and-billing-subtable-td text-right">
                        {feeDetails?.transactionFee.total}
                      </td>
                      <td className="fees-and-billing-subtable-td"></td>
                    </tr>
                    <tr className="subtable-tr">
                      <td
                        className="fees-and-billing-subtable-td"
                        colSpan={2}
                      ></td>
                      <td className="fees-and-billing-subtable-td text-left">
                        {t("fees.monthly_fee_per_charger")}
                      </td>
                      <td className="fees-and-billing-subtable-td text-right">
                        {feeDetails?.chargerMontlyFee.amount}
                      </td>
                      <td className="fees-and-billing-subtable-td text-right">
                        {feeDetails?.chargerMontlyFee.fee}
                      </td>
                      <td className="fees-and-billing-subtable-td text-right">
                        {feeDetails?.chargerMontlyFee.total}
                      </td>
                      <td className="fees-and-billing-subtable-td"></td>
                    </tr>
                    <tr className="subtable-tr">
                      <td
                        className="fees-and-billing-subtable-td"
                        colSpan={2}
                      ></td>
                      <td className="fees-and-billing-subtable-td text-left">
                        {t("fees.annual_roaming_fee")}
                      </td>
                      <td className="fees-and-billing-subtable-td text-right">
                        {feeDetails?.annualRoamingFee.amount}
                      </td>
                      <td className="fees-and-billing-subtable-td text-right">
                        {feeDetails?.annualRoamingFee.fee}
                      </td>
                      <td className="fees-and-billing-subtable-td text-right">
                        {feeDetails?.annualRoamingFee.total}
                      </td>
                      <td className="fees-and-billing-subtable-td"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </Table>
        <Paging
          tableSize={feesInfoCount}
          pageChangeFunc={(page, perPage) =>
            setPaging({ page: page, perPage: perPage })
          }
        />
      </div>
    </div>
  );
};

export default FeesAndBilling;

const tableRowOrder = [
  "period",
  "companyMarketName",
  "dateOfIssue",
  "dueDate",
  "totalBill",
];
