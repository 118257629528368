import React, { useState } from "react";
import "./ForgotPassword.css";
import { forgotPasswordHandler } from "../../stores/authStore";
import Popup from "../../shared/popup/Popup";
import LoginLayout from "../LoginLayout";
import Spinner from "../../shared/spinner/Spinner";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [correctEmail, setCorrectEmail] = useState(false);
  const [incorrectEmail, setIncorrectEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEmailInput = (event) => {
    setEmail(event.target.value);
  };

  const handleEmailSend = async (event) => {
    setLoading(true);
    event.preventDefault();
    const userData = { email: email, companyId: 1 };
    forgotPasswordHandler(JSON.stringify(userData)).then((res) => {
      if (res) {
        setLoading(false);
        setCorrectEmail(true);
      } else {
        setLoading(false);
        setIncorrectEmail(true);
      }
    });
  };

  const correctEmailPopup = () => {
    return (
      <>
        <img
          src="/icons/correct-incorrect-icons/correct-icon.svg"
          alt=""
          className="w-[81px]"
        />
        <div className="popup-main-text">
          {t("forgotPassword.correctEmail.header")}
        </div>
        <div className="popup-other-text">
          {t("forgotPassword.correctEmail.text_1")} {email}{" "}
          {t("forgotPassword.correctEmail.text_2")}
        </div>
      </>
    );
  };

  const incorrectEmailPopup = () => {
    return (
      <>
        <img
          src="/icons/correct-incorrect-icons/incorrect-icon.svg"
          alt=""
          className="w-[81px]"
        />
        <div className="popup-main-text">
          {t("forgotPassword.incorrectEmail.header")}
        </div>
        <div className="popup-other-text">
          {t("forgotPassword.incorrectEmail.text")}
        </div>
      </>
    );
  };

  return (
    <LoginLayout>
      <div className="flex flex-col gap-5 items-center flex-1 self-center w-[30rem]">
        <h1 className="forgot-password-main">{t("forgotPassword.header")}</h1>
        <p className="enter-your-username md:w-[30rem]">
          {t("forgotPassword.subheader")}
        </p>
        <form
          className="flex flex-col gap-8 md:w-[30rem]"
          onSubmit={handleEmailSend}
        >
          <div className="flex flex-col gap-1">
            <label htmlFor="email" className="login-email-password">
              E-mail
            </label>
            <input
              name="email"
              required
              id="email"
              type="email"
              value={email}
              onChange={handleEmailInput}
              className="login-input"
            />
          </div>
          <div className="flex flex-row justify-center items-center">
            <button type="submit" className="password-reset-button">
              {t("forgotPassword.submit")}
            </button>
          </div>
        </form>
        {correctEmail && (
          <Popup
            mainText={correctEmailPopup()}
            closeFunction={() => setCorrectEmail(false)}
            confirmFunction={() => undefined}
            closeText={t("forgotPassword.incorrectEmail.close")}
          />
        )}
        {incorrectEmail && (
          <Popup
            mainText={incorrectEmailPopup()}
            closeFunction={() => setIncorrectEmail(false)}
            confirmFunction={() => undefined}
            closeText={t("forgotPassword.incorrectEmail.close")}
          />
        )}
        {loading && <Spinner />}
      </div>
    </LoginLayout>
  );
};

export default ForgotPassword;
