import { generateQR } from "../../../helpers/dataHelper";
import { Connector, IChargerRoaming } from "../../../interfaces/IRoaming";
import { useToast } from "../../../shared/toast/ToastContent";
import { WithdrawChargerFromRoaming } from "../../../stores/roamingStore";
import { useTranslation } from "react-i18next";

interface Props {
  charger: IChargerRoaming;
  connectors: Connector[];
  close: (value: boolean) => void;
  refetchPublished: () => void;
}

const WithdrawPopup = ({
  connectors,
  close,
  charger,
  refetchPublished,
}: Props) => {
  const { t } = useTranslation();
  const { showToast } = useToast();

  const handleWithdraw = async () => {
    if (connectors) {
      await WithdrawChargerFromRoaming(charger.id).then((res) => {
        if (res) {
          refetchPublished();
          showToast(t("roaming.withdrawPopup.toast.success"), "success");
          close(false);
        } else {
          showToast(t("roaming.withdrawPopup.toast.error"), "error");
          close(false);
        }
      });
    }
  };

  const downloadQRCode = (qrString: string, connectorId: string) => {
    const qrDataUrl = generateQR(qrString);
    const link = document.createElement("a");
    link.href = qrDataUrl;
    link.download = "connector-" + connectorId + ".png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="modal-background" />
      <div
        style={{ zIndex: "1200" }}
        className="absolute inset-0 flex justify-center items-center "
      >
        <div
          style={{
            boxShadow: "0px 14px 7.8px 0px rgba(0, 0, 0, 0.10)",
          }}
          className="flex flex-col w-[850px] bg-white rounded-lg"
        >
          <div className="flex flex-col p-6 w-full">
            <div className="flex flex-col w-full">
              <p className="text-[20px] font-semibold text-[#111F47]">
                {t("roaming.widthdrawPopup.withdrawEVSEs")}
              </p>
              <div className="line mt-4 mb-8"></div>
              <tr className="subtable-row">
                <td colSpan={8} id="initial-td">
                  <div>
                    <table className="w-[100%]">
                      <thead>
                        <tr>
                          <th className="subtable-header"> </th>
                          <th className="subtable-header"> </th>
                          <th className="subtable-header">
                            {t(
                              "roaming.publishedTable.connectorsTable.connectorId"
                            )}
                          </th>
                          <th className="subtable-header">
                            {t(
                              "roaming.publishedTable.connectorsTable.connectorStatus"
                            )}
                          </th>
                          <th className="subtable-header">
                            {t(
                              "roaming.publishedTable.connectorsTable.connectorType"
                            )}
                          </th>
                          <th className="subtable-header">
                            {t(
                              "roaming.publishedTable.connectorsTable.electricCurrent"
                            )}
                          </th>
                          <th className="subtable-header">
                            {t(
                              "roaming.publishedTable.connectorsTable.roamingEvseId"
                            )}
                          </th>
                          <th className="subtable-header">
                            {t(
                              "roaming.publishedTable.connectorsTable.roamingQrCode"
                            )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {connectors.length > 0 ? (
                          connectors.map((connector) => (
                            <tr className="subtable-tr" key={connector.id}>
                              <td className="subtable-td" colSpan={2}></td>
                              <td className="subtable-td">
                                {connector.connectorName}
                              </td>
                              <td className="subtable-td">
                                {connector.connectorStatus}
                              </td>
                              <td className="subtable-td">
                                {connector.connectorType}
                              </td>
                              <td className="subtable-td">
                                {connector.powerType}
                              </td>
                              <td className="subtable-td">
                                {connector.evseId}
                              </td>
                              <td
                                className="text-[12px] font-medium text-[#183DB0] cursor-pointer underline"
                                onClick={() =>
                                  downloadQRCode(
                                    connector.qrString,
                                    connector.evseId
                                  )
                                }
                              >
                                {t(
                                  "roaming.publishedTable.connectorsTable.download"
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td className="subtable-td" colSpan={2}></td>
                            <td colSpan={9}>
                              {t("roaming.widthdrawPopup.noTransactions")}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </div>
          </div>
          <div className="flex w-full">
            <button
              onClick={() => close(false)}
              className="text-[16px] font-medium h-[57px] bg-[#E7E9ED] text-[#111F47] px-2.5 w-full rounded-bl-lg"
            >
              {t("roaming.widthdrawPopup.goBack")}
            </button>
            <button
              onClick={handleWithdraw}
              className="text-[16px] font-medium h-[57px] bg-[#1E4CDC] text-[#FFFFFF] px-2.5 w-full rounded-br-lg"
            >
              {t("roaming.widthdrawPopup.yesWithdraw")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WithdrawPopup;
