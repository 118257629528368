import { useEffect, useState } from "react";
import {
  GetAccessibilityTypes,
  RoamingStore,
} from "../../../stores/roamingStore";
import { useStore } from "react-stores";
import { useTranslation } from "react-i18next";

interface Props {
  selectedType: string | undefined;
  setSelectedType: (type: string) => void;
}

const AccessibilityDropdown = ({ selectedType, setSelectedType }: Props) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const { accessibilityTypes } = useStore(RoamingStore);

  useEffect(() => {
    GetAccessibilityTypes();
  }, []);

  const accessibilityLabels: Record<string, string> = {
    Unspecified: t("roaming.accessibility.Unspecified"),
    FreePubliclyAccessible: t("roaming.accessibility.FreePubliclyAccessible"),
    RestrictedAccess: t("roaming.accessibility.RestrictedAccess"),
    PayingPubliclyAccessible: t(
      "roaming.accessibility.PayingPubliclyAccessible"
    ),
    TestStation: t("roaming.accessibility.TestStation"),
  };

  const formatLabel = (value: string) => {
    return (
      accessibilityLabels[value] || value.replace(/([a-z])([A-Z])/g, "$1 $2")
    );
  };

  const handleSelect = (type: string, e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedType(type);
    setExpanded(false);
  };

  return (
    <div
      className="location-info-edit-input flex items-center justify-between cursor-pointer relative"
      onClick={() => setExpanded((prev) => !prev)}
    >
      {selectedType
        ? formatLabel(selectedType)
        : formatLabel(accessibilityLabels[1] || "")}

      <img
        src={
          expanded
            ? "/icons/roaming/arrowUp.svg"
            : "/icons/roaming/arrowDown.svg"
        }
        alt="arrow"
      />

      {expanded && (
        <div
          style={{
            boxShadow: "0px 14px 29.6px 0px rgba(0, 0, 0, 0.05)",
          }}
          className="bg-[#FFF] p-2.5 rounded-[10px] absolute top-12 left-0 right-0"
        >
          {accessibilityTypes.map((type, index) => (
            <div
              key={index}
              className={`p-2 cursor-pointer rounded-[5px] text-[12px] font-medium text-[#111F47] hover:bg-[#E9EDFC] hover:text-[#1E4CDC]`}
              onClick={(e) => handleSelect(type, e)}
            >
              {formatLabel(type)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AccessibilityDropdown;
