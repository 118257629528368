import { Store } from "react-stores";
import { IAuthStore } from "../interfaces/IStores";

const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;

export const AuthStore = new Store<IAuthStore>({
  jwt: "",
  user: null,
});

export const hasValidJwt = () => {
  let jwtString: string | null = AuthStore.state.jwt;

  if (!jwtString) {
    jwtString = sessionStorage.getItem("jwt") || checkIfRemembered();
  }

  return jwtString !== null && jwtString !== "";
};

export const autoLogoutIfExpired = () => {
  const jwtString =
    localStorage.getItem("jwt") || sessionStorage.getItem("jwt");

  if (!jwtString) {
    localStorage.removeItem("jwt");
    sessionStorage.removeItem("jwt");
    window.location.href = "/auth/login";
    return false;
  }

  try {
    const token = JSON.parse(atob(jwtString.split(".")[1]));
    const expiration = token.exp * 1000;
    const now = Date.now();

    if (now >= expiration) {
      localStorage.removeItem("jwt");
      sessionStorage.removeItem("jwt");
      window.location.href = "/auth/login";
      return false;
    }
    return true;
  } catch (error) {
    console.error("Invalid JWT format:", error);
    localStorage.removeItem("jwt");
    sessionStorage.removeItem("jwt");
    window.location.href = "/auth/login";
    return false;
  }
};

export const onSuccess = async (res) => {
  try {
    const token = res.credential;

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Auth/VerifyGoogleUser`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
          rememberMe: true,
        }),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      console.error("Google login failed:", errorData);
      return { jwt: "", error: "Google login failed" };
    }

    let jwtToken = await response.text();
    jwtToken = jwtToken.replace(/^"(.*)"$/, "$1");

    AuthStore.setState({ ...AuthStore.state, jwt: jwtToken });
    sessionStorage.setItem("jwt", jwtToken);

    localStorage.setItem(
      "jwt",
      JSON.stringify({ id: jwtToken, expiryDate: new Date().getTime() })
    );

    return { jwt: jwtToken, error: "" };
  } catch (error) {
    console.error("Authentication failed:", error);
    return { jwt: "", error: "Authentication failed" };
  }
};

export const loginHandler = async (data: string, rememberMe: boolean) => {
  try {
    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Auth/LoginUser`,
      {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status !== 200) {
      return { jwt: "", error: "Wrong email or password" };
    } else {
      let res = await response.text();
      res = res.replace(/^"(.*)"$/, "$1");
      AuthStore.setState({ ...AuthStore.state, jwt: res });
      sessionStorage.setItem("jwt", res);
      rememberMe &&
        localStorage.setItem(
          "jwt",
          JSON.stringify({ id: res, expiryDate: new Date().getTime() })
        );
      return { jwt: res, error: "" };
    }
  } catch (e) {
    console.log("Error: ", e);
    return { jwt: "", error: "Wrong email or password" };
  }
};

export const forgotPasswordHandler = async (data: string) => {
  try {
    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Auth/ForgotPassword`,
      {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.ok;
  } catch (e) {
    console.log("Error: ", e);
    return false;
  }
};

export const setNewPassword = async (data) => {
  try {
    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Auth/SetAdminPassword`,
      {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
          Accept: "text/plain",
        },
      }
    );
    return response.ok;
  } catch (e) {
    console.log("Error: ", e);
    return false;
  }
};

export const changePasswordHandler = async (data) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    if (!jwtString) return false;
    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Users/ChangeAdminPassword`,
      {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
          Accept: "text/plain",
          Authorization: "Bearer " + jwtString,
        },
      }
    );
    return response.ok;
  } catch (e) {
    console.log("Error: ", e);
    return false;
  }
};

export const meHandler = async () => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    if (!jwtString) jwtString = checkIfRemembered();
    if (!jwtString) return false;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow" as RequestRedirect,
    };

    // @ts-ignore
    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Users/GetUserInfo`,
      requestOptions
    );
    // if(response.status)
    const userData = await response.json();
    if (!userData || !response.ok) return false;

    AuthStore.setState({ ...AuthStore.state, user: userData });

    return true;
  } catch (e) {
    console.log("Caught error: ", e);
    return false;
  }
};

export const logout = () => {
  AuthStore.setState({ ...AuthStore.state, jwt: "" });
  localStorage.setItem("jwt", "");
  sessionStorage.setItem("jwt", "");
  window.location.href = "/auth/login";
};

export const checkIfRemembered = () => {
  try {
    let jwtString = localStorage.getItem("jwt");
    if (jwtString) {
      let jwtStringParsed = JSON.parse(jwtString);
      if (!checkIsJwtExpired(jwtStringParsed.expiryDate)) {
        AuthStore.setState({ ...AuthStore.state, jwt: jwtStringParsed.id });
        return jwtStringParsed.id;
      }
      localStorage.setItem("jwt", "");
    }
    return "";
  } catch (e) {
    console.log("Error: " + e);
    return "";
  }
};

const checkIsJwtExpired = (time: number) => {
  let currentDate = new Date();
  if (currentDate.getTime() - time > 561600000) {
    return true;
  } else {
    return false;
  }
};
