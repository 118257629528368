import "./NetworkOverview.css";
import Map from "./Map/Map";
import { useEffect, useRef, useState } from "react";
import { useStore } from "react-stores";
import Table from "../../../shared/table/Table";
import MarkerModal from "./markerModal/MarkerModal";
import CreateLocationModal from "./addNewLocation/CreateLocationModal";
import {
  GetLocationList,
  GetLocations,
  LocationStore,
} from "../../../stores/locationStore";
import { ICreateLocation } from "../../../interfaces/ILocation";
import Paging from "../../../shared/paging/Paging";
import { MarkerClusterRef } from "./Map/MarkerCluster";
import { useTranslation } from "react-i18next";

const NetworkOverview = () => {
  const { t } = useTranslation();
  const [paging, setPaging] = useState({ page: 1, perPage: 10 });
  const [activeButton, setActiveButton] = useState("map");
  const [selected, setSelected] = useState<number>(0);
  const [createLocation, setCreateLocation] = useState(false);
  const [newLocation, setNewLocation] = useState<ICreateLocation | null>(null);
  const [locationUpdated, setLocationUpdated] = useState(false);

  const [sort, setSort] = useState({
    field: "LocationName",
    descending: true,
  });
  const { locationList, locationListSize, deletingLocation } =
    useStore(LocationStore);

  const markerClusterRef = useRef<MarkerClusterRef>(null);

  const handleLocationUpdate = (updatedLocation: any) => {
    console.log(updatedLocation);
    if (markerClusterRef.current) {
      console.log("Ref is set and calling onLocationUpdate");
      markerClusterRef.current.onLocationUpdate(updatedLocation);
    } else {
      console.log("Ref is null");
    }
  };

  const tableHeaders = [
    t("network.tableHeaderList.locationId"),
    t("network.tableHeaderList.locationName"),
    t("network.tableHeaderList.chargers"),
    t("network.tableHeaderList.connectors"),
    t("network.tableHeaderList.address"),
    t("network.tableHeaderList.market"),
  ];

  const tableRowOrder = [
    "id",
    "locationName",
    "chargersCount",
    "connectorsCount",
    "street",
    "companyMarketName",
  ];

  const handleSort = async (sortingField, sortingMethod) => {
    if (sortingMethod === "default") {
      setSort({ field: "", descending: true });
    } else {
      setSort({
        field: sortingField,
        descending: sortingMethod === "descending" ? true : false,
      });
    }
  };

  useEffect(() => {
    GetLocationList(paging.page, paging.perPage, sort.field, sort.descending);

    if (locationUpdated) setLocationUpdated(false);

    if (deletingLocation?.isDeleted === true) {
      LocationStore.setState({
        ...LocationStore.state,
        deletingLocation: { locationId: 0, isDeleted: false },
      });
    }
  }, [paging, sort.field, sort.descending, deletingLocation, locationUpdated]);

  useEffect(() => {
    GetLocations();
  }, []);

  const handleSelection = (locationId: number) => {
    setSelected(locationId);
  };

  const handleButtonClick = (buttonType: string) => {
    setActiveButton(buttonType);
  };

  const closeMarkerModal = () => {
    setSelected(0);
  };

  useEffect(() => {
    if (selected !== 0) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [selected]);

  return (
    <div className="flex-1 p-3 flex flex-col gap-3 items-stretch main-wrapper">
      <div className="bg-white card p-5">
        <div className="flex flex-row justify-between items-center flex-wrap">
          <div className="operations-remote-management">
            <span>
              <span className="operations-remote-management-span">
                {t("network.title_1")}
              </span>
              <span className="operations-remote-management-span2">
                {t("network.title_2")}
              </span>
            </span>
          </div>

          <button
            className={
              newLocation !== null
                ? "createLocationBtn btn-disabled"
                : "createLocationBtn"
            }
            onClick={() => setCreateLocation(true)}
            disabled={newLocation !== null}
          >
            <img src="/icons/admin/plusIcon.svg" alt="" />
            {t("network.createLocationBtn")}
          </button>
        </div>
      </div>
      <div className="map-container">
        <div
          className={
            activeButton === "list" ? "map-btns" : "map-btns btns-box_shadow"
          }
        >
          <div className="button-container">
            <button
              className={
                activeButton === "map" ? "active button-1" : "inactive button-1"
              }
              onClick={() => handleButtonClick("map")}
            >
              {t("network.locationsMap")}
            </button>
            <button
              className={
                activeButton === "list"
                  ? "active button-2"
                  : "inactive button-2"
              }
              onClick={() => handleButtonClick("list")}
            >
              {t("network.locationsList")}
            </button>
          </div>
        </div>
        <div
          style={{
            display: activeButton === "map" ? "block" : "none",
            height: activeButton === "map" ? "100%" : "0",
            overflow: "hidden",
          }}
        >
          <Map
            newLocation={newLocation}
            setLocation={setNewLocation}
            ref={markerClusterRef}
          />
        </div>
        {activeButton === "list" && (
          <div className="table-container p-5">
            <Table
              tableHeaders={tableHeaders}
              tableRowOrder={tableRowOrder}
              tableRows={locationList}
              selected={selected}
              sortColumns={tableHeaders}
              handleSort={(sortingField, sortingMethod) =>
                handleSort(sortingField, sortingMethod)
              }
              handleSelection={(id) => handleSelection(id)}
              blankHeight
              preloadRowNumber={6}
            />
            <Paging
              tableSize={locationListSize}
              pageChangeFunc={(page, perPage) => setPaging({ page, perPage })}
            />
          </div>
        )}
      </div>
      {createLocation && (
        <CreateLocationModal
          closeModal={setCreateLocation}
          setLocation={setNewLocation}
          setScreen={setActiveButton}
        />
      )}
      {selected !== 0 && (
        <MarkerModal
          locationId={selected}
          closeModal={closeMarkerModal}
          onLocationUpdate={handleLocationUpdate}
          setScreen={setActiveButton}
        />
      )}
    </div>
  );
};

export default NetworkOverview;
