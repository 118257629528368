import React, { useRef, useState } from "react";
import "./ProfilePhotoModal.css";
import { useOutsideAlerter } from "../../../helpers/componentsHelper";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../stores/authStore";
import { useTranslation } from "react-i18next";
import { googleLogout } from "@react-oauth/google";

type ProfilePhotoModalProps = {
  name?: string;
  surname?: string;
};

const ProfilePhotoModal: React.FC<ProfilePhotoModalProps> = ({
  name,
  surname,
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setExpanded(false));

  const logoutFromAccount = () => {
    logout();
    googleLogout();
  };

  return (
    <div
      className="flex flex-col justify-start items-end gap-3 relative"
      ref={wrapperRef}
    >
      <div
        onClick={() => setExpanded((prev) => !prev)}
        className="flex flex-row gap-5 items-center hover:cursor-pointer"
      >
        <div className="username">
          {name} {surname}
        </div>
        <img src="/icons/nav/profileIcon.png" alt="" className="profile-icon" />
      </div>
      {expanded && (
        <div className="profile-modal-container">
          <div
            className="settings"
            onClick={() => {
              navigate("/dashboard/profile");
              setExpanded(false);
            }}
          >
            <img src="/icons/nav/home.svg" alt="" />
            <p>{t("profileModal.profileSettings")}</p>
          </div>
          <div
            className="settings"
            onClick={() => {
              navigate("/dashboard/company");
              setExpanded(false);
            }}
          >
            <img src="/icons/nav/user.svg" alt="" />
            <p>{t("profileModal.companySettings")}</p>
          </div>
          <div className="settings mt-3 border-t-2" onClick={logoutFromAccount}>
            <img src="/icons/nav/signOut.svg" alt="" />
            <p>{t("profileModal.signOut")}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfilePhotoModal;
