import React, { useState, useEffect, useMemo } from "react";
import { useStore } from "react-stores";
import "./LoadManagement.css";
import Table from "../../../shared/table/Table";
import SetPowerLimit from "./SetPowerLimit";
import ResetPowerLimit from "./ResetPowerLimit";
import {
  ChargerStore,
  handleGetChargers,
  handleGetConnectorsForCharger,
} from "../../../stores/chargerStore";
import { useCompanyMarketsDropdownQuery } from "../../../hooks/useCompanyMarketDropdown";
import Paging from "../../../shared/paging/Paging";
import { useTranslation } from "react-i18next";
import { closeConnection, startConnection } from "../../../helpers/signalRHelper";
import { IConnectorInCharger } from "../../../interfaces/IConnector";
import { getConnectorClass } from "../../../helpers/dataHelper";

interface Paging {
  page: number;
  perPage: number;
}

const LoadManagement = () => {
  const { t } = useTranslation();
  const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;
  const [sort, setSort] = useState({ field: "", descending: true });
  const [paging, setPaging] = useState<Paging>({ page: 1, perPage: 10 });
  const [selected, setSelected] = useState<number>(0);
  const [expanded, setExpanded] = useState<number>(0);
  const [setPowerLimitPopup, setSetPowerLimitPopup] = useState<boolean>(false);

  const [resetPowerLimitPopup, setResetPowerLimitPopup] = useState<boolean>(false);
  const [connection, setConnection] = useState<signalR.HubConnection | null>(null);
  var chargerWithConnectorsTemp: IConnectorInCharger[] = [];

  const { chargers, chargersSize, chargerWithConnectors } = useStore(ChargerStore);
  const { data: marketsData } = useCompanyMarketsDropdownQuery();

  const tableHeaders = [
    t("loadManagement.tableHeaders.charger_id"),
    t("loadManagement.tableHeaders.market"),
    t("loadManagement.tableHeaders.available_connectors"),
    t("loadManagement.tableHeaders.unavailable_faulted_connectors"),
    t("loadManagement.tableHeaders.location_name"),
    t("loadManagement.tableHeaders.address"),
  ];

  const canReSet = useMemo(() => {
    if (selected) {
      const selectedData = chargers.find(el => el.id === selected);
      return (selectedData && selectedData.havePowerLimitSet) ? true : false;
    } else {
      return false;
    }
  }, [selected])

  const rowsStyling = useMemo(
    () =>
      chargers.map((el) =>
        el.havePowerLimitSet
          ? { row: "table-red-border" }
          : { row: "table-blue-border" }
      ),
    [chargers]
  );

  useEffect(() => {
    marketsData &&
      handleGetChargers(
        marketsData.map((el) => el.id),
        [],
        [],
        paging.page,
        paging.perPage,
        sort.field,
        sort.descending
      );
  }, [marketsData, paging, sort]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await handleGetConnectorsForCharger(expanded);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      chargerWithConnectorsTemp = data?.connectors || [];
      if (data && data.connectors.length > 0) handleSignalRConnection(data.connectors);
    }

    expanded && fetchData();
    return () => {
      ChargerStore.setState({
        ...ChargerStore.state,
        chargerWithConnectors: null,
      });
      connection && closeConnection(connection);
    }
  }, [expanded]);

  const handleSort = async (sortingField, sortingMethod) => {
    if (sortingMethod === "default") {
      setSort({ field: "", descending: true });
    } else {
      setSort({
        field: sortingField,
        descending: sortingMethod === "descending" ? true : false,
      });
    }
  };


  const handleSignalRConnection = async (connectors) => {
    const newConnection = await startConnection(
      `${PUBLIC_BASE_URL}hubs/connectorStatus`
    );
    setConnection(newConnection);

    const connectorIds = connectors.map((connector) => connector.id);
    await newConnection
      .invoke("Subscribe", connectorIds)
      .then(() => console.log("Subscribed Id's: " + connectorIds))
      .catch((err) => console.error("Error while subscribing", err));

    newConnection.on(
      "updateConnectorStatus",
      (updateConnectorStatusSignalRDto) => {
        console.log(
          `Status for connector ${updateConnectorStatusSignalRDto.connectorId}: ${updateConnectorStatusSignalRDto.status}`
        );
        if (chargerWithConnectorsTemp && chargerWithConnectorsTemp.length > 0) {
          console.log("Connectors of charger:");
          console.log(chargerWithConnectorsTemp);
          let updatedConnectors = chargerWithConnectorsTemp.map((con) => {
            if (
              Number(con.id) ===
              Number(updateConnectorStatusSignalRDto.connectorId)
            ) {
              return {
                ...con,
                connectorStatus: updateConnectorStatusSignalRDto.status,
              };
            } else return con;
          });
          console.log("Updated: ");
          console.log(updatedConnectors);
          chargerWithConnectorsTemp = [...updatedConnectors];
          ChargerStore.setState({
            ...ChargerStore.state,
            chargerWithConnectors: { chargerId: expanded, connectors: chargerWithConnectorsTemp },
          });
        }
      }
    );
  };

  return (
    <div className="flex-1 p-3.5 flex flex-col gap-3.5 items-stretch main-wrapper">
      <div className="bg-white card p-5">
        <div className="flex flex-row justify-between items-center header flex-wrap">
          <div className="load-management">
            <span>
              <span className="load-management-span">
                {t("loadManagement.title_1")}
              </span>
              <span className="load-management-span2">
                {t("loadManagement.title_2")}
              </span>
            </span>
          </div>
          <div className="flex items-center gap-5">
            <button
              className={`register-btn ${canReSet && "register-btn-active"}`}
              disabled={!canReSet}
              onClick={() => setResetPowerLimitPopup(true)}
            >
              {t("loadManagement.reset_power_limit")}
            </button>
            <button
              className={`register-btn ${selected && "register-btn-active"}`}
              disabled={!selected}
              onClick={() => setSetPowerLimitPopup(true)}
            >
              {t("loadManagement.set_power_limit")}
            </button>
          </div>
        </div>
      </div>
      <div className="table-container p-5 flex flex-col gap-3">
        <div className="flex justify-end items-center gap-5 mb-2">
          <div className="flex items-center gap-1 text-[12px]">
            <img src="/icons/threeDots/blue-dot.svg" alt="" />
            <p>{t("loadManagement.no_power_limit")}</p>
          </div>
          <div className="flex items-center gap-1 text-[12px]">
            <img src="/icons/threeDots/red-dot.svg" alt="" />
            <p>{t("loadManagement.has_power_limit")}</p>
          </div>
        </div>
        <Table
          tableHeaders={tableHeaders}
          tableRowOrder={tableRowOrder}
          tableRows={chargers}
          selected={selected}
          sortColumns={tableHeaders}
          expanded={expanded}
          handleExpanded={(id) => setExpanded((prev) => (prev === id ? 0 : id))}
          handleSort={(sortingField, sortingMethod) =>
            handleSort(sortingField, sortingMethod)
          }
          handleSelection={(id) =>
            setSelected((prev) => (prev === id ? 0 : id))
          }
          rowsStyling={rowsStyling}
          preloadRowNumber={6}
        >
          <tr className="subtable-row">
            <td id="initial-td" colSpan={6}>
              <div className="pl-5">
                <table className="w-[100%]">
                  <thead>
                    <tr>
                      <th className="subtable-header">
                        {t("loadManagement.subtableHeaders.connector_id")}
                      </th>
                      <th className="subtable-header">
                        {t("loadManagement.subtableHeaders.connector_status")}
                      </th>
                      <th className="subtable-header">
                        {t("loadManagement.subtableHeaders.connector_type")}
                      </th>
                      <th className="subtable-header">
                        {t("loadManagement.subtableHeaders.electric_current")}
                      </th>
                      <th className="subtable-header">
                        {t("loadManagement.subtableHeaders.max_power")}
                      </th>
                      <th className="subtable-header">
                        {t("loadManagement.subtableHeaders.power_limit")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {chargerWithConnectors?.chargerId &&
                      chargerWithConnectors.connectors.map((connector) => (
                        <tr
                          className="subtable-tr"
                          key={connector.id}
                          id={
                            connector.staticPowerLimit
                              ? "table-red-border"
                              : "table-blue-border"
                          }
                        >
                          <td className="subtable-td">
                            {connector.connectorName}
                          </td>
                          <td className="subtable-td">
                            <div
                              className={`${getConnectorClass(
                                connector.connectorStatus
                              )} connector-status-container`}
                            >
                              {t(
                                `remoteManagement.connectorStatus.${connector.connectorStatus}`
                              )}
                            </div>
                          </td>
                          <td className="subtable-td">
                            {connector.connectorType}
                          </td>
                          <td className="subtable-td">
                            {connector.electricCurrent}
                          </td>
                          <td className="subtable-td">
                            {connector.maxPower &&
                              connector.maxPower.toFixed(2) + " kW"}
                          </td>
                          <td className="subtable-td">
                            {connector.staticPowerLimit
                              ? connector.staticPowerLimit.toFixed(2) + " kW"
                              : "N/A"}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </Table>
        <Paging
          tableSize={chargersSize}
          pageChangeFunc={(page, perPage) =>
            setPaging({ page: page, perPage: perPage })
          }
        />
      </div>
      {setPowerLimitPopup && (
        <SetPowerLimit
          handleExit={(changed) => {
            setSetPowerLimitPopup(false);
            changed &&
              handleGetChargers(
                marketsData ? marketsData.map((el) => el.id) : [],
                [],
                [],
                paging.page,
                paging.perPage,
                sort.field,
                sort.descending
              );
          }}
          selectedCharger={selected}
        />
      )}
      {resetPowerLimitPopup && (
        <ResetPowerLimit
          handleExit={(changed) => {
            setResetPowerLimitPopup(false);
            changed &&
              handleGetChargers(
                marketsData ? marketsData.map((el) => el.id) : [],
                [],
                [],
                paging.page,
                paging.perPage,
                sort.field,
                sort.descending
              );
          }}
          selectedCharger={selected}
        />
      )}
    </div>
  );
};

export default LoadManagement;

const tableRowOrder = [
  "ocppChargerId",
  "companyMarketName",
  "numberOfAvailableConnectors",
  "numberOfUnavailableFaultedConnectors",
  "locationName",
  "street",
];
