import React, { useState, useEffect } from "react";
import {
  PricingStore,
  handleGetPricingGroupsForMoving,
  moveConnectorInPricingGroup,
} from "../../../stores/pricingStore";
import Table from "../../../shared/table/Table";
import Paging from "../../../shared/paging/Paging";
import "./AddConnectors.css";
import { useStore } from "react-stores";
import { useToast } from "../../../shared/toast/ToastContent";
import Spinner from "../../../shared/spinner/Spinner";
import { useTranslation } from "react-i18next";

interface Paging {
  page: number;
  perPage: number;
}

const MoveConnectors: React.FC<{
  pricingGroupId: number;
  companyMarketId: number;
  exit: Function;
  connectors: number[];
}> = ({ pricingGroupId, companyMarketId, exit, connectors }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<number>(0);
  const [paging, setPaging] = useState<Paging>({ page: 1, perPage: 10 });
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);

  const { pricingGroups, pricingGroupsSize } = useStore(PricingStore);

  const tableHeaders = [
    t("commercial.moveConnectors.tableHeaders.groupName"),
    t("commercial.moveConnectors.tableHeaders.numberOfConnectors"),
    t("commercial.moveConnectors.tableHeaders.electricityPrice"),
    t("commercial.moveConnectors.tableHeaders.parkingFeePrice"),
    t("commercial.moveConnectors.tableHeaders.sessionStartPrice"),
  ];

  const tableRowOrder = [
    "name",
    "connectorCount",
    "priceAmount",
    "parkingFeePrice",
    "startFee",
  ];

  useEffect(() => {
    const getData = async () => {
      await handleGetPricingGroupsForMoving(
        pricingGroupId,
        [companyMarketId],
        paging.page,
        paging.perPage
      );
    };
    getData();
  }, [companyMarketId, paging, pricingGroupId]);

  useEffect(() => {
    return () => {
      PricingStore.setState({
        ...PricingStore.state,
        pricingGroups: [],
        pricingGroupsSize: 0,
      });
    };
  }, []);

  const handleSelection = async (id: number) => {
    if (selected === id) {
      setSelected(0);
    } else {
      setSelected(id);
    }
  };

  async function submitConnectorAdd() {
    setLoading(true);
    const res = await moveConnectorInPricingGroup(selected, connectors);
    setLoading(false);
    res
      ? showToast(
          `${connectors.length} ${
            connectors.length === 1
              ? t("commercial.moveConnectors.toast.connector")
              : t("commercial.moveConnectors.toast.connectors")
          } ${t("commercial.moveConnectors.toast.success")}`,
          "success"
        )
      : showToast(t("commercial.moveConnectors.toast.error"), "error");
    await exit();
  }

  return (
    <>
      <div className="modal-background" onClick={() => exit()} />
      <div className="absolute-add-connectors pt-2">
        <div className="flex flex-col items-center justify-start gap-2">
          <div className="flex justify-end items-center w-full p-5">
            <img
              src="/icons/exit/cross-big.svg"
              alt=""
              onClick={() => exit()}
              className="cursor-pointer"
            />
          </div>
          <div className="horizontal-gray-line w-full"></div>
          <div className="flex flex-col justify-start items-stretch w-full px-10 py-7 gap-5">
            <div className="flex justify-between gap-10">
              <p className="addConnectorsText">
                {connectors.length === 1
                  ? t("commercial.moveConnectors.movePreviouslyOne")
                  : t("commercial.moveConnectors.movePreviously")}{" "}
                {connectors.length}{" "}
                {connectors.length === 1
                  ? t("commercial.moveConnectors.toast.connector")
                  : t("commercial.moveConnectors.toast.connectors")}{" "}
                {t("commercial.moveConnectors.toOther")}
              </p>
              <button
                className={`confirm-connectors-btn ${
                  selected ? "confirm-connectors-btn-active" : ""
                }`}
                disabled={!selected}
                onClick={() => selected && submitConnectorAdd()}
              >
                {t("commercial.moveConnectors.confirm")}
              </button>
            </div>
            <div className="h-[2.5px] bg-[#B5BAC680] w-full mt-3"></div>
            <div className="confirm-addition-span">
              {t("commercial.moveConnectors.selectPricingGroup")}
            </div>

            <Table
              tableHeaders={tableHeaders}
              tableRowOrder={tableRowOrder}
              tableRows={pricingGroups}
              selected={selected}
              handleSelection={(id) => handleSelection(id)}
              preloadRowNumber={5}
            />
            <Paging
              tableSize={pricingGroupsSize}
              pageChangeFunc={(page, perPage) =>
                setPaging({ page: page, perPage: perPage })
              }
            />
          </div>
        </div>
      </div>
      {loading && <Spinner />}
    </>
  );
};

export default MoveConnectors;
