import React, { useEffect, useRef, useState } from "react";
import "./NetworkOnboarding.css";
import PendingConnection from "./pendingConnection/PendingConnection";
import FinishOnboardingTable from "./finishOnboarding/FinishOnboardingTable";
import AddNewCharger from "./addNewCharger/AddNewCharger";
import { useNavigate } from "react-router-dom";
import {
  handleCreateCharger,
  handleGetChargersOnboardingStatusCount,
  handleGetOnboardingChargerInstructions,
} from "../../../stores/chargerStore";
import { IChargersOnboardingStatusCount, IOnboardingChargerInstructions } from "../../../interfaces/ICharger";
import CreateChargerModal from "./createChargerModal/CreatechargerModal";
import { useTranslation } from "react-i18next";
import Spinner from "../../../shared/spinner/Spinner";

interface NewCharger {
  id: string;
  locationId: number | undefined;
}

const NetworkOnboarding = () => {
  const { t } = useTranslation();
  const [mode, setMode] = useState("Pending connection");
  const [chargerAdded, setChargerAdded] = useState(false);
  const [selected, setSelected] = useState(0);
  const [selectedCompanyMarket, setSelectedCompanyMarket] = useState(0);
  const [createChargerMode, setCreateChargerMode] = useState(false);
  const [creationPopup, setCreationPopup] = useState<boolean>(false);
  const [onboardingStatusCount, setOnboardingStatusCount] =
    useState<IChargersOnboardingStatusCount>({
      pendingCount: 0,
      connectedCount: 0,
    });
  const newChargerInstructions = useRef<IOnboardingChargerInstructions | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getStatusCount = async () => {
      setOnboardingStatusCount(
        await handleGetChargersOnboardingStatusCount().then(
          (res) =>
            res || {
              pendingCount: 0,
              connectedCount: 0,
            }
        )
      );
    };
    getStatusCount();
  }, [mode]);

  const handleSelection = (id: number) => {
    setSelected(selected === id ? 0 : id);
  };

  const creationPopupText = () => {
    return (
      <>
        <p className="instructions-popover-main-text">
          {t("networkOnboarding.creationPopup.title")}
        </p>
        <div className="instructions-popover-row-container">
          <p className="instructions-popover-row-left">OCPP proxy url</p>
          <p className="instructions-popover-row-right">
            {newChargerInstructions.current?.ocppProxyUrl}
          </p>
        </div>
        <div className="instructions-popover-row-container">
          <p className="instructions-popover-row-left">WSS/WS</p>
          <p className="instructions-popover-row-right">{newChargerInstructions.current?.encryption}</p>
        </div>
        <div className="instructions-popover-row-container">
          <p className="instructions-popover-row-left">
            {t("networkOnboarding.creationPopup.port")}
          </p>
          <p className="instructions-popover-row-right">{newChargerInstructions.current?.port}</p>
        </div>
        <div className="instructions-popover-row-container">
          <p className="instructions-popover-row-left">
            {t("networkOnboarding.creationPopup.protocol")}
          </p>
          <p className="instructions-popover-row-right">{newChargerInstructions.current?.availableProtocols}</p>
        </div>
        <div className="instructions-popover-row-container">
          <p className="instructions-popover-row-left">
            {t("networkOnboarding.creationPopup.password")}
          </p>
          <p className="instructions-popover-row-right">{newChargerInstructions.current?.password}</p>
        </div>
      </>
    );
  };

  const handleChargerCreation = async (newCh: NewCharger) => {
    setLoading(true);
    const data = {
      chargerIdentificator: newCh.id,
      locationId: newCh.locationId,
    };
    await handleCreateCharger(JSON.stringify(data));
    newChargerInstructions.current = await handleGetOnboardingChargerInstructions(newCh.id);
    setLoading(false);
    setCreationPopup(true);
    setOnboardingStatusCount(await handleGetChargersOnboardingStatusCount());
    setChargerAdded((prev) => !prev);
    setMode("Pending connection");
  };

  return (
    <div className="flex-1 px-3.5 pt-3.5 flex flex-col gap-3.5 items-stretch main-wrapper">
      <div className="bg-white card p-5">
        <div className="flex flex-row justify-between items-center header flex-wrap">
          <div className="network-onboarding">
            <span>
              <span className="network-onboarding-span">
                {t("networkOnboarding.title_1")}
              </span>
              <span className="network-onboarding-span2">
                {t("networkOnboarding.title_2")}
              </span>
            </span>
          </div>
          <div className="flex flex-row gap-5 flex-wrap items-center">
            {mode === "Finish onboarding" && (
              <button
                className={`finish-onboarding-btn ${selected && " finish-onboarding-btn-active"
                  }`}
                onClick={() =>
                  navigate(
                    "/dashboard/network/onboarding/finishOnboarding/" +
                    selected +
                    "/" +
                    selectedCompanyMarket
                  )
                }
                disabled={!(selected && selectedCompanyMarket)}
              >
                <p>{t("networkOnboarding.finishOnboardingBtn")}</p>
              </button>
            )}
            <button
              className="add-new-charger-btn flex flex-row justify-around items-center gap-2"
              onClick={() => setCreateChargerMode(true)}
            >
              <img src="/icons/admin/plusIcon.svg" alt="" />
              <p>{t("networkOnboarding.createNewCharger")}</p>
            </button>
          </div>
        </div>
      </div>
      <div className="flex-1 bg-white card p-5 flex flex-col justify-start items-start overflow-visible relative">
        <div className="max-h-[calc(80vh-15rem)]"></div>
        <div className="onboarding-buttons-container mt-2 mb-6">
          <div
            className={`flex flex-row justify-center items-center onboarding-btn rounded-l-[10px] ${mode === "Pending connection" && " onboarding-btn-active"
              }`}
            onClick={() =>
              mode !== "Pending connection" && setMode("Pending connection")
            }
          >
            <div>{t("networkOnboarding.pendingConnection")}</div>
            <div
              className={`onboarding-btn-num ${mode === "Pending connection" && " onboarding-btn-num-active"
                }`}
            >
              <p className="onboarding-num">
                {onboardingStatusCount.pendingCount}
              </p>
            </div>
          </div>
          <div
            className={`flex flex-row justify-center items-center onboarding-btn rounded-r-[10px] ${mode === "Finish onboarding" && " onboarding-btn-active"
              }`}
            onClick={() =>
              mode !== "Finish onboarding" && setMode("Finish onboarding")
            }
          >
            <div>{t("networkOnboarding.finishOnboardingLabel")}</div>
            <div
              className={`onboarding-btn-num ${mode === "Finish onboarding" && " onboarding-btn-num-active"
                }`}
            >
              <p className="onboarding-num">
                {onboardingStatusCount.connectedCount}
              </p>
            </div>
          </div>
        </div>
        {mode === "Pending connection" ? (
          <PendingConnection chargerAdded={chargerAdded} />
        ) : (
          <FinishOnboardingTable
            handleSelection={(id, companyMarketId) => {
              handleSelection(id);
              setSelectedCompanyMarket(companyMarketId);
            }}
            selected={selected}
          />
        )}
      </div>
      {createChargerMode && (
        <AddNewCharger
          handleExit={() => setCreateChargerMode(false)}
          handleAddingCharger={(newCh: NewCharger) => {
            handleChargerCreation(newCh);
            setCreateChargerMode(false);
          }}
        />
      )}
      {creationPopup && (
        <CreateChargerModal
          mainText={creationPopupText()}
          closeFunction={() => {
            setCreationPopup(false);
            newChargerInstructions.current = null;
          }}
          confirmText="OK"
          confirmFunction={() => {
            setCreationPopup(false);
            newChargerInstructions.current = null;
          }}
        />
      )}
      {loading && <Spinner />}
    </div>
  );
};

export default NetworkOnboarding;
