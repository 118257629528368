import { useEffect, useState } from "react";
import { Connector, IChargerRoaming } from "../../interfaces/IRoaming";
import AccessibilityDropdown from "./popups/AccessibilityDropdown";
import AccessibilityLocationDropdown from "./popups/AccessibilityLocationDropdown";
import { RoamingStore } from "../../stores/roamingStore";
import { useStore } from "react-stores";
import { useTranslation } from "react-i18next";

interface Props {
  charger: IChargerRoaming;
  setModal: (value: boolean) => void;
  setPublishingCharger: (charger: IChargerRoaming) => void;
  setConfirmPopup: (value: boolean) => void;
}
const ChargerModal = ({
  setModal,
  charger,
  setPublishingCharger,
  setConfirmPopup,
}: Props) => {
  const { t } = useTranslation();
  const [updatedCharger, setUpdatedCharger] =
    useState<IChargerRoaming>(charger);

  const { accessibilityTypes, accessibilityLocationTypes } =
    useStore(RoamingStore);

  useEffect(() => {
    setUpdatedCharger((prevCharger) => ({
      ...prevCharger,
      accessibility: charger.accessibility || accessibilityTypes[1],
      accessibilityLocation:
        charger.accessibilityLocation || accessibilityLocationTypes[0],
    }));
  }, [charger, accessibilityTypes, accessibilityLocationTypes]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setUpdatedCharger((prevEdited) => ({ ...prevEdited, [name]: value }));
  };

  const handlePublishCharger = async () => {
    setPublishingCharger(updatedCharger);

    setConfirmPopup(true);
  };

  return (
    <>
      <div className="modal-background" onClick={() => setModal(false)} />
      <div className="charger-modal-container">
        <div className="modal-header justify-end">
          <img
            onClick={() => setModal(false)}
            src="/icons/shared/exit-cross.svg"
            alt=""
            className="cursor-pointer"
          />
        </div>
        <div className="line"></div>
        <div className="content-location-container py-8">
          <div className="flex items-center justify-between w-full pb-8">
            <div className="flex flex-col gap-2 w-full">
              <p className="title-1">
                {t("roaming.chargerModal.connectorIDs")}
              </p>
              <div>
                {charger.connectors.map((connector) => (
                  <div key={connector.id} className="flex items-center gap-1">
                    <img src="/icons/nav/activeDot.svg" alt="dot" />
                    <p key={connector.id} className="text-[13px] font-medium">
                      {connector.connectorName}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <button
              onClick={handlePublishCharger}
              className="text-[12px] h-10 font-medium w-[120px] rounded-[10px] bg-[#1E4CDC] text-white "
            >
              {t("roaming.chargerModal.publish")}
            </button>
          </div>
          <div className="line"></div>
          <div className="location-information-table">
            <div className="flex justify-between gap-5 w-full">
              <div className="flex-fields">
                <p className="title-1">
                  {t("roaming.chargerModal.hotlinePhoneNumber")}
                </p>
                <input
                  className="location-info-edit-input"
                  placeholder={t("roaming.chargerModal.hotlinePhoneNumber")}
                  name="hotlinePhoneNumber"
                  value={updatedCharger?.hotlinePhoneNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="flex-fields">
                <p className="title-1">
                  {t("roaming.chargerModal.chargingStationName")}
                </p>
                <input
                  className="location-info-edit-input"
                  placeholder={t("roaming.chargerModal.chargingStationName")}
                  name="chargingStationName"
                  value={updatedCharger?.chargingStationName}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="flex justify-between gap-5 w-full">
              <div className="flex-fields">
                <p className="title-1">
                  {t("roaming.chargerModal.locationName")}
                </p>
                <div className="unactive-fields">
                  <p className="unactive-data">{charger?.locationName}</p>
                </div>
              </div>
              <div className="flex-fields">
                <p className="title-1">{t("roaming.chargerModal.address")}</p>
                <div className="unactive-fields">
                  <p className="unactive-data">{charger?.address}</p>
                </div>
              </div>
            </div>
            <div className="flex justify-between gap-5 w-full">
              <div className="flex-fields">
                <p className="title-1">
                  {t("roaming.chargerModal.geoCoordinates")}
                </p>
                <div className="unactive-fields">
                  <p className="unactive-data">
                    {charger?.longitude.toFixed(3)},{" "}
                    {charger?.latitude.toFixed(3)}
                  </p>
                </div>
              </div>
              <div className="flex-fields">
                <p className="title-1">{t("roaming.chargerModal.plugType")}</p>
                <div className="unactive-fields">
                  <p className="unactive-data">
                    {charger.connectors[0]?.connectorType}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex justify-between gap-5">
              <div className="flex-fields">
                <p className="title-1">{t("roaming.chargerModal.powerType")}</p>
                <div className="unactive-fields">
                  <p className="unactive-data">
                    {charger.connectors[0]?.powerType}
                  </p>
                </div>
              </div>
              <div className="flex-fields">
                <p className="title-1">{t("roaming.chargerModal.powerInKW")}</p>
                <div className="unactive-fields">
                  <p className="unactive-data">
                    {charger.connectors[0]?.powerKw}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-between gap-5">
              <div className="flex-fields">
                <p className="title-1">
                  {t("roaming.chargerModal.accessibility")}
                </p>
                <AccessibilityDropdown
                  selectedType={updatedCharger?.accessibility}
                  setSelectedType={(newType) => {
                    setUpdatedCharger((prevUpdated) => ({
                      ...prevUpdated,
                      accessibility: newType,
                    }));
                  }}
                />
              </div>
              <div className="flex-fields">
                <p className="title-1">
                  {t("roaming.chargerModal.accessibilityLocation")}
                </p>
                <AccessibilityLocationDropdown
                  selectedType={updatedCharger?.accessibilityLocation}
                  setSelectedType={(newType) => {
                    setUpdatedCharger((prevUpdated) => ({
                      ...prevUpdated,
                      accessibilityLocation: newType,
                    }));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChargerModal;
