import { useState } from "react";
import Paging from "../../shared/paging/Paging";
import Table from "../../shared/table/Table";
import { IChargerRoaming } from "../../interfaces/IRoaming";
import { generateQR, getConnectorClass } from "../../helpers/dataHelper";
import { useTranslation } from "react-i18next";

interface Props {
  data: IChargerRoaming[];
  selected: number;
  handleSelection: (id: number) => void;
  setPaging: (params: { page: number; perPage: number }) => void;
  setSorting: (params: { field: string; descending: boolean }) => void;
}
const PublishedEVSETable = ({
  selected,
  handleSelection,
  data,
  setPaging,
  setSorting,
}: Props) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<number>(0);

  const tableSeedHeaders: string[] = [
    t("roaming.publishedTable.tableHeaders.chargerId"),
    t("roaming.publishedTable.tableHeaders.locationName"),
    t("roaming.publishedTable.tableHeaders.market"),
    t("roaming.publishedTable.tableHeaders.publishedConnectors"),
    t("roaming.publishedTable.tableHeaders.chargerInformation"),
  ];

  const handleSort = async (sortingField, sortingMethod) => {
    if (sortingMethod === "default") {
      setSorting({ field: "", descending: true });
    } else {
      setSorting({
        field: sortingField,
        descending: sortingMethod === "descending" ? true : false,
      });
    }
  };

  const handleExpanded = (id: number) => {
    if (expanded === id) {
      setExpanded(0);
    } else {
      setExpanded(id);
    }
  };

  const handleCheckboxClick = (chargerId: number) => {
    handleExpanded(chargerId);
    handleSelection(chargerId);
  };

  const downloadQRCode = (qrString: string, connectorId: string) => {
    const qrDataUrl = generateQR(qrString);
    const link = document.createElement("a");
    link.href = qrDataUrl;
    link.download = "connector-" + connectorId + ".png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="p-5 table-container">
      <Table
        tableHeaders={tableSeedHeaders}
        tableRowOrder={tableSeedRowOrder}
        handleSelection={(id) => handleSelection(id)}
        selected={selected}
        tableRows={data ? data : []}
        sortColumns={[
          "Charger ID",
          "Location Name",
          "Published connectors",
          "Charger information",
        ]}
        handleSort={(sortingField, sortingMethod) =>
          handleSort(sortingField, sortingMethod)
        }
        expanded={expanded}
        handleExpanded={(id) => handleCheckboxClick(id)}
      >
        {data.map((charger) => (
          <tr key={charger.id} className="subtable-row">
            <td colSpan={8} id="initial-td">
              {expanded === charger.id && (
                <div>
                  <table className="w-[100%]">
                    <thead>
                      <tr>
                        <th className="subtable-header"> </th>
                        <th className="subtable-header"> </th>
                        <th className="subtable-header">
                          {t(
                            "roaming.publishedTable.connectorsTable.connectorId"
                          )}
                        </th>
                        <th className="subtable-header">
                          {t(
                            "roaming.publishedTable.connectorsTable.connectorStatus"
                          )}
                        </th>
                        <th className="subtable-header">
                          {t(
                            "roaming.publishedTable.connectorsTable.connectorType"
                          )}
                        </th>
                        <th className="subtable-header">
                          {t(
                            "roaming.publishedTable.connectorsTable.electricCurrent"
                          )}
                        </th>
                        <th className="subtable-header">
                          {t(
                            "roaming.publishedTable.connectorsTable.roamingEvseId"
                          )}
                        </th>
                        <th className="subtable-header">
                          {t(
                            "roaming.publishedTable.connectorsTable.roamingQrCode"
                          )}
                        </th>
                        <th className="subtable-header">
                          {t(
                            "roaming.publishedTable.connectorsTable.roamingStatus"
                          )}
                        </th>
                        <th className="subtable-header">
                          {t("roaming.publishedTable.connectorsTable.note")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {charger.connectors && charger.connectors.length > 0 ? (
                        charger.connectors.map((connector) => (
                          <tr className="subtable-tr" key={connector.id}>
                            <td className="subtable-td" colSpan={2}></td>
                            <td className="subtable-td">
                              {connector.connectorName}
                            </td>

                            <td className="subtable-td ">
                              <div
                                className={` ${getConnectorClass(connector.connectorStatus)} connector-status-container`}
                              >
                                {t(`remoteManagement.connectorStatus.${connector.connectorStatus}`)}
                              </div>
                            </td>
                            <td className="subtable-td">
                              {connector.connectorType}
                            </td>
                            <td className="subtable-td">
                              {connector.powerType}
                            </td>
                            <td className="subtable-td">{connector.evseId}</td>
                            <td
                              className="text-[12px] font-medium text-[#183DB0] cursor-pointer underline"
                              onClick={() =>
                                downloadQRCode(
                                  connector.qrString,
                                  connector.evseId
                                )
                              }
                            >
                              {t(
                                "roaming.publishedTable.connectorsTable.download"
                              )}
                            </td>
                            <td className="subtable-td">
                              {t("roaming.Published")}
                            </td>
                            <td className="subtable-td">{connector.note}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="subtable-td" colSpan={10}>
                            {t(
                              "roaming.publishedTable.connectorsTable.noConnectors"
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </td>
          </tr>
        ))}
      </Table>

      <Paging
        tableSize={data.length}
        pageChangeFunc={(page, perPage) =>
          setPaging({ page: page, perPage: perPage })
        }
      />
    </div>
  );
};

export default PublishedEVSETable;

const tableSeedRowOrder: string[] = [
  "ocppChargerId",
  "locationName",
  "companyMarketName",
  "publishedConnectors",
  "description",
];
