import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { router } from "./router/Routes";
import { ToastContainer } from "react-toastify";
import "react-calendar/dist/Calendar.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastProvider } from "./shared/toast/ToastContent";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <GoogleOAuthProvider clientId="902527561650-a8h3lirecdkbgmllt4fpt8b1tso6pkpt.apps.googleusercontent.com">
        <ToastProvider>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
            <ToastContainer
              position="top-center"
              autoClose={3000}
              hideProgressBar={true}
            />
          </QueryClientProvider>
        </ToastProvider>
      </GoogleOAuthProvider>
    </I18nextProvider>
  </React.StrictMode>
);

reportWebVitals();
