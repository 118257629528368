import React, { useState, useRef, useEffect, ReactNode, useMemo } from "react";
import "./LoadingPopup.css";
import { useTranslation } from "react-i18next";

interface LoadingPopupProps {
  steps: Step[];
  closeFunction: () => void;
  handleReset: () => void;
}

export interface Step {
  text: string;
  status: StepState;
  progressReady: boolean;
}

enum LoadingState {
  Waiting = "loadingState.waiting",
  Failed = "loadingState.failed",
  Success = "loadingState.success",
}

export enum StepState {
  Completed = "stepState.completed",
  Progress = "stepState.progress",
  Pending = "stepState.pending",
  Failed = "stepState.failed",
}

const LoadingPopup = ({
  steps,
  closeFunction,
  handleReset,
}: LoadingPopupProps) => {
  const { t } = useTranslation();
  let popupRef = useRef<any>(null);

  const globalState = useMemo(() => {
    if (steps.some((step) => step.status === StepState.Failed))
      return LoadingState.Failed;
    else if (steps.every((step) => step.status === StepState.Completed))
      return LoadingState.Success;
    else return LoadingState.Waiting;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(steps)]);

  useEffect(() => {
    let handler = (e) => {
      if (popupRef.current && !popupRef.current.contains(e.target)) {
        closeFunction();
      }
    };
    document.addEventListener("mousedown", handler);
  });

  // useEffect(() => {
  //     reset();
  //     testingProgress();
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const reset = () => {
  //     setSteps(stepsText.map(text => ({ text: text, status: StepState.Pending, progressReady: false })));
  // };

  // const testingProgress = () => {
  //     setSteps(prev => {
  //         prev[0].status = StepState.Progress
  //         return prev;
  //     });
  //     for (let i = 0; i < steps.length - 1; i++) {
  //         setTimeout(() => {
  //             setSteps(prev => {
  //                 prev[i].status = StepState.Completed
  //                 prev[i + 1].status = StepState.Progress
  //                 prev[i].progressReady = true
  //                 return prev;
  //             })
  //             console.log('ussaaa ' + i)

  //         }, i * 2000 + 2000);
  //     }
  //     setTimeout(() => {
  //         setSteps(prev => {
  //             prev[prev.length - 1].status = StepState.Failed
  //             prev[prev.length - 1].progressReady = true
  //             return prev;
  //         })
  //         console.log('ussaaa zadnji')
  //     }, (steps.length - 1) * 2000 + 2000);
  // };

  return (
    <>
      <div className="modal-background" />
      <div className="loading-popup-container" ref={popupRef}>
        <div className="flex flex-col items-stretch relative h-[100%] w-[100%] pb-5">
          <div className="loading-popup-state-title-container">
            <div className="flex justify-between items-center w-full">
              <div />
              <div className="state-text">{t(globalState)}</div>
              <img
                src="/icons/exit/cross-big.svg"
                alt=""
                onClick={closeFunction}
                className="cursor-pointer"
              />
            </div>
            <div className="w-full bg-[#E7E9ED] h-[2px]"></div>
          </div>
          <div
            className={`flex justify-center items-stretch gap-[10px] px-4 mb-6`}
          >
            {steps.map((step, idx) => (
              <div
                className="flex flex-col justify-between items-center gap-3 w-[170px] relative"
                key={idx}
              >
                {step.status === StepState.Completed ? (
                  <img src="/icons/loading/completed.svg" alt="" />
                ) : step.status === StepState.Progress ? (
                  <img src="/icons/loading/progress.svg" alt="" />
                ) : step.status === StepState.Pending ? (
                  <img src="/icons/loading/pending.svg" alt="" />
                ) : (
                  <img src="/icons/loading/failed.svg" alt="" />
                )}
                <div className="step-text grow">{step.text}</div>
                <div
                  className={`flex justify-center items-center step-status-text ${step.status === StepState.Completed
                      ? "step-completed"
                      : step.status === StepState.Progress
                        ? "step-in-progress"
                        : step.status === StepState.Pending
                          ? "step-pending"
                          : "step-failed"
                    }`}
                >
                  {t(step.status)}
                </div>
                {idx !== 0 && (
                  <div className="absolute top-4 left-[-60px] w-[110px] h-[4px] bg-[#E7E9ED] rounded">
                    {step.progressReady && (
                      <div className="progress rounded"></div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="flex flex-row absolute bottom-[-2.2rem] justify-stretch items-center w-[100%]">
            {globalState === t(LoadingState.Failed) && (
              <div
                className="flex-1 bottom-curved try-again-btn"
                onClick={handleReset}
              >
                {t("sessions.active.stopSessionSteps.tryAgain")}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LoadingPopup;
