import React, { useEffect, useRef, useState } from "react";
import "./LanguageDropdown.css";
import { useTranslation } from "react-i18next";
import { setAccountLanguage } from "../../i18n";

interface Props {
  header: boolean;
}

const LanguageDropdown: React.FC<Props> = ({ header }) => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setIsOpen(false);
    setAccountLanguage(lang);
  };

  const languageFlags: { [key: string]: string } = {
    en: "/icons/flags/en.png",
    hr: "/icons/flags/hr.png",
  };

  return (
    <div
      ref={dropdownRef}
      className={`language-container ${
        header ? "relative rounded-[10px]" : "absolute top-[40px] right-[100px]"
      } ${isOpen ? "open" : ""}`}
      onClick={toggleDropdown}
    >
      <div className="flex items-center gap-2">
        <img
          className="w-[25px] h-[25px]"
          src={languageFlags[i18n.language] || "/icons/flags/en.png"}
          alt={i18n.language}
        />
        <p>{i18n.language.toUpperCase()}</p>
      </div>

      {/* <img
        className="rotate-img"
        src="/icons/arrow/arrowDownBlue.svg"
        alt="Dropdown"
      /> */}

      {isOpen && (
        <div className="dropdown-menu">
          <button
            className="flex items-center gap-2"
            onClick={() => changeLanguage("en")}
          >
            <img
              className="w-[25px] h-[25px]"
              src="/icons/flags/en.png"
              alt="en"
            />
            EN
          </button>
          <button
            className="flex items-center gap-2"
            onClick={() => changeLanguage("hr")}
          >
            <img
              className="w-[25px] h-[25px]"
              src="/icons/flags/hr.png"
              alt="en"
            />
            HR
          </button>
        </div>
      )}
    </div>
  );
};

export default LanguageDropdown;
