import { useEffect, useState } from "react";
import DateSelectRange from "../../shared/calendar/DateSelectRange";
import CurrencyDropdown from "../../shared/currencyDropdown/CurrencyDropdown";
import MarketDropdown from "../../shared/marketDropdown/MarketDropdown";
import "./UserManagement.css";
import Paging from "../../shared/paging/Paging";
import Table from "../../shared/table/Table";
import { useStore } from "react-stores";
import {
  GetUserRevenue,
  GetUserSessions,
  GetUsers,
  UserStore,
} from "../../stores/userStore";
import { IMarketDropdown } from "../../interfaces/IMarket";
import { IUserSession } from "../../interfaces/IUser";
import { formatDateLocaleCalendar } from "../../helpers/dataHelper";
import { useTranslation } from "react-i18next";

const UserManagement = () => {
  const { t } = useTranslation();
  const [paging, setPaging] = useState({ page: 1, perPage: 10 });
  const [calendar, setCalendar] = useState({ startDate: "", endDate: "" });
  const [showDateModal, setShowDateModal] = useState(false);
  const [selectedMarkets, setSelectedMarkets] = useState<IMarketDropdown[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [sort, setSort] = useState({
    field: "id",
    descending: false,
  });
  const [userSessions, setUserSessions] = useState<IUserSession[]>([]);
  const [selected, setSelected] = useState<number>(0);
  const [expanded, setExpanded] = useState<number>(0);

  const { users, revenue, usersCount } = useStore(UserStore);

  useEffect(() => {
    const fetchData = async () => {
      if (expanded !== 0 && selectedCurrency && selectedMarkets.length > 0) {
        const result = await GetUserSessions(
          expanded,
          selectedMarkets.map((market) => market.id),
          selectedCurrency,
          calendar.startDate,
          calendar.endDate
        );
        setUserSessions(result);
      }
    };

    fetchData();
  }, [
    calendar.endDate,
    calendar.startDate,
    expanded,
    selectedCurrency,
    selectedMarkets,
  ]);

  useEffect(() => {
    if (selectedCurrency && selectedMarkets.length > 0) {
      GetUsers(
        selectedCurrency,
        calendar.startDate,
        calendar.endDate,
        selectedMarkets.map((market) => market.id),
        paging.page,
        paging.perPage,
        sort.field,
        sort.descending
      );
    }
  }, [selectedCurrency, calendar, paging, selectedMarkets, sort]);

  useEffect(() => {
    if (selectedCurrency && selectedMarkets.length > 0) {
      GetUserRevenue(
        selectedCurrency,
        calendar.startDate,
        calendar.endDate,
        selectedMarkets.map((market) => market.id)
      );
    }
  }, [selectedCurrency, calendar, selectedMarkets]);

  const handleSelection = (id: number) => {
    setSelected(selected === id ? 0 : id);
  };

  const handleExpanded = (id: number) => {
    if (expanded === id) {
      setExpanded(0);
    } else {
      setExpanded(id);
    }
  };

  const tableSeedHeaders: string[] = [
    t("userManagement.tableHeaders.userId"),
    t("userManagement.tableHeaders.username"),
    t("userManagement.tableHeaders.totalSpend"),
    t("userManagement.tableHeaders.averageSpend"),
    t("userManagement.tableHeaders.transactions"),
    t("userManagement.tableHeaders.lastActivity"),
    t("userManagement.tableHeaders.averageChargingTime"),
  ];
  const tableSeedRowOrder: string[] = [
    "id",
    "username",
    "totalSpend",
    "averageSpend",
    "transactionCount",
    "lastActivity",
    "averageChargingTime",
  ];

  const handleCheckboxClick = (chargerId: number) => {
    handleExpanded(chargerId);
    handleSelection(chargerId);
  };

  const handleClearDate = (event) => {
    event.stopPropagation();
    setCalendar({ startDate: "", endDate: "" });
  };

  const handleSort = async (sortingField, sortingMethod) => {
    if (sortingMethod === "default") {
      setSort({ field: "id", descending: false });
    } else {
      setSort({
        field: sortingField,
        descending: sortingMethod === "descending" ? true : false,
      });
    }
  };

  return (
    <div className="flex-1 px-3.5 pt-3.5 flex flex-col gap-3.5 items-stretch main-wrapper">
      <div className="bg-white card p-5">
        <div className="flex flex-row justify-between items-center header flex-wrap">
          <div className="dashboard">
            <span>
              <span className="dashboard-span">
                {t("userManagement.breadcrumb.userManagement")}/
              </span>
              <span className="dashboard-span2">
                {t("userManagement.breadcrumb.users")}
              </span>
            </span>
          </div>
          <div className="flex flex-row gap-5 flex-wrap">
            <CurrencyDropdown
              availableCurrencies={[
                ...new Set(selectedMarkets.map((market) => market.currencyIso)),
              ]}
              handleSelection={(newCurrencies) =>
                setSelectedCurrency(newCurrencies)
              }
            />
            <MarketDropdown
              handleSelection={(newMarkets) => {
                setSelectedMarkets(newMarkets);
                setPaging((prev) => ({ page: 1, perPage: prev.perPage }));
              }}
            />
            <button
              onClick={() => setShowDateModal(!showDateModal)}
              className={`calendarButton ${
                showDateModal ? "calendarButtonActive" : ""
              } flex flex-row gap-3 items-center justify-center`}
            >
              {calendar.startDate && calendar.endDate ? (
                <div
                  className={
                    showDateModal
                      ? "calendarButtonActive picked-date-container-blue"
                      : "picked-date-container"
                  }
                >
                  <p className="date">
                    {formatDateLocaleCalendar(calendar.startDate)} -{" "}
                    {formatDateLocaleCalendar(calendar.endDate)}
                  </p>
                  {!showDateModal && (
                    <div className="date-exit-container">
                      <img
                        className="cursor-pointer exit-calendar-cross"
                        src="/icons/exit/cross.svg"
                        alt="Clear Date"
                        onClick={handleClearDate}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <img
                    src={
                      showDateModal
                        ? "/icons/calendar/calendarIconWhite.svg"
                        : "/icons/calendar/calendarIcon.svg"
                    }
                    alt=""
                  />
                  {t("userManagement.timePeriod")}
                  <img
                    src={
                      showDateModal
                        ? "/icons/arrow/arrowUpWhite.svg"
                        : "/icons/arrow/arrowDownDarkBlue.svg"
                    }
                    alt=""
                  />
                </>
              )}
            </button>
            {showDateModal && (
              <DateSelectRange
                showCalendar={setShowDateModal}
                start={calendar.startDate}
                end={calendar.endDate}
                handleApply={(start, end) => {
                  setCalendar({ startDate: start, endDate: end });
                  setPaging((prev) => ({ page: 1, perPage: prev.perPage }));
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="revenue-cards grid grid-cols-1 md:grid-cols-3 gap-3">
        <div className="md:col-span-1 flex flex-col gap-2.5 items-stretch justify-between p-5 revenue-container">
          <p className="title">
            {t("userManagement.revenueCards.b2cMobileAppUsers")}
          </p>
          <p className="price">
            {revenue &&
            revenue.b2CUserRevenueDto &&
            revenue.b2CUserRevenueDto.amount
              ? revenue.b2CUserRevenueDto.amount.toFixed(2)
              : 0}{" "}
            {selectedCurrency}
          </p>
          <div className="percentage flex flex-row items-center gap-1">
            <div className="percent-div">
              <div className="percent">
                {revenue?.b2CUserRevenueDto?.percentageOfTotal?.toFixed(1) || 0}
                %
              </div>
            </div>
            <div className="text">
              {t("userManagement.revenueCards.ofTotalRevenue")}
            </div>
          </div>
        </div>
        <div className="md:col-span-1 flex flex-col gap-2.5 items-stretch justify-between p-5 revenue-container">
          <p className="title">
            {t("userManagement.revenueCards.b2cWebAppUsers")}
          </p>
          <p className="price">
            {revenue &&
            revenue.b2CWebUserRevenueDto &&
            revenue.b2CWebUserRevenueDto.amount
              ? revenue.b2CWebUserRevenueDto.amount.toFixed(2)
              : 0}{" "}
            {selectedCurrency}
          </p>
          <div className="percentage flex flex-row items-center gap-1">
            <div className="percent-div">
              <div className="percent">
                {revenue?.b2CWebUserRevenueDto?.percentageOfTotal?.toFixed(1) ||
                  0}
                %
              </div>
            </div>
            <div className="text">
              {t("userManagement.revenueCards.ofTotalRevenue")}
            </div>
          </div>
        </div>
        <div className="md:col-span-1 flex flex-col gap-2.5 items-stretch justify-between p-5 revenue-container">
          <p className="title">
            {t("userManagement.revenueCards.roamingUsers")}
          </p>
          <p className="price">
            {revenue &&
            revenue.roamingUserRevenueDto &&
            revenue.roamingUserRevenueDto.amount
              ? revenue.roamingUserRevenueDto.amount.toFixed(2)
              : 0}{" "}
            {selectedCurrency}
          </p>
          <div className="percentage flex flex-row items-center gap-1">
            <div className="percent-div">
              <div className="percent">
                {revenue?.roamingUserRevenueDto?.percentageOfTotal?.toFixed(
                  1
                ) || 0}
                %
              </div>
            </div>
            <div className="text">
              {t("userManagement.revenueCards.ofTotalRevenue")}
            </div>
          </div>
        </div>
      </div>
      <div className="p-5 table-container">
        <Table
          tableHeaders={tableSeedHeaders}
          tableRowOrder={tableSeedRowOrder}
          handleSelection={(id) => handleSelection(id)}
          selected={selected}
          tableRows={users ? users : []}
          sortColumns={tableSeedHeaders} // Updated to use translated headers
          handleSort={(sortingField, sortingMethod) =>
            handleSort(sortingField, sortingMethod)
          }
          expanded={expanded}
          handleExpanded={(id) => handleCheckboxClick(id)}
          preloadRowNumber={5}
        >
          <tr className="subtable-row">
            <td colSpan={8} id="initial-td">
              <div>
                <table className="w-[100%]">
                  <thead>
                    <tr>
                      <th className="subtable-header"> </th>
                      <th className="subtable-header"> </th>
                      <th className="subtable-header">
                        {t("userManagement.subtableHeaders.chargingSessionId")}
                      </th>
                      <th className="subtable-header">
                        {t("userManagement.subtableHeaders.startTime")}
                      </th>
                      <th className="subtable-header">
                        {t("userManagement.subtableHeaders.stopTime")}
                      </th>
                      <th className="subtable-header">
                        {t("userManagement.subtableHeaders.duration")}
                      </th>
                      <th className="subtable-header">
                        {t("userManagement.subtableHeaders.energyConsumed")}
                      </th>
                      <th className="subtable-header">
                        {t("userManagement.subtableHeaders.status")}
                      </th>
                      <th className="subtable-header">
                        {t("userManagement.subtableHeaders.billedAmount")}
                      </th>
                      <th className="subtable-header">
                        {t("userManagement.subtableHeaders.locationName")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {userSessions.length > 0 ? (
                      userSessions.map((session) => (
                        <tr className="subtable-tr" key={session.id}>
                          <td className="subtable-td" colSpan={2}></td>
                          <td className="subtable-td">{session.id}</td>
                          <td className="subtable-td">{session.timeStart}</td>
                          <td className="subtable-td">{session.timeStop}</td>
                          <td className="subtable-td">{session.duration}</td>
                          <td className="subtable-td">
                            {session.electricityConsumed}{" "}
                            {t("userManagement.units.kWh")}
                          </td>
                          <td className="subtable-td">
                            <div
                              className={`${
                                session.status === "Active"
                                  ? "in-use"
                                  : "available"
                              } connector-status-container`}
                            >
                              {session.status}
                            </div>
                          </td>
                          <td className="subtable-td">{session.totalPrice}</td>
                          <td className="subtable-td">
                            {session.locationName}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="subtable-td" colSpan={2}></td>
                        <td colSpan={9}>
                          {t("userManagement.noTransactions")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </Table>

        <Paging
          tableSize={usersCount}
          pageChangeFunc={(page, perPage) =>
            setPaging({ page: page, perPage: perPage })
          }
        />
      </div>
    </div>
  );
};

export default UserManagement;
