import React, { useEffect, useState } from "react";
import "./InstructionsPopover.css";
import { useTranslation } from "react-i18next";
import { ChargerStore, handleGetOnboardingChargerInstructions } from "../../../../stores/chargerStore";
import { useStore } from "react-stores";
import { IOnboardingChargerInstructions } from "../../../../interfaces/ICharger";

const InstructionsPopover = ({ id, selected, ocppChargerId }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [onboardingChargerInstructions, setOnboardingChargerInstructions] = useState<IOnboardingChargerInstructions | null>(null);

  useEffect(() => {
    if (id === selected) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [id, selected]);

  useEffect(() => {
    const fetchOnboardingChargerInstructions = async () => {
      setOnboardingChargerInstructions(await handleGetOnboardingChargerInstructions(ocppChargerId));
    };
    fetchOnboardingChargerInstructions();
  }, [ocppChargerId]);

  return (
    <>
      <div
        className="instructions-popover-icon-circle"
        onMouseEnter={() => setExpanded(true)}
        onMouseLeave={() => setExpanded(false)}
      >
        <div className="instructions-popover-icon-i">i</div>
      </div>
      {expanded && (
        <div className="absolute overflow-visible w-0 h-0">
          <div className="instructions-popover-container">
            <p className="instructions-popover-main-text">
              {" "}
              {t("networkOnboarding.creationPopup.title")}
            </p>
            <div className="instructions-popover-row-container">
              <p className="instructions-popover-row-left">OCPP proxy url</p>
              <p className="instructions-popover-row-right">
                {onboardingChargerInstructions?.ocppProxyUrl}
              </p>
            </div>
            <div className="instructions-popover-row-container">
              <p className="instructions-popover-row-left">WSS/WS</p>
              <p className="instructions-popover-row-right">{onboardingChargerInstructions?.encryption}</p>
            </div>
            <div className="instructions-popover-row-container">
              <p className="instructions-popover-row-left">
                {t("networkOnboarding.creationPopup.port")}
              </p>
              <p className="instructions-popover-row-right">{onboardingChargerInstructions?.port}</p>
            </div>
            <div className="instructions-popover-row-container">
              <p className="instructions-popover-row-left">
                {t("networkOnboarding.creationPopup.protocol")}
              </p>
              <p className="instructions-popover-row-right">{onboardingChargerInstructions?.availableProtocols}</p>
            </div>
            <div className="instructions-popover-row-container">
              <p className="instructions-popover-row-left">
                {t("networkOnboarding.creationPopup.password")}
              </p>
              <p className="instructions-popover-row-right">{onboardingChargerInstructions?.password}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InstructionsPopover;
