import React, { useState, useEffect, Fragment, useMemo } from "react";
import { Slider } from "@mui/material";
import { useToast } from "../../../shared/toast/ToastContent";
import "./LoadManagement.css";
import { useStore } from "react-stores";
import {
  ChargerStore,
  handleGetChargers,
  handleGetConnectorsForCharger,
  setConnectorPowerLimit,
} from "../../../stores/chargerStore";
import { sleep } from "../../../helpers/timeHelper";
import Spinner from "../../../shared/spinner/Spinner";
import { useTranslation } from "react-i18next";

interface Connector {
  id: number;
  connectorName: string;
  connectorType: string;
  electricCurrent: string;
  maxPower?: number;
  powerLimit?: number;
  staticPowerLimit?: number;
}

interface Props {
  selectedCharger: number;
  handleExit: (changed: boolean) => void;
}

const SetPowerLimit: React.FC<Props> = ({ selectedCharger, handleExit }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<number[]>([]);
  const [expanded, setExpanded] = useState<number>(0);
  const [connectors, setConnectors] = useState<Connector[]>([] as Connector[]);
  const [editedConnectors, setEditedConnectors] = useState<Connector[]>(
    [] as Connector[]
  );
  const [powerLimitValue, setPowerLimitValue] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const { showToast } = useToast();

  const canSet = useMemo(() => {
    return selected.length > 0;
  }, [selected]);

  useEffect(() => {
    const fetchConnectors = async () => {
      const data = await handleGetConnectorsForCharger(selectedCharger);
      if (data?.connectors) {
        setConnectors(data.connectors);
        setEditedConnectors(data.connectors);
      }
    };
    fetchConnectors();
  }, [selectedCharger]);

  useEffect(() => {
    setPowerLimitValue(
      editedConnectors.find((el) => el.id === expanded)?.staticPowerLimit || 0
    );
  }, [editedConnectors, expanded]);

  const handleSelection = (id: number, e: any) => {
    e.stopPropagation();
    if (
      e.target.className === "checker-border-large" ||
      e.target.className === "correct-icon"
    ) {
      setSelected((prevSelected) =>
        !prevSelected.includes(id)
          ? [...prevSelected, id]
          : prevSelected.filter((selectedId) => selectedId !== id)
      );
    } else {
      setExpanded((prev) => (prev === id ? 0 : id));
    }
  };

  const handleSetBtnClick = async () => {
    const setPowerLimit = async () => {
      editedConnectors
        .filter((connector) => selected.includes(connector.id))
        .forEach((connector) =>
          setConnectorPowerLimit({
            connectorId: connector.id,
            limit: Number(connector.staticPowerLimit),
          })
        );
    };
    setLoading(true);
    await setPowerLimit();
    await sleep(1000);
    await handleGetConnectorsForCharger(selectedCharger);
    handleExit(true);
    setLoading(false);
    showToast(t("loadManagement.setPowerLimit.successMessage"), "success");
  };

  return (
    <>
      <div className="modal-background" onClick={() => handleExit(false)} />
      <div className="absolute-set-power-limit pt-2">
        <div className="flex flex-col items-center justify-start gap-3">
          <div className="flex justify-end items-center w-full p-5">
            <img
              src="/icons/exit/cross-big.svg"
              alt=""
              onClick={() => handleExit(false)}
              className="cursor-pointer"
            />
          </div>
          <div className="horizontal-gray-line w-full"></div>
          <div className="flex flex-col justify-start items-stretch w-full px-10 py-7 gap-7">
            <div className="flex justify-end items-center gap-3">
              <button
                className={`register-btn ${canSet && "register-btn-active"}`}
                disabled={!canSet}
                onClick={handleSetBtnClick}
              >
                {t("loadManagement.setPowerLimit.setPowerLimitButton")}
              </button>
            </div>
            <div className="flex flex-col items-stretch justify-between overflow-auto table-container overflow-visible">
              <table className="table table-striped">
                <thead>
                  <tr className="header-row table-row">
                    <th></th>
                    <th>
                      {t(
                        "loadManagement.setPowerLimit.tableHeaders.connectorId"
                      )}
                    </th>
                    <th>
                      {t(
                        "loadManagement.setPowerLimit.tableHeaders.connectorType"
                      )}
                    </th>
                    <th>
                      {t(
                        "loadManagement.setPowerLimit.tableHeaders.electricCurrent"
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {connectors.map((connector, index) => (
                    <Fragment key={index}>
                      <tr
                        className={
                          expanded === connector.id
                            ? "selected-row selectable-row"
                            : "selectable-row"
                        }
                        onClick={(e) => handleSelection(connector.id, e)}
                      >
                        <td
                          id="select-icon"
                          onClick={(e) => handleSelection(connector.id, e)}
                        >
                          {selected.includes(connector.id) ? (
                            <img
                              src="/icons/correct-incorrect-icons/correct-icon-squared-large.svg"
                              alt=""
                              className="correct-icon"
                            />
                          ) : (
                            <div className="checker-border-large" />
                          )}
                        </td>
                        <td
                          className={
                            selected.includes(connector.id)
                              ? "td-selected-row"
                              : ""
                          }
                        >
                          {connector.connectorName}
                        </td>
                        <td
                          className={
                            selected.includes(connector.id)
                              ? "td-selected-row"
                              : ""
                          }
                        >
                          {connector.connectorType}
                        </td>
                        <td
                          className={
                            selected.includes(connector.id)
                              ? "td-selected-row"
                              : ""
                          }
                        >
                          {connector.electricCurrent}
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="w-full mt-2">
              <div className="flex flex-col gap-3 w-full items-left text-[20px]">
                <p className="font-semibold text-[#111F47]">
                  {" "}
                  {t("loadManagement.setPowerLimit.setPowerLimitLabel")}{" "}
                  {expanded
                    ? `(0-${
                        connectors.find((el) => el.id === expanded)?.maxPower
                      } kW)`
                    : ""}
                </p>
                <div className="flex gap-4 items-center w-full text-[#B5BAC6]">
                  <Slider
                    aria-label="PowerLimit"
                    defaultValue={powerLimitValue}
                    value={powerLimitValue}
                    color="info"
                    min={0}
                    max={connectors.find((el) => el.id === expanded)?.maxPower}
                    step={0.1}
                    onChange={(event, value) => {
                      setPowerLimitValue(value instanceof Array ? 0 : value);
                      const editedIdx = editedConnectors.findIndex(
                        (el) => el.id === expanded
                      );
                      if (editedIdx >= 0 && !(value instanceof Array))
                        setEditedConnectors((prev) => {
                          prev[editedIdx].staticPowerLimit = value;
                          return prev;
                        });
                    }}
                    disabled={!expanded}
                  />
                  <div className="w-[70px] h-[40px] flex items-center justify-center border border-[#B5BAC6] ml-3 rounded-lg">
                    {powerLimitValue}
                  </div>
                  <div>kW</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Spinner />}
    </>
  );
};

export default SetPowerLimit;
