import { Store } from "react-stores";
import { IDashboardStore } from "../interfaces/IStores";
import { AuthStore } from "./authStore";
import {
  calculateMiddleDate,
  getFormattedQueryString,
  getMarketsToformattedString,
} from "../helpers/dataHelper";

const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;

export const DashboardStore = new Store<IDashboardStore>({
  chargersStatus: null,
  revenue: [],
  energyConsumption: [],
  timeUtilization: [],
  capacityUtilization: [],
  connectorsEVSE: [],
  connectorsEVSECount: 0,
});

export const handleGetChargerStatus = async (companyMarketIds: number[]) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    let marketsString = getMarketsToformattedString(companyMarketIds);

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Dashboards/GetDashboardEVSEStatus${marketsString}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    const data = await response.json();
    DashboardStore.setState({
      ...DashboardStore.state,
      chargersStatus: data,
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleGetRevenue = async (
  startDate: string = "",
  endDate: string = "",
  companyMarketIds: number[],
  locationIds: number[] = [],
  chargerIds: number[] = [],
  currencyISO: string
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    const queryString = getFormattedQueryString({
      CompanyMarketIds: companyMarketIds,
      LocationIds: locationIds,
      ChargerIds: chargerIds,
    });

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Dashboards/GetDashboardRevenue${queryString}${
        startDate ? "&from=" + startDate : ""
      }${endDate ? "&to=" + endDate : ""}&currencyISO=${currencyISO}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    const data = await response.json();
    DashboardStore.setState({
      ...DashboardStore.state,
      revenue: data.pointDtos.map((point) => {
        return {
          value: point.price,
          from: point.from,
          to: point.to,
          middle: calculateMiddleDate(point.from, point.to),
        };
      }),
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleGetEnergyConsumption = async (
  startDate: string = "",
  endDate: string = "",
  companyMarketIds: number[],
  locationIds: number[] = [],
  chargerIds: number[] = []
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    const queryString = getFormattedQueryString({
      CompanyMarketIds: companyMarketIds,
      LocationIds: locationIds,
      ChargerIds: chargerIds,
    });

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Dashboards/GetDashboardEnergyConsumption${queryString}${
        startDate ? "&from=" + startDate : ""
      }${endDate ? "&to=" + endDate : ""}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    const data = await response.json();
    DashboardStore.setState({
      ...DashboardStore.state,
      energyConsumption: data.pointDtos.map((point) => {
        return {
          value: point.consumption,
          from: point.from,
          to: point.to,
          middle: calculateMiddleDate(point.from, point.to),
        };
      }),
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleGetUtilization = async (
  startDate: string = "",
  endDate: string = "",
  companyMarketIds: number[],
  locationIds: number[] = [],
  chargerIds: number[] = []
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    const queryString = getFormattedQueryString({
      CompanyMarketIds: companyMarketIds,
      LocationIds: locationIds,
      ChargerIds: chargerIds,
    });

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Dashboards/GetDashboardUtilization${queryString}${
        startDate ? "&from=" + startDate : ""
      }${endDate ? "&to=" + endDate : ""}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    const data = await response.json();
    DashboardStore.setState({
      ...DashboardStore.state,
      timeUtilization: data.timeUtilizationPointDtos.map((point) => {
        return {
          value: point.evseOccupancy,
          from: point.from,
          to: point.to,
          middle: calculateMiddleDate(point.from, point.to),
        };
      }),
      capacityUtilization: data.capacityUtilizationPointDtos.map((point) => {
        return {
          value: point.capacityUtilization,
          from: point.from,
          to: point.to,
          middle: calculateMiddleDate(point.from, point.to),
        };
      }),
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleGetConnectorsByEVSEStatus = async (
  status: string,
  companyMarketIds: number[],
  pageIndex: number,
  pageSize: number
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    const url = new URL(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Connectors/GetConnectorsByEVSEStatus`
    );

    url.searchParams.append("status", status);

    companyMarketIds.forEach((id) => {
      url.searchParams.append("CompanyMarketIds", id.toString());
    });

    url.searchParams.append("PageIndex", pageIndex.toString());
    url.searchParams.append("PageSize", pageSize.toString());

    const response = await fetch(url, {
      method: "GET",
      headers: myHeaders,
    });

    const data = await response.json();
    DashboardStore.setState({
      ...DashboardStore.state,
      connectorsEVSE: data.data,
      connectorsEVSECount: data.totalCount,
    });
    return data.data;
  } catch (e) {
    console.log("Error: ", e);
  }
};
