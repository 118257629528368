// import React, { useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import "./DateSelectRange.css";
import { useState } from "react";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

interface Props {
  showCalendar: Function;
  start: string;
  end: string;
  handleApply: Function;
}

const DateSelectRange: React.FC<Props> = ({
  showCalendar,
  start,
  end,
  handleApply,
}) => {
  const { t } = useTranslation();

  const [activeButton, setActiveButton] = useState<string>("");

  const setToStartOfDay = (date: Date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };

  const setToEndOfDay = (date: Date) => {
    const newDate = new Date(date);

    newDate.setHours(23, 59, 59, 999);
    return newDate;
  };

  const [selectionRange, setSelectionRange] = useState({
    startDate: start ? new Date(start) : null,
    endDate: end ? new Date(end) : null,
  });

  const handleDateChange = (range) => {
    const [startDate, endDate] = range;
    setSelectionRange({
      startDate: startDate ? setToStartOfDay(startDate) : null,
      endDate: endDate ? setToEndOfDay(endDate) : null,
    });
  };

  const handleTodayClick = () => {
    const today = new Date();
    setSelectionRange({
      startDate: today ? setToStartOfDay(today) : null,
      endDate: today ? setToEndOfDay(today) : null,
    });
    setActiveButton("Today");
  };

  const handleYesterdayClick = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    setSelectionRange({
      startDate: yesterday ? setToStartOfDay(yesterday) : null,
      endDate: yesterday ? setToEndOfDay(yesterday) : null,
    });
    setActiveButton("Yesterday");
  };
  const handleThisMonthClick = () => {
    const start = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const end = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    );
    setSelectionRange({
      startDate: start ? setToStartOfDay(start) : null,
      endDate: end ? setToEndOfDay(end) : null,
    });
    setActiveButton("This month");
  };
  const handleThisYearClick = () => {
    const startDate = new Date(new Date().getFullYear(), 0, 1);
    const endDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    );
    setSelectionRange({
      startDate: startDate ? setToStartOfDay(startDate) : null,
      endDate: endDate ? setToEndOfDay(endDate) : null,
    });
  };

  const handlePastMonthClick = () => {
    const startDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 1,
      1
    );
    const endDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      0
    );
    setSelectionRange({
      startDate: startDate ? setToStartOfDay(startDate) : null,
      endDate: endDate ? setToEndOfDay(endDate) : null,
    });
  };

  const handlePast3MonthsClick = () => {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth() - 3, 1);
    const endDate = new Date(today.getFullYear(), new Date().getMonth(), 0);

    setSelectionRange({
      startDate: startDate ? setToStartOfDay(startDate) : null,
      endDate: endDate ? setToEndOfDay(endDate) : null,
    });
  };

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      const { startDate, endDate } = selectionRange;
      if (date >= startDate! && date <= endDate!) {
        return "react-calendar__tile--active";
      }
    }
    return null;
  };

  const handleResetClick = () => {
    setSelectionRange({ startDate: null, endDate: null });
    setActiveButton("");
  };

  const handleApplyClick = () => {
    const { startDate, endDate } = selectionRange;
    if (startDate && endDate) {
      let formattedStartDate = startDate.toISOString();
      let formattedEndDate = endDate.toISOString();

      showCalendar(false);
      handleApply(formattedStartDate, formattedEndDate);
    }
  };

  return (
    <>
      <div
        className="calendar-modal-background"
        onClick={() => showCalendar(false)}
      />
      <div className="modal" role="dialog" aria-modal="true">
        <div style={{ display: "flex", padding: "5px" }}>
          <Calendar
            locale={i18n.language}
            className="calendar"
            tileClassName={tileClassName}
            next2Label={null}
            prev2Label={null}
            selectRange={true}
            onChange={handleDateChange}
            value={[selectionRange.startDate, selectionRange.endDate]}
          />
        </div>
        <div className="line-item-divider" />
        <div className="calendar-btn-container">
          <div className="col">
            <button
              className={`calendar-btn ${
                activeButton === "Today" ? "focus" : ""
              }`}
              onClick={handleTodayClick}
            >
              {t("calendar.today")}
            </button>
            <button
              className={`calendar-btn ${
                activeButton === "Yesterday" ? "focus" : ""
              }`}
              onClick={handleYesterdayClick}
            >
              {t("calendar.yesterday")}
            </button>
            <button
              className={`calendar-btn ${
                activeButton === "This month" ? "focus" : ""
              }`}
              onClick={handleThisMonthClick}
            >
              {t("calendar.thisMonth")}
            </button>
          </div>
          <div className="col">
            <button className="calendar-btn" onClick={handlePastMonthClick}>
              {t("calendar.pastMonth")}
            </button>
            <button className="calendar-btn" onClick={handlePast3MonthsClick}>
              {t("calendar.past3Months")}
            </button>
            <button className="calendar-btn" onClick={handleThisYearClick}>
              {t("calendar.thisYear")}
            </button>
          </div>
        </div>
        <div className="line-item-divider" />
        <div className="flex flex-row justify-center items-center w-[100%] p-2">
          <button className="resetButtonRdr mr-5" onClick={handleResetClick}>
            {t("calendar.reset")}
          </button>
          <button className="saveButtonRdr" onClick={handleApplyClick}>
            {t("calendar.apply")}
          </button>
        </div>
      </div>
    </>
  );
};

export default DateSelectRange;
