import React, { useState, useEffect } from "react";
import "./Paging.css";
import { useTranslation } from "react-i18next";

interface PagingProps {
  tableSize: number;
  pageChangeFunc: Function;
}

const Paging = ({ tableSize, pageChangeFunc }) => {
  const { t } = useTranslation();
  const [numOfPages, setNumOfPages] = useState(1);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    const calculateNumOfPages = () => {
      const calculatedNumOfPages = tableSize
        ? Math.ceil(tableSize / perPage)
        : 1;
      setNumOfPages(calculatedNumOfPages);
      setPage(1);
    };

    calculateNumOfPages();
  }, [tableSize, perPage]);

  useEffect(() => {
    pageChangeFunc(page, perPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage]);

  function handlePageChange(newPage: number) {
    if (page + newPage <= 0) {
      setPage(1);
    } else {
      if (page + newPage >= numOfPages) {
        setPage(numOfPages);
      } else {
        setPage((old) => old + newPage);
      }
    }
  }

  function handlePerPageChange(newPerPage: number) {
    setPerPage(newPerPage);
    setPage(1);
  }

  return (
    <div className="table-footer">
      <div className="flex flex-row justify-start items-center gap-3">
        <p className="items-per-page">{t("paging.main")}</p>
        <div
          className={`per-page ${perPage === 10 ? "per-page-selected" : ""}`}
          onClick={() => handlePerPageChange(10)}
        >
          10
        </div>

        <div
          className={`per-page ${perPage === 20 ? "per-page-selected" : ""}`}
          onClick={() => handlePerPageChange(20)}
        >
          20
        </div>

        <div
          className={`per-page ${perPage === 50 ? "per-page-selected" : ""}`}
          onClick={() => handlePerPageChange(50)}
        >
          50
        </div>
      </div>
      <div className="flex flex-row justify-end gap-0 items-stretch">
        <div
          className={`arrow-left ${page === 1 ? "" : "arrow-blue"}`}
          onClick={() => handlePageChange(-1)}
        >
          {page === 1 ? (
            <img src="/icons/arrow/arrowLeft.svg" className="h-8" alt="" />
          ) : (
            <img src="/icons/arrow/arrowLeftWhite.svg" className="h-8" alt="" />
          )}
        </div>
        <div className="one-of-five">
          {page} {t("paging.of")} {numOfPages}
        </div>
        <div
          className={`arrow-right ${page === numOfPages ? "" : "arrow-blue"}`}
          onClick={() => handlePageChange(1)}
        >
          {page === numOfPages ? (
            <img src="/icons/arrow/arrowRight.svg" className="h-8" alt="" />
          ) : (
            <img
              src="/icons/arrow/arrowRightWhite.svg"
              className="h-8"
              alt=""
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Paging;
