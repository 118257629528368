import React, { useState, useEffect } from "react";
import { useStore } from "react-stores";
import "./Roaming.css";
import PartnersTable from "./PartnersTable";
import RegisterPopup from "./popups/RegisterPopup";
import MarketDropdown from "../../shared/marketDropdown/MarketDropdown";
import { IMarketDropdown } from "../../interfaces/IMarket";
import EVSEStatusDropdown, { EVSERoamingType } from "./EVSEStatusDropdown";
import Table from "../../shared/table/Table";
import Paging from "../../shared/paging/Paging";
import ChargerModal from "./ChargerModal";
import WithdrawPopup from "./popups/WithdrawPopup";
import PublishedEVSETable from "./PublishedEVSETable";
import UnPublishedEVSETable from "./UnPublishedEVSETable";
import RoamingMarketDropdown from "./popups/RoamingMarketDropdwon";
import {
  Connector,
  IChargerRoaming,
  ICompanyMarketRoaming,
} from "../../interfaces/IRoaming";
import { usePublishedChargersQuery } from "../../hooks/roaming/usePublishedChargers";
import { useUnPublishedChargersQuery } from "../../hooks/roaming/useUnPublishedChargers";
import PublishPopup from "./popups/PublishPopup";
import { useRoamingCompanyMarketsPaginatedQuery } from "../../hooks/roaming/useCompanyMarketsPaginated";
import { useTranslation } from "react-i18next";

const RoamingMain = () => {
  const { t } = useTranslation();
  const [publishingCharger, setPublishingCharger] = useState<
    IChargerRoaming | undefined
  >();
  const [selectedPartners, setSelectedPartners] = useState<number>(0);
  const [selectedStatus, setSelectedStatus] = useState<EVSERoamingType>(
    EVSERoamingType.Unpublished
  );
  const [widthDrawPopup, setWithdrawPopup] = useState(false);
  const [publishPopup, setPublishPopup] = useState(false);
  const [chargerModal, setChargerModal] = useState(false);
  const [selectedPublished, setSelectedPublished] = useState<number>(0);
  const [selectedUnpublished, setSelectedUnpublished] = useState<number>(0);
  const [selectedMarket, setSelectedMarket] =
    useState<ICompanyMarketRoaming | null>(null);
  const [paging, setPaging] = useState({ page: 1, perPage: 10 });

  const [pagingPublished, setPagingPublished] = useState({
    page: 1,
    perPage: 10,
  });
  const [pagingUnpublished, setPagingUnpublished] = useState({
    page: 1,
    perPage: 10,
  });
  const [page, setPage] = useState<"Partners" | "EVSEs">("Partners");
  const [sort, setSort] = useState({
    field: "",
    descending: true,
  });
  const [sortPublished, setSortPubished] = useState({
    field: "",
    descending: true,
  });
  const [sortUnpublished, setSortUnpublished] = useState({
    field: "",
    descending: true,
  });
  const [registerPopup, setRegisterPopup] = useState<boolean>(false);

  const { data: roamingPaginated, refetch: refetchCompanyMarketsPaginated } =
    useRoamingCompanyMarketsPaginatedQuery(
      sort.field,
      sort.descending,
      paging.page,
      paging.perPage,
      false
    );

  const { data: publishedChargers, refetch: refetchPublishedChargers } =
    usePublishedChargersQuery(
      selectedMarket?.id || 0,
      sortPublished.field,
      sortPublished.descending,
      pagingPublished.page,
      pagingPublished.perPage,
      {
        enabled: selectedMarket !== null && selectedStatus === "Published",
      }
    );

  const { data: unpublishedChargers, refetch: refetchUnpublishedChargers } =
    useUnPublishedChargersQuery(
      selectedMarket?.id || 0,
      sortUnpublished.field,
      sortUnpublished.descending,
      pagingUnpublished.page,
      pagingUnpublished.perPage,
      {
        enabled: selectedMarket !== null && selectedStatus === "Unpublished",
      }
    );
  useEffect(() => {
    refetchCompanyMarketsPaginated();
  }, [paging, sort, selectedStatus, refetchCompanyMarketsPaginated]);
  useEffect(() => {
    if (selectedMarket && selectedStatus === "Published") {
      refetchPublishedChargers();
    }
  }, [
    pagingPublished,
    sortPublished,
    selectedMarket,
    selectedStatus,
    refetchPublishedChargers,
  ]);

  useEffect(() => {
    if (selectedMarket && selectedStatus === "Unpublished") {
      refetchUnpublishedChargers();
    }
  }, [
    pagingUnpublished,
    sortUnpublished,
    selectedMarket,
    selectedStatus,
    refetchUnpublishedChargers,
  ]);

  const closeChargerModal = () => {
    setSelectedUnpublished(0);
  };

  const selectedCharger = unpublishedChargers?.find(
    (charger) => charger.id === selectedUnpublished
  );

  const selectedPublishedCharger = publishedChargers?.find(
    (charger) => charger.id === selectedPublished
  );

  return (
    <div className="flex-1 p-3.5 flex flex-col gap-3.5 items-stretch main-wrapper">
      <div className="bg-white card p-5">
        <div className="flex flex-row justify-between items-center header flex-wrap">
          <div className="roaming">
            <span>
              <span className="roaming-span">{t("roaming.title_1")}</span>
              <span className="roaming-span2">{t("roaming.title_2")}</span>
            </span>
          </div>
          {page === "Partners" ? (
            <button
              className={`register-btn ${
                selectedPartners && "register-btn-active"
              }`}
              disabled={!selectedPartners}
              onClick={() => setRegisterPopup(true)}
            >
              {t("roaming.register")}
            </button>
          ) : (
            <div className="flex items-center gap-4">
              <RoamingMarketDropdown
                handleSelection={(selectedMarket) => {
                  setSelectedMarket(selectedMarket);
                  setPaging((prev) => ({ page: 1, perPage: prev.perPage }));
                }}
              />
              <EVSEStatusDropdown onSelect={setSelectedStatus} />
            </div>
          )}
        </div>
      </div>
      <div className="table-container p-5 flex flex-col gap-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center mb-6">
            <button
              className={
                page === "Partners"
                  ? "roaming-page-btn roaming-left-curved roaming-page-btn-active"
                  : "roaming-page-btn roaming-left-curved"
              }
              onClick={() => {
                setPage("Partners");
                setSelectedStatus(EVSERoamingType.Unpublished);
              }}
            >
              {t("roaming.partners")}
            </button>
            <button
              className={
                page === "EVSEs"
                  ? "roaming-page-btn roaming-right-curved roaming-page-btn-active"
                  : "roaming-page-btn roaming-right-curved"
              }
              onClick={() => setPage("EVSEs")}
            >
              {t("roaming.evses")}
            </button>
          </div>
          {selectedStatus === "Published" && (
            <button
              onClick={() => selectedPublished && setWithdrawPopup(true)}
              className={`${
                selectedPublished ? "stopChargingButtonActive" : ""
              } stopChargingButton`}
              disabled={!selectedPublished}
            >
              {t("roaming.withdraw")}
            </button>
          )}
        </div>

        {page === "Partners" ? (
          <PartnersTable
            data={roamingPaginated || []}
            selected={selectedPartners}
            handleSelection={(id) =>
              setSelectedPartners((prev) => (prev === id ? 0 : id))
            }
            setPaging={setPaging}
            setSorting={setSort}
          />
        ) : (
          <>
            {selectedMarket && selectedStatus === "Published" ? (
              <PublishedEVSETable
                data={publishedChargers || []}
                selected={selectedPublished}
                handleSelection={(id) =>
                  setSelectedPublished((prev) => (prev === id ? 0 : id))
                }
                setPaging={setPagingPublished}
                setSorting={setSortPubished}
              />
            ) : selectedMarket && selectedStatus === "Unpublished" ? (
              <UnPublishedEVSETable
                data={unpublishedChargers || []}
                selected={selectedUnpublished}
                handleSelection={(id) =>
                  setSelectedUnpublished((prev) => (prev === id ? 0 : id))
                }
                setChargerModal={setChargerModal}
                setPaging={setPagingUnpublished}
                setSorting={setSortUnpublished}
              />
            ) : (
              <div className="p-4 text-[#111F47] bg-[#E9EDFC] text-[15px] font-semibold text-center">
                {t("roaming.text")}
              </div>
            )}
          </>
        )}
      </div>
      {registerPopup && (
        <RegisterPopup
          refetch={refetchCompanyMarketsPaginated}
          partnerId={selectedPartners}
          exit={() => {
            setSelectedPartners(0);
            setRegisterPopup(false);
          }}
        />
      )}
      {selectedUnpublished !== 0 && (
        <ChargerModal
          charger={selectedCharger!}
          setModal={closeChargerModal}
          setPublishingCharger={setPublishingCharger}
          setConfirmPopup={setPublishPopup}
        />
      )}
      {widthDrawPopup && selectedPublishedCharger?.connectors && (
        <WithdrawPopup
          charger={selectedPublishedCharger}
          connectors={selectedPublishedCharger?.connectors}
          close={setWithdrawPopup}
          refetchPublished={refetchPublishedChargers}
        />
      )}
      {publishPopup && publishingCharger && (
        <PublishPopup
          charger={publishingCharger}
          close={setPublishPopup}
          closeChargerModal={setSelectedUnpublished}
          refetchUnpublished={refetchUnpublishedChargers}
        />
      )}
    </div>
  );
};

export default RoamingMain;
