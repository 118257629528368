import React, { useState, useEffect, useRef } from "react";
import moment from "moment/moment";
import {
  ActiveStore,
  getSignalRConnection,
  handleGetActiveSessions,
  setSignalRConnection,
  closeSignalRConnection,
} from "../../../stores/activeStore";
import {
  closeConnection,
  startConnection,
} from "../../../helpers/signalRHelper";
import { useStore } from "react-stores";
import "./ActiveSession.css";
import Popup from "../../../shared/popup/Popup";
import Spinner from "../../../shared/spinner/Spinner";
import Toast from "../../../shared/toast/Toast";
import Table from "../../../shared/table/Table";
import Paging from "../../../shared/paging/Paging";
import MarketDropdown from "../../../shared/marketDropdown/MarketDropdown";
import {
  ILocationDropdown,
  IMarketDropdown,
} from "../../../interfaces/IMarket";
import { ISessionActive } from "../../../interfaces/ISession";
import AdvancedFilter from "../../../shared/advancedFilter/AdvancedFilter";
import LoadingPopup, {
  Step,
  StepState,
} from "../../../shared/loadingPopup/LoadingPopup";
import { IChargerDropdown } from "../../../interfaces/ICharger";
import {
  closeNewConnection,
  handleStopSession,
} from "../../../helpers/loadingPopupLogicHelper";
import { useTranslation } from "react-i18next";

const ActiveSessions = () => {
  const { t } = useTranslation();
  const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;
  const [selected, setSelected] = useState<number>(0);
  const [searched, setSearched] = useState<string>("");
  const [paging, setPaging] = useState({ page: 1, perPage: 10 });
  const [selectedFilters, setSelectedFilters] = useState({
    markets: [] as IMarketDropdown[],
    locations: [] as ILocationDropdown[],
    chargers: [] as IChargerDropdown[],
  });
  const [sort, setSort] = useState({ field: "", descending: true });

  const { totalCount } = useStore(ActiveStore);
  const [dataTransfered, setDataTransfered] = useState(false);

  const tableHeaders: string[] = [
    t("sessions.active.tableHeaders.startTime"),
    t("sessions.active.tableHeaders.chargingSessionId"),
    t("sessions.active.tableHeaders.market"),
    t("sessions.active.tableHeaders.duration"),
    t("sessions.active.tableHeaders.electricityConsumed"),
    t("sessions.active.tableHeaders.carBattery"),
    t("sessions.active.tableHeaders.currentBill"),
    t("sessions.active.tableHeaders.connectorId"),
    t("sessions.active.tableHeaders.currentPower"),
  ];

  const tableRowOrder: string[] = [
    "timeStart",
    "id",
    "companyMarketName",
    "duration",
    "electricityConsumed",
    "currentCarBattery",
    "totalPrice",
    "connectorId",
    "currentPower",
  ];

  const [toastText, setToastText] = useState<string>("");
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastStatus, setToastStatus] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPopup, setLoadingPopup] = useState<boolean>(false);
  const [stopChargingPopup, setStopChargingPopup] = useState<boolean>(false);
  const [stopChargingTimeout, setStopChargingTimeout] = useState<
    NodeJS.Timeout | undefined
  >(undefined);
  const [activeSessions, setActiveSessions] = useState<ISessionActive[]>(
    [] as ISessionActive[]
  );
  const [deletedOrInserted, setDeletedOrInserted] = useState(false);

  let clockTicking: NodeJS.Timeout | null = null;

  const initialStopSessionSteps = () => {
    return [
      {
        text: t("sessions.active.stopSessionSteps.sending"),
        status: StepState.Pending,
        progressReady: false,
      },
      {
        text: t("sessions.active.stopSessionSteps.stopping"),
        status: StepState.Pending,
        progressReady: false,
      },
      {
        text: t("sessions.active.stopSessionSteps.payment"),
        status: StepState.Pending,
        progressReady: false,
      },
      {
        text: t("sessions.active.stopSessionSteps.generating"),
        status: StepState.Pending,
        progressReady: false,
      },
    ];
  };

  const [loadingStopSessionSteps, setLoadingStepSessionSteps] = useState<
    Step[]
  >(initialStopSessionSteps());

  useEffect(() => {
    fetchDataFromApi();

    return () => {
      closeSignalRConnection();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, searched, selectedFilters, sort, deletedOrInserted]);

  const fetchDataFromApi = async () => {
    const fetchSessions = async () => {
      try {
        let sessions = await handleGetActiveSessions(
          selectedFilters.markets.map((market) => market.id),
          selectedFilters.locations.map((location) => location.id),
          selectedFilters.chargers.map((charger) => charger.id),
          paging.page,
          paging.perPage,
          searched,
          sort.field,
          sort.descending
        );
        sessions ? setActiveSessions(sessions) : setActiveSessions([]);
        return sessions || [];
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (selectedFilters.markets?.length > 0) {
      let data = await fetchSessions();
      if (data && data.length > 0) {
        if (getSignalRConnection() === null)
          await handleSignalRConnection(data);
        else handleExistingConnectionSubscriptions(data);
        setDataTransfered((prev) => !prev);
      }
    }
  };

  useEffect(() => {
    startClock();

    return () => {
      clockTicking && clearInterval(clockTicking);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTransfered]);

  const startClock = () => {
    if (clockTicking) clearInterval(clockTicking);

    clockTicking = setInterval(() => {
      setActiveSessions((prevSessions) => {
        return prevSessions.map((session) => {
          const dur = moment.duration(session.duration).add(1, "second");
          return {
            ...session,
            duration: moment.utc(dur.asMilliseconds()).format("HH:mm:ss"),
          };
        });
      });
    }, 1000);
  };

  const handleSignalRConnection = async (sessions) => {
    try {
      console.log("Creating new SignalR connection");
      let conn = await startConnection(`${PUBLIC_BASE_URL}hubs/sessions`);

      await conn
        .invoke("SubscribeActiveSessions")
        .catch((err) => console.error("Subscription error:", err));

      conn.on("newSessionAdded", async (sessionInfo) => {
        console.log("New session added: ", +sessionInfo);
        fetchDataFromApi();
      });

      sessions.forEach(async (session) => {
        await conn
          .invoke("SubscribeB2B", session.id)
          .catch((err) => console.error("Subscription error:", err));
      });

      conn.on("receiveSessionInfo", (sessionInfo) => {
        console.log("Received session info update:", sessionInfo);
      });

      conn.on("updateSession", (updateSessionSignalRDto) => {
        console.log("Session update received:", updateSessionSignalRDto);

        const {
          sessionId,
          currentPrice,
          currentSoC,
          energyConsumed,
          currentPower,
        } = updateSessionSignalRDto;

        setActiveSessions((prevSessions) => {
          return prevSessions.map((session) => {
            if (session.id === sessionId) {
              return {
                ...session,
                totalPrice: `${currentPrice.toFixed(2)} ${session.currencyISO}`,
                currentCarBattery:
                  currentSoC >= 0 ? `${currentSoC.toFixed(2)}%` : "n/a",
                electricityConsumed: `${Math.abs(
                  energyConsumed.toFixed(2)
                )} kWh`,
                currentPower: `${Math.abs(currentPower.toFixed(2)) ?? 0} kW`,
              };
            }
            return session;
          });
        });
      });

      conn.on("sessionRemoved", (StopSessionSignalRDto) => {
        console.log("Session removed:", StopSessionSignalRDto);
        fetchDataFromApi();
      });

      conn.on("stopSession", handleStopSession);
      setSignalRConnection(conn);
      // return () => {
      //   conn.off("newSessionAdded");
      //   conn.off("receiveSessionInfo");
      //   conn.off("updateSession");
      //   conn.off("sessionRemoved");
      // };
    } catch (ex) {
      console.log("Error while establishing signalR connection: " + ex);
    }
  };

  const handleExistingConnectionSubscriptions = async (sessions) => {
    await getSignalRConnection()?.off("SubscribeB2B");

    sessions.forEach(async (session) => {
      await getSignalRConnection()
        ?.invoke("SubscribeB2B", session.id)
        .catch((err) => console.error("Subscription error:", err));
    });
  };

  // const handleStopSession = (stopSessionSignalRDto) => {
  //   console.log("Charging session stopped, id:", stopSessionSignalRDto.id);
  //   setSelected(0);
  //   setToastStatus(true);
  //   setToastText("Charging session successfully stopped");
  //   setToastVisible(true);
  //   setLoading(false);
  //   setPaging((prev) => ({ ...prev, page: 1 }));
  // };

  // const subscribeToStopSession = async (sessionId) => {
  //   try {
  //     await connection!
  //       .invoke("SubscribeToStopSession", sessionId)
  //       .then(() => {
  //         console.log("Subscribing to stop sessionId " + sessionId);
  //         //StopSessionById(sessionId).then(() => setSessionStopped(true));
  //       })
  //       .catch((err) => console.error("Subscription error:", err));
  //   } catch (error) {
  //     console.error("SignalR connection is not initialized.");
  //   }
  // };

  // const handleStopChargingClick = () => {
  //   if (selected) {
  //     subscribeToStopSession(selected);
  //     setStopChargingPopup(false);
  //     setLoading(true);
  //     setStopChargingTimeout(
  //       setTimeout(() => {
  //         if (loading) {

  //         }
  //       }, 20000)
  //     );
  //   }
  // };

  const handleSort = async (sortingField, sortingMethod) => {
    if (sortingMethod === "default") {
      setSort({ field: "", descending: true });
    } else {
      setSort({
        field: sortingField,
        descending: sortingMethod === "descending",
      });
    }
  };

  const handleSelection = (id: number) => {
    setSelected((prevSelected) => (prevSelected === id ? 0 : id));
  };

  const searchHistory = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearched(value);
    setPaging((prev) => ({ ...prev, page: 1 }));
  };

  const mainText = () => {
    return (
      <div className="flex flex-col items-start gap-3">
        <div className="active-session-popout-main-text">
          {t("sessions.active.popup.title")} {selected}
        </div>
        <div className="popout-other-text">
          {t("sessions.active.popup.text")}
        </div>
        <div className="h-[2.5px] bg-[#E7E9ED] w-[100%] mt-2"></div>
      </div>
    );
  };

  return (
    <div className="flex-1 px-3.5 pt-3.5 flex flex-col gap-3.5 items-stretch main-wrapper">
      <div className="bg-white card p-5">
        <div className="flex flex-row justify-between items-center header flex-wrap">
          <div className="charging-sessions-history">
            <span>
              <span className="charging-sessions-history-span">
                {t("sessions.active.title_1")}
              </span>
              <span className="charging-sessions-history-span2">
                {t("sessions.active.title_2")}
              </span>
            </span>
          </div>
          <div className="flex flex-row gap-5 flex-wrap">
            <AdvancedFilter
              handleSelection={(newMarkets) => {
                setSelectedFilters(newMarkets);
                setPaging((prev) => ({ page: 1, perPage: prev.perPage }));
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex-1 flex flex-col table-container p-5 relative">
        <div className="h-full">
          <div className="flex flex-row justify-between items center mb-2">
            <div className="relative">
              <img
                className="absolute h-5 left-3 top-2.5"
                src="/icons/table/searchIcon.svg"
                alt=""
              />
              <input
                className="searchInputActiveSessions"
                placeholder=""
                onChange={searchHistory}
              />
            </div>
            <button
              onClick={() => selected && setStopChargingPopup(true)}
              className={`${
                selected ? "stopChargingButtonActive" : ""
              } stopChargingButton`}
              disabled={!selected}
            >
              {t("sessions.active.stopCharging")}
            </button>
          </div>
          <div className="horizontal-gray-line w-[100%] mt-5 mb-6"></div>
          <Table
            tableHeaders={tableHeaders}
            tableRows={activeSessions}
            tableRowOrder={tableRowOrder}
            handleSelection={(id) => handleSelection(id)}
            selected={selected}
            sortColumns={tableHeaders}
            handleSort={(sortingField, sortingMethod) =>
              handleSort(sortingField, sortingMethod)
            }
            noMargin={true}
          />
        </div>

        <Paging
          tableSize={totalCount}
          pageChangeFunc={(page, perPage) =>
            setPaging({ page: page, perPage: perPage })
          }
        />
      </div>
      {stopChargingPopup && (
        <Popup
          mainText={mainText()}
          confirmFunction={() => {
            setStopChargingPopup(false);
            setLoadingPopup(true);
            handleStopSession(selected, setLoadingStepSessionSteps);
          }}
          confirmText={t("sessions.active.yes")}
          closeText={t("sessions.active.no")}
          closeFunction={() => setStopChargingPopup(false)}
        />
      )}
      {loading && <Spinner />}
      {toastVisible && (
        <Toast
          text={toastText}
          closeFunction={() => setToastVisible(false)}
          status={toastStatus}
        />
      )}
      {loadingPopup && (
        <LoadingPopup
          closeFunction={() => {
            setLoadingPopup(false);
            closeNewConnection();
            setLoadingStepSessionSteps(initialStopSessionSteps());
          }}
          steps={loadingStopSessionSteps}
          handleReset={() => {
            setLoadingStepSessionSteps(initialStopSessionSteps());
            handleStopSession(selected, setLoadingStepSessionSteps);
          }}
        />
      )}
    </div>
  );
};

export default ActiveSessions;

const loadingPopupTextRoaming = [
  "Sending control command to charger",
  "Charger stopping session",
  "Sending charging details to roaming provider",
  "Roaming provider accepting and acknowledging session stop",
];
