import moment from "moment";
import qrcode from "qrcode-generator";

export function formatDate(date: string) {
  let formatted = date.replaceAll("-", "/");
  formatted = formatted.split("T")[0];
  return formatted;
}

export function extractTime(date: string) {
  let [datePart, timePart] = date.split("T");

  let utcDate = new Date(`${datePart}T${timePart}Z`);

  let hours = utcDate.getHours();
  let minutes = utcDate.getMinutes();
  let seconds = utcDate.getSeconds();

  let formattedHours = hours.toString().padStart(2, "0");
  let formattedMinutes = minutes.toString().padStart(2, "0");
  let formattedSeconds = seconds.toString().padStart(2, "0");

  let localTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

  return localTime;
}

export const concatenateDateTime = (date, time) => {
  const formattedDate = date.split("T")[0].replace(/-/g, "/");
  return `${formattedDate} ${time.split(".")[0]}`;
};

export const formatDateLocale = (
  dateString: string,
  locale = navigator.language,
  dashboard: boolean = false
) => {
  let formattedDate = dateString.replaceAll("-", "/").split("T")[0];
  const date = new Date(formattedDate);

  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: dashboard ? "2-digit" : "numeric",
  };
  let localeDateString = new Intl.DateTimeFormat(locale, options).format(date);

  localeDateString = localeDateString.replaceAll(".", "/").replaceAll("-", "/");
  localeDateString = localeDateString
    .replaceAll(" /", "/")
    .replaceAll("/ ", "/");

  if (localeDateString.endsWith("/")) {
    localeDateString = localeDateString.slice(0, -1);
  }

  return localeDateString;
};

export const formatDateLocaleCalendar = (
  dateString: string,
  locale = navigator.language,
  dashboard: boolean = false
) => {
  const date = new Date(dateString); // Parse UTC string

  const day = date.getDate().toString().padStart(2, "0"); // Local day
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Local month
  const year = dashboard
    ? date.getFullYear().toString().slice(-2)
    : date.getFullYear().toString();

  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

export function formatTime(time: string) {
  time = time.slice(-1) === "Z" ? time.slice(0, -1) : time;
  let splited = time.split(":");
  for (let i = 0; i < splited.length; i++) {
    splited[i] = splited[i].split(".")[0];
  }
  return splited.join(":");
}

export function convertToLocalTime(date: string) {
  let UTCTime = new Date(date + " UTC");
  let localTime = UTCTime.toString();
  return moment(localTime).format("YYYY/MM/DD HH:mm:ss");
}

export function getFormattedQueryString(params: {
  [key: string]: number[];
}): string {
  const queryParts = Object.entries(params)
    .filter(([, values]) => values.length > 0)
    .map(([key, values]) => values.map((value) => `${key}=${value}`).join("&"));

  return queryParts.length > 0 ? `?${queryParts.join("&")}` : "";
}

export function getMarketsToformattedString(markets: number[]) {
  let marketsString = "?";
  markets.forEach((market) => (marketsString += `CompanyMarketIds=${market}&`));
  marketsString = marketsString.slice(0, -1);
  return marketsString;
}

export function getMarketsToformattedString2(markets: number[]) {
  let marketsString = "?";
  markets.forEach((market) => (marketsString += `companyMarketIds=${market}&`));
  marketsString = marketsString.slice(0, -1);
  return marketsString;
}

export function getConnectorsToformattedString(connectors: number[]) {
  let connectorsString = "?";
  connectors.forEach(
    (connector) => (connectorsString += `connectorIds=${connector}&`)
  );
  connectorsString = connectorsString.slice(0, -1);
  return connectorsString;
}

export function formatDateStringBasedOnDateRange(
  dateStart: string,
  dateStop: string,
  dateString: string,
  dayRangeLimit: number
) {
  let dateStartDate = new Date(dateStart);
  let dateStopDate = new Date(dateStop);
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  const diffDays = Math.floor(
    (dateStopDate.getTime() - dateStartDate.getTime()) / _MS_PER_DAY
  );

  if (diffDays >= dayRangeLimit) {
    // Format date only using locale
    return formatDateLocale(dateString, navigator.language, true);
  } else {
    return formatDateTimeLocaleDashboard(dateString);
  }
}

export const formatDateTimeLocaleDashboard = (
  dateString: string,
  locale = navigator.language
) => {
  const date = new Date(dateString);
  // Extract local date and time components
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  // Format as DD/MM/YY HH:mm
  const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}`;

  console.log("RETURNNNNNS", formattedDateTime);
  return formattedDateTime;
};

export const calculateMiddleDate = (
  dateString1: string,
  dateString2: string
) => {
  dateString1 = dateString1.slice(-1) === "Z" ? dateString1 : dateString1 + "Z";
  dateString2 = dateString2.slice(-1) === "Z" ? dateString2 : dateString2 + "Z";

  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);

  const _MS_PER_HOUR = 1000 * 60 * 60;
  const diffHours = Math.abs(
    (date2.getTime() - date1.getTime()) / _MS_PER_HOUR
  );

  const middleTime = (date1.getTime() + date2.getTime()) / 2;
  const middle = new Date(middleTime);

  if (diffHours % 2 !== 0) {
    const minutes = middle.getUTCMinutes();
    if (minutes !== 0) {
      const hours = middle.getUTCHours();
      const roundedHours = minutes >= 30 ? hours + 1 : hours;
      middle.setUTCHours(roundedHours, 0, 0, 0);
    }
  }

  return middle.toISOString();
};

export const convertToUTC = (dateStr: string) => {
  if (!dateStr) return "";
  const localDate = new Date(dateStr);
  const utcDate = new Date(
    localDate.getTime() - localDate.getTimezoneOffset() * 60000
  );
  return utcDate.toISOString();
};

export const dateFormatterWithHoursMinutes = (dateString: string): string => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

export function formatRelativeTime(datetimeStr: string): string {
  const eventTime = new Date(datetimeStr);
  const currentTime = new Date();
  const timeDifference = currentTime.getTime() - eventTime.getTime();

  const seconds = Math.floor(timeDifference / 1000);

  if (seconds < 60) {
    return "Now";
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else if (seconds < 86400) {
    const hours = Math.floor(seconds / 3600);
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else {
    const days = Math.floor(seconds / 86400);
    return `${days} day${days > 1 ? "s" : ""} ago`;
  }
}

export const generateQR = (qrString: string) => {
  const qr = qrcode(0, "M");
  qr.addData(qrString);
  qr.make();
  return qr.createDataURL(6, 24);
};

export const getConnectorClass = (status: string) => {
  const statusClassMap: { [key: string]: string } = {
    Available: "available",
    Faulted: "offline",
    Unavailable: "unavailable",
    Preparing: "occupied",
    Charging: "occupied",
    SuspendedEVSE: "unavailable",
    SuspendedEV: "unavailable",
    Finishing: "occupied",
    Reserved: "unavailable",
    Occupied: "occupied",
  };

  return statusClassMap[status];
};
