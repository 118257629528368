import { useState, useEffect } from "react";
import React from "react";
import {
  DashboardStore,
  handleGetChargerStatus,
  handleGetConnectorsByEVSEStatus,
  handleGetEnergyConsumption,
  handleGetRevenue,
  handleGetUtilization,
} from "../../stores/dashboardStore";
import {
  formatDateLocaleCalendar,
  formatDateStringBasedOnDateRange,
} from "../../helpers/dataHelper";
import { useStore } from "react-stores";
import "./DashboardOverview.css";
import Revenue from "./charts/Revenue";
import Utilization from "./charts/Utilization";
import Energy from "./charts/Energy";
import ChargerStatus from "./charts/ChargerStatus";
import DateSelectRange from "../../shared/calendar/DateSelectRange";
import CurrencyDropdown from "../../shared/currencyDropdown/CurrencyDropdown";
import {
  IChargersDropdown,
  ILocationDropdown,
  IMarketDropdown,
} from "../../interfaces/IMarket";
import PreloadMap from "../../shared/preloadMap/PreloadMap";
import AdvancedFilter from "../../shared/advancedFilter/AdvancedFilter";
import EvseStatusPopup from "./EvseStatusPopup";
import { useTranslation } from "react-i18next";

export type EVSEStatus = "Available" | "Unavailable" | "OutOfService" | "";

const DashboardOverview = () => {
  const { t } = useTranslation();
  const today = new Date();
  let start = new Date();
  start.setDate(today.getDate() - 90);
  const [calendar, setCalendar] = useState({
    startDate: start.toISOString(),
    endDate: new Date().toISOString(),
  });

  const [showDateModal, setShowDateModal] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    markets: [] as IMarketDropdown[],
    locations: [] as ILocationDropdown[],
    chargers: [] as IChargersDropdown[],
  });

  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [EVSEModalOpened, setEVSEModalOpened] = useState<boolean>(false);
  const [EVSEData, setEVSEData] = useState<any[]>([]);
  const [selectedEVSEStatus, setSelectedEVSEStatus] = useState<EVSEStatus>("");

  const {
    chargersStatus,
    energyConsumption,
    timeUtilization,
    capacityUtilization,
    revenue,
  } = useStore(DashboardStore);

  useEffect(() => {
    const marketIds = selectedFilters.markets?.map((market) => market.id) || [];
    if (marketIds.length > 0) {
      handleGetChargerStatus(marketIds);
    }
  }, [selectedFilters]);

  useEffect(() => {
    const updateCharts = () => {
      if (selectedFilters.markets?.length > 0 && selectedCurrency) {
        handleGetRevenue(
          calendar.startDate,
          calendar.endDate,
          selectedFilters.markets.map((market) => market.id),
          selectedFilters.locations.map((location) => location.id),
          selectedFilters.chargers.map((charger) => charger.id),
          selectedCurrency
        );
        handleGetEnergyConsumption(
          calendar.startDate,
          calendar.endDate,
          selectedFilters.markets.map((market) => market.id),
          selectedFilters.locations.map((location) => location.id),
          selectedFilters.chargers.map((charger) => charger.id)
        );
        handleGetUtilization(
          calendar.startDate,
          calendar.endDate,
          selectedFilters.markets.map((market) => market.id),
          selectedFilters.locations.map((location) => location.id),
          selectedFilters.chargers.map((charger) => charger.id)
        );
      }
    };
    updateCharts();
  }, [calendar, selectedFilters, selectedCurrency]);

  const handleClearDate = (event: React.MouseEvent) => {
    event.stopPropagation();
    setCalendar({
      startDate: start.toISOString(),
      endDate: today.toISOString(),
    });
  };

  const fetchEVSEData = async (status: EVSEStatus) => {
    try {
      if (selectedFilters.markets.length > 0) {
        const marketIds = selectedFilters.markets.map((market) => market.id);
        const data = await handleGetConnectorsByEVSEStatus(
          status,
          marketIds,
          1,
          10
        );
        setEVSEData(data);
      }
    } catch (e) {
      console.error("Failed to fetch EVSE data:", e);
    }
  };

  const openEVSEModal = (status: EVSEStatus) => {
    fetchEVSEData(status);
    setSelectedEVSEStatus(status);
    setEVSEModalOpened(true);
  };

  const handleEVSEModalExit = () => {
    setEVSEModalOpened(false);
    setEVSEData([]);
  };

  const handleFilterSelection = (filters: {
    markets: IMarketDropdown[];
    locations: ILocationDropdown[];
    chargers: IChargersDropdown[];
  }) => {
    console.log(filters);
    setSelectedFilters(filters);
  };

  return (
    <div className="flex-1 p-3.5 flex flex-col gap-2 items-stretch main-wrapper">
      <div className="grid grid-cols-1 gap-4 items-center h-100% w-100%">
        <div className="bg-white card p-5">
          <div className="flex flex-row justify-between items-center header flex-wrap">
            <div className="dashboard">
              <span>
                <span className="dashboard-span">{t("dashboard.title_1")}</span>
                <span className="dashboard-span2">
                  {t("dashboard.title_2")}
                </span>
              </span>
            </div>
            <div className="flex flex-row gap-5 flex-wrap">
              <CurrencyDropdown
                availableCurrencies={[
                  ...new Set(
                    selectedFilters.markets.map((market) => market.currencyIso)
                  ),
                ]}
                handleSelection={setSelectedCurrency}
              />
              <AdvancedFilter handleSelection={handleFilterSelection} />
              <button
                onClick={() => setShowDateModal(!showDateModal)}
                className={`calendarButton ${
                  showDateModal ? "calendarButtonActive" : ""
                } flex flex-row gap-3 items-center justify-center`}
              >
                {calendar.startDate && calendar.endDate ? (
                  <div
                    className={
                      showDateModal
                        ? "calendarButtonActive picked-date-container-blue"
                        : "picked-date-container"
                    }
                  >
                    <p className="date">
                      {formatDateLocaleCalendar(calendar.startDate)} -{" "}
                      {formatDateLocaleCalendar(calendar.endDate)}
                    </p>
                    {!showDateModal && (
                      <div className="date-exit-container">
                        <img
                          className="cursor-pointer exit-calendar-cross"
                          src="/icons/exit/cross.svg"
                          alt="Clear Date"
                          onClick={handleClearDate}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <img
                      src={
                        showDateModal
                          ? "/icons/calendar/calendarIconWhite.svg"
                          : "/icons/calendar/calendarIcon.svg"
                      }
                      alt=""
                    />
                    {t("calendar.timePeriod")}
                    <img
                      src={
                        showDateModal
                          ? "/icons/arrow/arrowUpWhite.svg"
                          : "/icons/arrow/arrowDownDarkBlue.svg"
                      }
                      alt=""
                    />
                  </>
                )}
              </button>
              {showDateModal && (
                <DateSelectRange
                  showCalendar={setShowDateModal}
                  start={calendar.startDate}
                  end={calendar.endDate}
                  handleApply={(start, end) =>
                    setCalendar({ startDate: start, endDate: end })
                  }
                />
              )}
            </div>
          </div>
        </div>
        <div className="chart-container">
          <Revenue
            xValues={revenue.map((x) =>
              formatDateStringBasedOnDateRange(
                calendar.startDate,
                calendar.endDate,
                x.middle,
                30
              )
            )}
            yValues={revenue.map((el) => ({
              ...el,
              from: formatDateStringBasedOnDateRange(
                calendar.startDate,
                calendar.endDate,
                el.from,
                30
              ),
              to: formatDateStringBasedOnDateRange(
                calendar.startDate,
                calendar.endDate,
                el.to,
                30
              ),
              middle: formatDateStringBasedOnDateRange(
                calendar.startDate,
                calendar.endDate,
                el.middle,
                30
              ),
            }))}
            currency={selectedCurrency}
          />
        </div>
        <div className="chart-container">
          <Utilization
            xValues={timeUtilization.map((x) =>
              formatDateStringBasedOnDateRange(
                calendar.startDate,
                calendar.endDate,
                x.middle,
                30
              )
            )}
            yTime={timeUtilization.map((el) => ({
              value: el.value,
              from: formatDateStringBasedOnDateRange(
                calendar.startDate,
                calendar.endDate,
                el.from,
                30
              ),
              to: formatDateStringBasedOnDateRange(
                calendar.startDate,
                calendar.endDate,
                el.to,
                30
              ),
              middle: formatDateStringBasedOnDateRange(
                calendar.startDate,
                calendar.endDate,
                el.middle,
                30
              ),
            }))}
            yCapacity={capacityUtilization.map((el) => ({
              value: el.value,
              from: formatDateStringBasedOnDateRange(
                calendar.startDate,
                calendar.endDate,
                el.from,
                30
              ),
              to: formatDateStringBasedOnDateRange(
                calendar.startDate,
                calendar.endDate,
                el.to,
                30
              ),
              middle: formatDateStringBasedOnDateRange(
                calendar.startDate,
                calendar.endDate,
                el.middle,
                30
              ),
            }))}
          />
        </div>
        <div className="grid grid-cols-5 gap-8 items-center w-[100%]">
          <div className="chart-container col-span-3">
            <Energy
              xValues={energyConsumption.map((x) =>
                formatDateStringBasedOnDateRange(
                  calendar.startDate,
                  calendar.endDate,
                  x.middle,
                  6
                )
              )}
              yValues={energyConsumption.map((el) => ({
                ...el,
                from: formatDateStringBasedOnDateRange(
                  calendar.startDate,
                  calendar.endDate,
                  el.from,
                  6
                ),
                to: formatDateStringBasedOnDateRange(
                  calendar.startDate,
                  calendar.endDate,
                  el.to,
                  6
                ),
                middle: formatDateStringBasedOnDateRange(
                  calendar.startDate,
                  calendar.endDate,
                  el.middle,
                  6
                ),
              }))}
            />
          </div>
          <div className="chart-container col-span-2">
            {chargersStatus && (
              <ChargerStatus
                values={[
                  chargersStatus.unavailable,
                  chargersStatus.available,
                  chargersStatus.outOfService,
                ]}
                openModal={(status: EVSEStatus) => openEVSEModal(status)}
              />
            )}
          </div>
        </div>
      </div>
      <PreloadMap />
      {EVSEModalOpened && (
        <EvseStatusPopup
          status={selectedEVSEStatus}
          data={EVSEData}
          handleExit={handleEVSEModalExit}
          marketIds={selectedFilters.markets.map((market) => market.id)}
        />
      )}
    </div>
  );
};

export default DashboardOverview;
