import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  handleCreateNewMarket,
  handleGetMarkets,
} from "../../stores/marketStore";
import "./Company.css";
import { MAPBOX_ACCESS_TOKEN } from "../../helpers/constants";
import {
  CommonStore,
  GetCompaniesForDropdown,
  GetCountriesForDropdown,
  GetCurrenciesForDropdown,
} from "../../stores/commonStore";
import { useStore } from "react-stores";
import { selectStyling } from "../../helpers/componentsHelper";
import { useToast } from "../../shared/toast/ToastContent";
import Popup from "../../shared/popup/Popup";
import Spinner from "../../shared/spinner/Spinner";
import { useTranslation } from "react-i18next";

interface NewMarket {
  name: string;
  companyId: number;
  currencyId: number;
  address: string;
  email: string;
  hotlinePhoneNumber: string;
  countryIso: string;
}

interface Props {
  createMode: boolean;
  companyId: number;
  companyName: string;
  setCreateMode: Function;
}

interface SelectOption {
  value: string;
  label: string;
}

const Create: React.FC<Props> = ({ createMode, setCreateMode }) => {
  const { t } = useTranslation();
  const [newMarket, setNewMarket] = useState<NewMarket>({
    name: "",
    currencyId: 0,
    companyId: 0,
    address: "",
    email: "",
    hotlinePhoneNumber: "",
    countryIso: "",
  });
  const [query, setQuery] = useState("");
  const [addressOptions, setAddressOptions] = useState<SelectOption[]>([]);
  const { companiesForDropdown, countriesForDropDown, currenciesForDropdown } =
    useStore(CommonStore);

  const [canConfirm, setCanConfirm] = useState<boolean>(false);
  const [creationSuccessPopup, setCreationSuccessPopup] =
    useState<boolean>(false);

  const { showToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);

  const countryCurrencyMap: { [key: string]: number } = {
    HRV: 1, // Croatia -> HRK
    BIH: 4, // Bosnia and Herzegovina -> BAM
    SRB: 3, // Serbia -> RSD
    MNE: 1, // Montenegro -> EUR
    ARE: 5, // United Arab Emirates -> AED
    HUN: 6, // Hungary -> HUF
    CHE: 7, // Switzerland -> CHF
  };

  useEffect(() => {
    if (
      newMarket.name &&
      newMarket.address &&
      newMarket.countryIso &&
      newMarket.companyId
    ) {
      setCanConfirm(true);
    } else {
      setCanConfirm(false);
    }
  }, [newMarket]);

  useEffect(() => {
    GetCountriesForDropdown();
    GetCompaniesForDropdown();
    GetCurrenciesForDropdown();
  }, []);

  const exitCreateMode = () => {
    setCreateMode(false);
    setNewMarket({
      name: "",
      currencyId: 0,
      companyId: 0,
      address: "",
      email: "",
      hotlinePhoneNumber: "",
      countryIso: "",
    });
  };

  const handleSelectChange = (selectedOption: SelectOption | null) => {
    setNewMarket({
      ...newMarket,
      address: selectedOption?.label || "",
    });
    setQuery(selectedOption?.label || "");
    setAddressOptions([]);
  };

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (query.length > 2) {
        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${MAPBOX_ACCESS_TOKEN}&autocomplete=true&proximity=18.29,43.29&bbox=12.29,41.29,21.89,47.09`
        );
        const data = await response.json();
        const options = data.features.slice(0, 3).map((feature: any) => ({
          value: feature.place_name,
          label: feature.place_name,
        }));
        if (options.some((option) => option.label === query)) {
          setAddressOptions([]);
        } else {
          setAddressOptions(options);
        }
      } else {
        setAddressOptions([]);
      }
    };

    fetchSuggestions();
  }, [query]);

  const handleMarketCreation = async () => {
    setLoading(true);
    const res = await handleCreateNewMarket(newMarket);
    if (res) {
      handleGetMarkets();
      setLoading(false);
      showToast(t("createMarket.toastMessages.success"), "success");
      exitCreateMode();
    } else {
      setLoading(false);
      showToast(t("createMarket.toastMessages.error"), "error");
    }
  };

  const handleCountryChange = (selectedOption: SelectOption | null) => {
    const selectedCountryIso = selectedOption?.value || "";
    const currencyId = countryCurrencyMap[selectedCountryIso] || 0;

    setNewMarket({
      ...newMarket,
      countryIso: selectedCountryIso,
      currencyId: currencyId,
    });
  };

  return (
    <>
      {createMode && (
        <>
          <div className="modal-background" onClick={exitCreateMode} />
          <div className="absolute-edit-company pt-2">
            <div className="flex flex-col items-center justify-start gap-2">
              <div className="flex justify-end items-center w-full p-5">
                <img
                  src="/icons/exit/cross-big.svg"
                  alt=""
                  onClick={exitCreateMode}
                  className="cursor-pointer"
                />
              </div>
              <div className="horizontal-gray-line w-full"></div>
              <div className="flex flex-col justify-start items-stretch w-full px-10 py-5 gap-6">
                <div className="flex justify-between items-center">
                  <p className="edit-market-text">{t("createMarket.title")}</p>
                  <button
                    className={`edit-market-confirm-btn ${
                      canConfirm ? "edit-market-confirm-btn-active" : ""
                    }`}
                    disabled={!canConfirm}
                    onClick={handleMarketCreation}
                  >
                    {t("createMarket.confirmButton")}
                  </button>
                </div>
                <div className="h-[2.5px] bg-[#B5BAC680] w-full mt-2"></div>
                <div className="flex flex-col gap-5 items-stretch justify-between p-2">
                  <div className="flex justify-stretch gap-5">
                    <div className="flex flex-col items-start flex-1 gap-2">
                      <label htmlFor="market" className="edit-market-header">
                        {t("createMarket.labels.marketName")}
                      </label>
                      <input
                        className="edit-market-input"
                        id="market"
                        placeholder={t("createMarket.placeholders.marketName")}
                        value={newMarket.name}
                        onChange={(e) =>
                          setNewMarket({ ...newMarket, name: e.target.value })
                        }
                      />
                    </div>
                    <div className="flex flex-col items-start flex-1 gap-2">
                      <label className="edit-market-header">
                        {t("createMarket.labels.companyName")}
                      </label>
                      <div className="w-full">
                        <Select
                          options={companiesForDropdown.map((company) => ({
                            value: company.id.toString(),
                            label: company.companyName,
                          }))}
                          placeholder={t(
                            "createMarket.placeholders.companyName"
                          )}
                          styles={selectStyling}
                          onChange={(selectedOption) =>
                            setNewMarket({
                              ...newMarket,
                              companyId: Number(selectedOption?.value) || 0,
                            })
                          }
                          isClearable={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-stretch gap-5">
                    <div className="flex flex-col items-start flex-1 gap-2">
                      <label className="edit-market-header">
                        {t("createMarket.labels.country")}
                      </label>
                      <div className="w-full">
                        <Select
                          options={countriesForDropDown.map((country) => ({
                            value: country.iSO,
                            label: country.internationalName,
                          }))}
                          placeholder={t("createMarket.placeholders.country")}
                          styles={selectStyling}
                          onChange={handleCountryChange}
                          isClearable={true}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col items-start flex-1 gap-2 relative">
                      <label className="edit-market-header">
                        {t("createMarket.labels.companyAddress")}
                      </label>
                      <input
                        className="edit-market-input"
                        type="text"
                        name="address"
                        placeholder={t("createMarket.placeholders.address")}
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                      />
                      {addressOptions.length > 0 && (
                        <ul className="w-full address-options-container">
                          {addressOptions.map((option) => (
                            <li
                              className="address-options"
                              key={option.value}
                              onClick={() => handleSelectChange(option)}
                            >
                              {option.label}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-stretch gap-5">
                    <div className="flex flex-col items-start flex-1 gap-2">
                      <label htmlFor="email" className="edit-market-header">
                        {t("createMarket.labels.email")}
                      </label>
                      <input
                        className="edit-market-input"
                        id="email"
                        placeholder={t("createMarket.placeholders.email")}
                        name="email"
                        value={newMarket.email}
                        onChange={(e) =>
                          setNewMarket({ ...newMarket, email: e.target.value })
                        }
                      />
                    </div>
                    <div className="flex flex-col items-start flex-1 gap-2">
                      <label
                        htmlFor="hotlinePhoneNumber"
                        className="edit-market-header"
                      >
                        {t("createMarket.labels.hotlinePhoneNumber")}
                      </label>
                      <input
                        className="edit-market-input"
                        id="hotlinePhoneNumber"
                        placeholder={t(
                          "createMarket.placeholders.hotlinePhoneNumber"
                        )}
                        name="hotlinePhoneNumber"
                        value={newMarket.hotlinePhoneNumber}
                        onChange={(e) =>
                          setNewMarket({
                            ...newMarket,
                            hotlinePhoneNumber: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {loading && <Spinner />}
    </>
  );
};

export default Create;
