import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import i18n from "../i18n";
import { AuthStore, checkIfRemembered } from "../stores/authStore";

const LANGUAGE_CACHE_KEY = "account-language";
const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;

const fetchAccountLanguage = async (): Promise<string | null> => {
  let jwtToken: string | null = AuthStore.state.jwt;
  if (!jwtToken) jwtToken = sessionStorage.getItem("jwt");
  if (!jwtToken) jwtToken = checkIfRemembered();
  if (!jwtToken) return null;

  try {
    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Users/GetAccountLanguage`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${jwtToken}` },
      }
    );

    if (!response.ok) throw new Error("Failed to fetch language");

    const userLanguage = await response.json();
    i18n.changeLanguage(userLanguage); // Apply the language change
    return userLanguage;
  } catch (error) {
    console.error("API Language Detection failed:", error);
    return null;
  }
};

const defaultLanguageQueryOptions: UseQueryOptions<string | null> = {
  queryKey: [LANGUAGE_CACHE_KEY],
  queryFn: fetchAccountLanguage,
  staleTime: 10 * 60 * 1000,
  retry: 1,
  refetchOnWindowFocus: false,
};

type CustomLanguageQueryOptions = Omit<
  UseQueryOptions<string | null>,
  "queryKey" | "queryFn"
>;

export function useGetAccountLanguageQuery(
  options?: CustomLanguageQueryOptions
) {
  return useQuery({
    ...defaultLanguageQueryOptions,
    ...options,
  });
}
