import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useStore } from "react-stores";
import { selectStyling } from "../../../helpers/componentsHelper";
import { useToast } from "../../../shared/toast/ToastContent";
import Popup from "../../../shared/popup/Popup";
import "./Popups.css";
import { useCompanyMarketsDropdownQuery } from "../../../hooks/roaming/useCompanyMarketsDropdown";
import { RegisterCompanyMarket } from "../../../stores/roamingStore";
import { useTranslation } from "react-i18next";

interface RegisterPopupProps {
  refetch: () => void;
  partnerId: number;
  exit: () => void;
}

const RegisterPopup = ({ refetch, partnerId, exit }: RegisterPopupProps) => {
  const { t } = useTranslation();
  const {
    data: companyMarkets,
    isLoading,
    error,
    refetch: refetchMarkets,
  } = useCompanyMarketsDropdownQuery(false);

  const { showToast } = useToast();
  const [canConfirm, setCanConfirm] = useState<boolean>(false);

  const [formData, setFormData] = useState({
    name: "",
    acronym: "",
    country: "",
    street: "",
    zip: "",
    city: "",
    phoneNumber: "",
  });
  const [formErrors, setFormErrors] = useState({
    acronym: "",
    phoneNumber: "",
  });

  useEffect(() => {
    const { acronym, phoneNumber } = formData;

    let acronymError = "";
    if (acronym.length !== 3) {
      acronymError = t("roaming.registerPopup.acronymError_1");
    } else if (!/^[A-Z]{3}$/.test(acronym)) {
      acronymError = t("roaming.registerPopup.acronymError_2");
    }

    let phoneError = "";
    const phoneRegex = /^\+?[1-9][0-9\s-()]*$/;
    if (phoneNumber && phoneNumber.length < 6) {
      phoneError = t("roaming.registerPopup.phoneError_1");
    } else if (!phoneRegex.test(phoneNumber)) {
      phoneError = t("roaming.registerPopup.phoneError_2");
    }

    setFormErrors({ acronym: acronymError, phoneNumber: phoneError });

    setCanConfirm(
      acronym.length === 3 &&
        /^[A-Z]{3}$/.test(acronym) &&
        phoneNumber.length >= 6 &&
        phoneNumber.length <= 16 &&
        !acronymError &&
        !phoneError
    );
  }, [formData]);

  useEffect(() => {
    const selectedMarket = companyMarkets?.find(
      (market) => market.id === partnerId
    );

    if (selectedMarket) {
      const [street, zipAndCity] = selectedMarket.address.split(",");
      const zipMatch = zipAndCity?.match(/\d{5}/);
      const zip = zipMatch ? zipMatch[0] : "";
      const city = zipAndCity?.replace(zip, "").trim() || "";

      setFormData({
        name: selectedMarket.companyMarketName,
        acronym: "",
        country: selectedMarket.country,
        street: street.trim(),
        zip,
        city,
        phoneNumber: "",
      });
    }
  }, [partnerId, companyMarkets]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value && !value.startsWith("+")) {
      setFormData((prevData) => ({
        ...prevData,
        phoneNumber: "+" + value.replace(/[^0-9]/g, ""),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        phoneNumber: value.replace(/[^0-9+]/g, ""),
      }));
    }
  };

  const handleConfirm = () => {
    RegisterCompanyMarket(
      partnerId,
      formData.acronym,
      formData.phoneNumber
    ).then((res) => {
      if (res) {
        showToast("Request successfully sent", "success");
        refetch();
        exit();
      } else {
        showToast("Error while registering company market", "error");
        exit();
      }
    });
  };

  return (
    <>
      <div className="modal-background" onClick={exit} />
      <div className="absolute-register-roaming pt-2">
        <div className="flex flex-col items-center justify-start gap-2">
          <div className="flex justify-end items-center w-full p-5">
            <img
              src="/icons/exit/cross-big.svg"
              alt=""
              onClick={exit}
              className="cursor-pointer"
            />
          </div>
          <div className="horizontal-gray-line w-full"></div>
          <div className="flex flex-col justify-start items-stretch w-full px-10 py-5 gap-6">
            <div className="flex flex-col gap-6 items-stretch justify-between p-2">
              <div className="flex justify-stretch gap-5">
                <div className="flex flex-col items-start flex-1 gap-2">
                  <label htmlFor="name" className="roaming-inputs-header">
                    {t("roaming.registerPopup.subPartnerLabel")}
                  </label>
                  <input
                    className="edit-market-input-readonly"
                    id="name"
                    placeholder={t(
                      "roaming.registerPopup.subPartnerPlaceholder"
                    )}
                    name="name"
                    value={formData.name}
                    readOnly
                  />
                </div>
                <div className="flex flex-col items-start flex-1 gap-2">
                  <label htmlFor="acronym" className="roaming-inputs-header">
                    {t("roaming.registerPopup.preferredAcronymLabel")}
                  </label>
                  <input
                    type="text"
                    className="edit-market-input"
                    id="acronym"
                    placeholder="XXX"
                    name="acronym"
                    maxLength={3}
                    minLength={3}
                    value={formData.acronym}
                    onChange={handleInputChange}
                  />

                  <div className="text-[#f77986] text-[12px] font-semibold h-2">
                    {formErrors.acronym && formErrors.acronym}
                  </div>
                </div>
              </div>
              <div className="flex justify-stretch gap-5">
                <div className="flex flex-col items-start flex-1 gap-2">
                  <label htmlFor="country" className="roaming-inputs-header">
                    {t("roaming.registerPopup.country")}
                  </label>
                  <input
                    className="edit-market-input-readonly"
                    id="country"
                    placeholder={t("roaming.registerPopup.country")}
                    name="country"
                    value={formData.country}
                    readOnly
                  />
                </div>
                <div className="flex flex-col items-start flex-1 gap-2">
                  <label htmlFor="street" className="roaming-inputs-header">
                    {t("roaming.registerPopup.street")}
                  </label>
                  <input
                    className="edit-market-input-readonly"
                    id="street"
                    placeholder={t("roaming.registerPopup.streetPlaceholder")}
                    name="street"
                    value={formData.street}
                    readOnly
                  />
                </div>
              </div>
              <div className="flex justify-stretch gap-5 mt-2">
                <div className="flex flex-col items-start flex-1 gap-2">
                  <label htmlFor="zip" className="roaming-inputs-header">
                    {t("roaming.registerPopup.zip")}
                  </label>
                  <input
                    className="edit-market-input-readonly"
                    id="zip"
                    placeholder={t("roaming.registerPopup.zip")}
                    name="zip"
                    value={formData.zip}
                    readOnly
                  />
                </div>
                <div className="flex flex-col items-start flex-1 gap-2">
                  <label htmlFor="city" className="roaming-inputs-header">
                    {t("roaming.registerPopup.city")}
                  </label>
                  <input
                    className="edit-market-input-readonly"
                    id="city"
                    placeholder={t("roaming.registerPopup.city")}
                    name="city"
                    value={formData.city}
                    readOnly
                  />
                </div>
              </div>
              <div className="flex justify-stretch gap-5">
                <div className="flex flex-col items-start flex-1 gap-2">
                  <label
                    htmlFor="phoneNumber"
                    className="roaming-inputs-header"
                  >
                    {t("roaming.registerPopup.hotline")}
                  </label>
                  <input
                    className="edit-market-input"
                    id="phoneNumber"
                    placeholder="+XXX XXX XXX XXX"
                    name="phoneNumber"
                    pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$"
                    value={formData.phoneNumber}
                    onChange={handlePhoneNumberChange}
                  />
                  <div className="text-[#f77986] text-[12px] font-semibold h-2">
                    {formErrors.phoneNumber && formErrors.phoneNumber}
                  </div>
                  <div
                    id="phone-number-message"
                    className="text-[12px] font-semibold text-[#ED1F0E] hidden"
                  >
                    {t("roaming.registerPopup.changes")}
                  </div>
                </div>
                <div className="flex-1" />
              </div>
              <div className="flex justify-center mt-7">
                <button
                  className={`partners-roaming-confirm-btn ${
                    canConfirm && "partners-roaming-confirm-btn-active"
                  }`}
                  disabled={!canConfirm}
                  onClick={handleConfirm}
                >
                  {t("roaming.registerPopup.confirm")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterPopup;
