import "./InfoPopup.css";
import { useTranslation } from "react-i18next";

const InfoPopup = () => {
  const { t } = useTranslation();
  return (
    <div className="info-container ">
      <div className="section">
        <p className="section-title">{t("infoPopup.reset.title")}</p>
        <div className="text-container">
          <p className="text">{t("infoPopup.reset.description")}</p>
        </div>
      </div>
      <div className="section">
        <p className="section-title">{t("infoPopup.emergencyReset.title")}</p>
        <div className="text-container">
          <p className="text">{t("infoPopup.emergencyReset.description")}</p>
        </div>
      </div>
      <div className="section">
        <p className="section-title">
          {t("infoPopup.changeAvailability.title")}
        </p>
        <div className="text-container">
          <p className="text">
            {t("infoPopup.changeAvailability.description")}
          </p>
        </div>
      </div>
      <div className="section">
        <p className="section-title">{t("infoPopup.unlock.title")}</p>
        <div className="text-container">
          <p className="text">{t("infoPopup.unlock.description")}</p>
        </div>
      </div>
    </div>
  );
};

export default InfoPopup;
