import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  autoLogoutIfExpired,
  hasValidJwt,
  meHandler,
} from "../stores/authStore";
import Spinner from "../shared/spinner/Spinner";
import { useGetAccountLanguageQuery } from "../hooks/useGetAccountLanguage";
import { useGetUserInfoQuery } from "../hooks/useGetUserInfo";

const CHECK_INTERVAL = 10 * 1000;

const DashboardLayout = () => {
  const navigate = useNavigate();
  const [isAuthed, setIsAuthed] = useState(false);
  const { pathname } = useLocation();
  useGetAccountLanguageQuery();
  useGetUserInfoQuery();

  useEffect(() => {
    const r = hasValidJwt();
    if (!r) {
      console.log("Not authed");
      setIsAuthed(false);
      navigate("/auth/login");
    } else {
      setIsAuthed(true);
      if (pathname === "/") navigate("/dashboard/overview");
    }

    if (!autoLogoutIfExpired()) {
      setIsAuthed(false);
      return;
    }

    const interval = setInterval(() => {
      if (!autoLogoutIfExpired()) {
        setIsAuthed(false);
      }
    }, CHECK_INTERVAL);

    return () => clearInterval(interval);
  }, [navigate, pathname]);

  return (
    <div className="flex min-h-screen">
      {isAuthed ? (
        <>
          <Sidebar />
          <div className="flex-auto flex flex-col ml-[250px]">
            <Header />
            <Outlet />
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default DashboardLayout;
