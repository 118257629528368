import React, { useState, useEffect } from "react";
import "./AddNewCharger.css";
import Select, { components } from "react-select";
import { handleGetMarketsForDropdown } from "../../../../stores/marketStore";
import { IMarketDropdown } from "../../../../interfaces/IMarket";
import { getLocationsDropdown } from "../../../../stores/locationStore";
import { ILocationsForDropdown } from "../../../../interfaces/ILocation";
import { selectStyling } from "../../../../helpers/componentsHelper";
import { useTranslation } from "react-i18next";

interface Props {
  handleExit: Function;
  handleAddingCharger: Function;
}

interface NewCharger {
  chargerId: string;
  locationId: undefined | number;
  companyMarketId: number | undefined;
  address: string;
  longitude: undefined | number;
  latitude: undefined | number;
}

const AddNewCharger: React.FC<Props> = ({ handleExit, handleAddingCharger }) => {
  const { t } = useTranslation();
  const [newCharger, setNewCharger] = useState<NewCharger>({
    chargerId: "",
    locationId: undefined,
    companyMarketId: undefined,
    address: "",
    longitude: undefined,
    latitude: undefined,
  });
  const [canConfirm, setCanConfirm] = useState<boolean>(false);
  const [marketsForDropdown, setMarketsForDropdown] = useState<
    IMarketDropdown[]
  >([]);
  const [allLocations, setAllLocations] = useState<ILocationsForDropdown[]>(
    [] as ILocationsForDropdown[]
  );
  const [filteredLocations, setFilteredLocations] = useState<
    ILocationsForDropdown[]
  >([]);

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div
          className="grid"
          style={{ gridTemplateColumns: "1fr 1.5fr 1.5fr" }}
        >
          <div>{props.data.label[0]}</div>
          <div>{props.data.label[1]}</div>
          <div>{props.data.label[2]}</div>
        </div>
      </components.Option>
    );
  };

  useEffect(() => {
    const getMarketsAndLocations = async () => {
      setAllLocations(await getLocationsDropdown());
      setMarketsForDropdown(await handleGetMarketsForDropdown());
    };
    getMarketsAndLocations();
  }, []);

  useEffect(() => {
    const checkConfirmation = () => {
      const values = Object.values(newCharger);
      const isComplete = values.every((value) => !!value);
      setCanConfirm(isComplete);
    };

    checkConfirmation();
  }, [newCharger]);

  const formatLocationsAsOptions = () => {
    let returnArr = [
      {
        value: -1,
        name: "",
        label: ["ID", "Name", "Address"],
        latitude: 0,
        longitude: 0,
        city: "",
        disabled: true,
      },
    ];
    filteredLocations.forEach((location) =>
      returnArr.push({
        value: location.id,
        name: location.name,
        label: [location.id.toString(), location.name, location.street],
        latitude: location.latitude,
        longitude: location.longitude,
        city: location.city,
        disabled: false,
      })
    );
    return returnArr;
  };

  const handleClickingConnect = () => {
    handleAddingCharger({
      id: newCharger.chargerId,
      locationId: newCharger.locationId,
    });
  };

  return (
    <>
      <div className="modal-background" onClick={() => handleExit()} />
      <div className="absolute-add-charger pt-2">
        <div className="flex flex-col items-center justify-start gap-2">
          <div className="flex justify-end items-center w-full p-5">
            <img
              src="/icons/exit/cross-big.svg"
              alt=""
              onClick={() => handleExit()}
              className="cursor-pointer"
            />
          </div>
          <div className="horizontal-gray-line w-full"></div>
          <div className="flex flex-col justify-start items-stretch w-full p-10 gap-7">
            <div className="flex justify-between items-center">
              <p className="addNewText">
                {t("networkOnboarding.createChargerModal.title")}
              </p>
              <button
                className={`connect-btn ${canConfirm ? "connect-btn-active" : ""
                  }`}
                disabled={!canConfirm}
                onClick={handleClickingConnect}
              >
                {t("networkOnboarding.createChargerModal.connect")}
              </button>
            </div>
            <div className="h-[2.5px] bg-[#B5BAC680] w-full mt-5"></div>
            <div className="flex flex-col justify-start items-between gap-5">
              <div className="flex justify-stretch gap-5">
                <div className="flex flex-col items-start flex-1 gap-2">
                  <p className="input-title">
                    {t("networkOnboarding.createChargerModal.chargerId")}
                  </p>
                  <input
                    type="text"
                    placeholder="Charger ID"
                    className="create-charger-input"
                    onChange={(e) =>
                      setNewCharger({
                        ...newCharger,
                        chargerId: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="flex flex-col items-start flex-1 gap-2">
                  <p className="input-title">
                    {t("networkOnboarding.createChargerModal.companyMarket")}
                  </p>
                  <div className="w-full">
                    <Select
                      options={marketsForDropdown.map((market) => {
                        return { value: market.id, label: market.name };
                      })}
                      placeholder="Company Market"
                      styles={selectStyling}
                      onChange={(selectedOption) => {
                        setFilteredLocations(
                          allLocations.filter(
                            (location) =>
                              location.companyMarketId === selectedOption?.value
                          )
                        );
                        let currLocation =
                          allLocations[
                          allLocations.findIndex(
                            (location) =>
                              location.id === newCharger.locationId
                          )
                          ];
                        if (
                          currLocation &&
                          currLocation.companyMarketId === selectedOption?.value
                        ) {
                          setNewCharger({
                            ...newCharger,
                            companyMarketId: selectedOption?.value || undefined,
                          });
                        } else {
                          setNewCharger({
                            ...newCharger,
                            locationId: undefined,
                            companyMarketId: selectedOption?.value || undefined,
                            address: "",
                            longitude: undefined,
                            latitude: undefined,
                          });
                        }
                      }}
                      isClearable={true}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-stretch gap-5">
                <div className="flex flex-col items-start flex-1 gap-2">
                  <p className="input-title">
                    {t("networkOnboarding.createChargerModal.locationName")}
                  </p>
                  <div className="w-full">
                    <Select
                      options={formatLocationsAsOptions()}
                      components={{ Option }}
                      placeholder="Location Name"
                      formatOptionLabel={(option) => option.name}
                      styles={selectStyling}
                      onChange={(selectedOption) =>
                        setNewCharger({
                          ...newCharger,
                          locationId: selectedOption?.value || undefined,
                          address:
                            selectedOption?.label[2] && selectedOption?.city
                              ? `${selectedOption?.label[2]}, ${selectedOption?.city}`
                              : "",
                          latitude: selectedOption?.latitude || undefined,
                          longitude: selectedOption?.longitude || undefined,
                        })
                      }
                      isOptionDisabled={(option) => option.disabled}
                      isClearable={true}
                      filterOption={(candidate, input) => {
                        return (
                          candidate.label.toString() ===
                          "Location ID,Location Name,Location Address" ||
                          candidate.label
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col items-start flex-1 gap-2">
                  <p className="input-title">
                    {t("networkOnboarding.createChargerModal.address")}
                  </p>
                  <input
                    type="text"
                    placeholder="Address"
                    className="create-charger-input-readonly"
                    value={newCharger.address}
                    readOnly
                  />
                </div>
              </div>
              <div className="flex justify-stretch gap-5">
                <div className="flex flex-col items-start flex-1 gap-2">
                  <p className="input-title">
                    {t(
                      "networkOnboarding.createChargerModal.longitudeLatitude"
                    )}
                  </p>
                  <input
                    type="text"
                    placeholder="Longitude & Latitude"
                    value={
                      newCharger.longitude && newCharger.latitude
                        ? `${newCharger.longitude}, ${newCharger.latitude}`
                        : ""
                    }
                    className="create-charger-input-readonly"
                    readOnly
                  />
                </div>
                <div className="flex flex-col items-start flex-1 gap-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewCharger;
