import React, { useEffect, useRef, useState } from "react";
import "./NotificationButton.css";
import { useOutsideAlerter } from "../../../helpers/componentsHelper";
import { Link, useNavigate } from "react-router-dom";
import { useStore } from "react-stores";
import {
  closeConnection,
  startConnection,
} from "../../../helpers/signalRHelper";
import { useCompanyInfoQuery } from "../../../hooks/useGetCompanyInfoHook";
import {
  handleGetNotifications,
  NotificationsStore,
} from "../../../stores/notificationsStore";
import { handleSetSelectedAlarm } from "../../../stores/alarmsStore";
import { handleSetSelectedTicket } from "../../../stores/ticketStore";
import { useTranslation } from "react-i18next";

const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;

const NotificationButton: React.FC = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [selectedWindow, setSelectedWindow] = useState<"Alarms" | "Tickets">(
    "Alarms"
  );
  const { data: companyInfoData } = useCompanyInfoQuery();
  const [connection, setConnection] = useState<signalR.HubConnection | null>(
    null
  );
  const [notificationCount, setNotificationCount] = useState(0);
  const { alarms, alarmsCount, tickets, ticketsCount } =
    useStore(NotificationsStore);
  const navigate = useNavigate();

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setExpanded(false));

  useEffect(() => {
    handleGetNotifications();
  }, []);

  useEffect(() => {
    const handleConnection = async () => await handleNotificationCount();
    companyInfoData && handleConnection();

    return () => {
      connection && closeConnection(connection);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyInfoData]);

  const handleNotificationCount = async () => {
    try {
      const newConnection = await startConnection(
        `${PUBLIC_BASE_URL}hubs/notifications`
      );
      await newConnection
        .invoke("Subscribe", companyInfoData?.id)
        .then(() => console.log("Subscribed on notification"))
        .catch((err) => console.error("Subscription error:", err));
      newConnection.on("Notification", (notificationCount) => {
        console.log("notifications number: ", notificationCount);
        setNotificationCount(notificationCount);
        handleGetNotifications();
      });

      setConnection(newConnection);
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const handleViewAllBtn = (pagename: string) => {
    setExpanded(false);
    navigate(pagename);
  };

  const handleAlarmClick = (id: number) => {
    setExpanded(false);
    handleSetSelectedAlarm(id);
    navigate("dashboard/operations/alarms");
  };

  const handleTicketClick = (id: number) => {
    setExpanded(false);
    handleSetSelectedTicket(id);
    navigate("dashboard/operations/tickets");
  };

  return (
    <div
      className="flex flex-col justify-start items-end gap-3 relative"
      ref={wrapperRef}
    >
      <div
        className="flex justify-center items-center relative header-icons-container"
        onClick={() => setExpanded((prev) => !prev)}
      >
        <img src="/icons/nav/bell.svg" alt="" />
        <div className="notification-bubble">
          {notificationCount
            ? (notificationCount > 99 ? "99+" : notificationCount)
            : (alarmsCount + ticketsCount > 99 ? "99+" : (alarmsCount + ticketsCount))}
        </div>
      </div>
      {expanded && (
        <div className="notification-container">
          <div className="flex flex-row items-center pt-2 pl-4 gap-2">
            <div
              className={`flex flex-row items-center gap-1 p-2 ${selectedWindow === "Alarms"
                ? "notification-selected-slider"
                : "notification-slider"
                }`}
              onClick={() => setSelectedWindow("Alarms")}
            >
              {t("notificationButton.tabs.alarms")}
              <div
                className={
                  selectedWindow === "Alarms" ? "bubble-selected" : "bubble"
                }
              >
                {alarmsCount}
              </div>
            </div>
            <div
              className={`flex flex-row items-center gap-1 p-2 ${selectedWindow === "Tickets"
                ? "notification-selected-slider"
                : "notification-slider"
                }`}
              onClick={() => setSelectedWindow("Tickets")}
            >
              {t("notificationButton.tabs.tickets")}
              <div
                className={
                  selectedWindow === "Tickets" ? "bubble-selected" : "bubble"
                }
              >
                {ticketsCount}
              </div>
            </div>
          </div>
          {selectedWindow === "Tickets" ? (
            <div className="flex flex-col items-stretch relative w-full">
              {tickets.length > 0 ? (
                tickets.map((ticket, idx) => (
                  <div
                    key={idx}
                    className={`flex items-stretch w-full p-2.5 relative notification ${!ticket.responded && "notification-unread"
                      } ${idx === tickets.length - 1 && "pb-[43px]"}`}
                    onClick={() => handleTicketClick(ticket.id)}
                  >
                    <div className="flex flex-col items-start pl-4 gap-1 text-[#111F47]">
                      <p className="notification-title">{ticket.title}</p>
                      <p className="notification-desc">{ticket.status}</p>
                    </div>
                    <p className="absolute top-3 right-3 notification-time">
                      {ticket.raisedAtRelative}
                    </p>
                    {!ticket.responded && (
                      <img
                        src="/icons/threeDots/blue-dot-smaller.svg"
                        alt=""
                        className="absolute top-4 left-3 "
                      />
                    )}
                  </div>
                ))
              ) : (
                <div className="w-full notification-desc text-left p-3 notification">
                  {t("notificationButton.messages.noTickets")}
                </div>
              )}
              {tickets.length > 0 && (
                <div className="view-all-container">
                  <p
                    className="cursor-pointer"
                    onClick={() =>
                      handleViewAllBtn("/dashboard/operations/tickets")
                    }
                  >
                    {t("notificationButton.viewAll")}
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col items-stretch w-full">
              {alarms.length > 0 ? (
                alarms.map((alarm, idx) => (
                  <div
                    key={idx}
                    className={`flex items-stretch w-full p-2.5 relative notification ${!alarm.hasBeenRead && "notification-unread"
                      } ${idx === alarms.length - 1 && "pb-[43px]"}`}
                    onClick={() => handleAlarmClick(alarm.id)}
                  >
                    <div className="flex flex-col items-start pl-4 gap-1 text-[#111F47]">
                      <p className="notification-title">{alarm.title}</p>
                      <p className="notification-desc">
                        {alarm.shortDescription}
                      </p>
                    </div>
                    <p className="absolute top-3 right-3 notification-time">
                      {alarm.createdAtRelative}
                    </p>
                    {!alarm.hasBeenRead && (
                      <img
                        src="/icons/threeDots/blue-dot-smaller.svg"
                        alt=""
                        className="absolute top-4 left-3 "
                      />
                    )}
                  </div>
                ))
              ) : (
                <div className="w-full notification-desc text-left p-3 notification">
                  {t("notificationButton.messages.noAlarms")}
                </div>
              )}
              {alarms.length > 0 && (
                <div className="view-all-container">
                  <p
                    className="cursor-pointer"
                    onClick={() =>
                      handleViewAllBtn("/dashboard/operations/alarms")
                    }
                  >
                    {t("notificationButton.viewAll")}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NotificationButton;
