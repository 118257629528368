import React, { useRef, useState } from "react";
import "./AlarmTypeDropdown.css";
import { useOutsideAlerter } from "../../../../helpers/componentsHelper";
import { AlarmType } from "../../../../interfaces/IAlarms";
import { useTranslation } from "react-i18next";

interface AlarmTypeDropdownProps {
  handleSelection: Function;
}

const AlarmTypeDropdown: React.FC<AlarmTypeDropdownProps> = ({
  handleSelection,
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [selectedType, setSelectedType] = useState<AlarmType[]>([
    AlarmType.Warning,
    AlarmType.Error,
    AlarmType.Notification,
  ]);
  const [availableTypes, setAvailableTypes] = useState<AlarmType[]>([
    AlarmType.Warning,
    AlarmType.Error,
    AlarmType.Notification,
  ]);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setExpanded(false));

  const handleAlarmTypeChange = (alarmType: AlarmType) => {
    if (selectedType.some((m) => m === alarmType)) {
      if (selectedType.length > 1) {
        let newArray = [...selectedType];
        let index = newArray.findIndex((m) => m === alarmType);
        newArray.splice(index, 1);
        setSelectedType(newArray);
        handleSelection(newArray);
      }
    } else {
      let newArray = [...selectedType];
      newArray = newArray.concat(alarmType);
      setSelectedType(newArray);
      handleSelection(newArray);
    }
  };

  return (
    <div
      className="flex flex-col justify-start items-end gap-3 relative"
      ref={wrapperRef}
    >
      <button
        className={`alarm-type-selection-btn ${
          expanded ? "alarm-type-selection-btn-active" : ""
        }`}
        onClick={() => {
          setExpanded((prev) => !prev);
        }}
      >
        <p>{t("alarms.alarmType.title")}</p>
        <img
          src={
            expanded
              ? "/icons/arrow/arrowUpWhite.svg"
              : "/icons/arrow/arrowDownDarkBlue.svg"
          }
          alt=""
        />
      </button>
      {expanded && (
        <div className="alarm-type-dropdown-container">
          {availableTypes.map((type) => (
            <div
              key={type}
              className={`alarm-type-dropdown-hoverable ${
                selectedType.some((t) => t === type) &&
                " alarm-type-dropdown-selected"
              }`}
              onClick={() => handleAlarmTypeChange(type)}
            >
              {selectedType.some((t) => t === type) ? (
                <img
                  src="/icons/correct-incorrect-icons/correct-icon-squared.svg"
                  alt=""
                  className="correct-icon"
                />
              ) : (
                <div className="checker-border" />
              )}
              <p className="text-left">
                {t(`alarms.alarmType.${type.toLowerCase()}`)}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AlarmTypeDropdown;
