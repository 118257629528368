import React from "react";
import { logout } from "../stores/authStore";
import "./Sidebar.css";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const [selected, setSelected] = React.useState("");

  const handleSelected = (newSelected) => {
    setSelected(newSelected === selected ? "" : newSelected);
  };

  return (
    <div className="sidebar-container">
      <div className="flex flex-col gap-8 items-left">
        {/* Logo and Separator */}
        <div className="flex flex-col items-center justify-between gap-8">
          <img src="/icons/nav/powerupLogo.svg" alt="" />
          <hr className="line" />
        </div>

        {/* Dashboard Link */}
        <Link
          to="/dashboard/overview"
          className="flex flex-row gap-5 relative items-left pl-[1.2rem]"
          onClick={() => handleSelected("")}
        >
          <img src="/icons/nav/dashboardIcon.svg" alt="" />
          <span
            className={
              pathname === "/dashboard/overview"
                ? "active sidebar-main-text whitespace-nowrap"
                : "sidebar-main-text whitespace-nowrap"
            }
          >
            {t("sidebar.dashboard")}
          </span>
        </Link>

        {/* Sessions */}
        <div className="flex flex-col items-left gap-2">
          <div
            className="flex flex-row gap-5 relative items-left pl-[1.2rem] cursor-pointer"
            onClick={() => handleSelected("Sessions")}
          >
            <img src="/icons/nav/sessionsIcon.svg" alt="" />
            <span
              className={`${
                pathname.startsWith("/dashboard/sessions/") ? "active" : ""
              } sidebar-main-text p`}
            >
              {t("sidebar.sessions.title")}
            </span>
            <img
              src={
                selected === "Sessions"
                  ? "/icons/arrow/arrowUp.svg"
                  : "/icons/arrow/arrowDown.svg"
              }
              alt=""
            />
          </div>
          {selected === "Sessions" && (
            <>
              <Link
                to="/dashboard/sessions/active"
                className={`${
                  pathname === "/dashboard/sessions/active"
                    ? "active-subgroup"
                    : ""
                } flex flex-row gap-2 relative items-center ml-[1.5rem] mr-[2rem] p-[0.4rem] justify-left`}
              >
                <img
                  src={
                    pathname === "/dashboard/sessions/active"
                      ? "/icons/nav/activeDot.svg"
                      : "/icons/nav/inactiveDot.svg"
                  }
                  alt=""
                  className="pl-[2rem]"
                />
                <div
                  className={`${
                    pathname === "/dashboard/sessions/active"
                      ? "active-subgroup-text"
                      : ""
                  } sidebar-subgroup`}
                >
                  {t("sidebar.sessions.active")}
                </div>
              </Link>
              <Link
                to="/dashboard/sessions/history"
                className={`${
                  pathname === "/dashboard/sessions/history"
                    ? "active-subgroup"
                    : ""
                } flex flex-row gap-2 relative items-center ml-[1.5rem] mr-[2rem] p-[0.4rem] justify-left`}
              >
                <img
                  src={
                    pathname === "/dashboard/sessions/history"
                      ? "/icons/nav/activeDot.svg"
                      : "/icons/nav/inactiveDot.svg"
                  }
                  alt=""
                  className="pl-[2rem]"
                />
                <div
                  className={`${
                    pathname === "/dashboard/sessions/history"
                      ? "active-subgroup-text"
                      : ""
                  } sidebar-subgroup`}
                >
                  {t("sidebar.sessions.history")}
                </div>
              </Link>
            </>
          )}
        </div>

        {/* Network */}
        <div className="flex flex-col items-left gap-2">
          <div
            className="flex flex-row gap-5 relative items-left pl-[1.2rem] cursor-pointer"
            onClick={() => handleSelected("Network")}
          >
            <img src="/icons/nav/networkIcon.svg" alt="" />
            <span
              className={`${
                pathname.startsWith("/dashboard/network/") ? "active" : ""
              } sidebar-main-text p`}
            >
              {t("sidebar.network.title")}
            </span>
            <img
              src={
                selected === "Network"
                  ? "/icons/arrow/arrowUp.svg"
                  : "/icons/arrow/arrowDown.svg"
              }
              alt=""
            />
          </div>
          {selected === "Network" && (
            <>
              <Link
                to="/dashboard/network/overview"
                className={`${
                  pathname === "/dashboard/network/overview"
                    ? "active-subgroup"
                    : ""
                } flex flex-row gap-2 relative items-center ml-[1.5rem] mr-[2rem] p-[0.4rem] justify-left`}
              >
                <img
                  src={
                    pathname === "/dashboard/network/overview"
                      ? "/icons/nav/activeDot.svg"
                      : "/icons/nav/inactiveDot.svg"
                  }
                  alt=""
                  className="pl-[2rem]"
                />
                <div
                  className={`${
                    pathname === "/dashboard/network/overview"
                      ? "active-subgroup-text"
                      : ""
                  } sidebar-subgroup`}
                >
                  {t("sidebar.network.overview")}
                </div>
              </Link>
              <Link
                to="/dashboard/network/onboarding"
                className={`${
                  pathname.startsWith("/dashboard/network/onboarding")
                    ? "active-subgroup"
                    : ""
                } flex flex-row gap-2 relative items-center ml-[1.5rem] mr-[2rem] p-[0.4rem] justify-left`}
              >
                <img
                  src={
                    pathname.startsWith("/dashboard/network/onboarding")
                      ? "/icons/nav/activeDot.svg"
                      : "/icons/nav/inactiveDot.svg"
                  }
                  alt=""
                  className="pl-[2rem]"
                />
                <div
                  className={`${
                    pathname.startsWith("/dashboard/network/onboarding")
                      ? "active-subgroup-text"
                      : ""
                  } sidebar-subgroup`}
                >
                  {t("sidebar.network.onboarding")}
                </div>
              </Link>
            </>
          )}
        </div>

        {/* Commercial */}
        <div className="flex flex-col items-left gap-2">
          <div
            className="flex flex-row gap-5 relative items-left pl-[1.2rem] cursor-pointer"
            onClick={() => handleSelected("Commercial")}
          >
            <img src="/icons/nav/tarrifsIcon.svg" alt="" />
            <span
              className={`${
                pathname.startsWith("/dashboard/commercial") ? "active" : ""
              } sidebar-main-text`}
            >
              {t("sidebar.commercial.title")}
            </span>
            <img
              src={
                selected === "Commercial"
                  ? "/icons/arrow/arrowUp.svg"
                  : "/icons/arrow/arrowDown.svg"
              }
              alt=""
            />
          </div>
          {selected === "Commercial" && (
            <>
              <Link
                to="/dashboard/commercial/pricing"
                className={`${
                  pathname.startsWith("/dashboard/commercial/pricing")
                    ? "active-subgroup"
                    : ""
                } flex flex-row gap-2 relative items-center ml-[1.5rem] mr-[2rem] p-[0.4rem] justify-left`}
              >
                <img
                  src={
                    pathname.startsWith("/dashboard/commercial/pricing")
                      ? "/icons/nav/activeDot.svg"
                      : "/icons/nav/inactiveDot.svg"
                  }
                  alt=""
                  className="pl-[2rem]"
                />
                <div
                  className={`${
                    pathname.startsWith("/dashboard/commercial/pricing")
                      ? "active-subgroup-text"
                      : ""
                  } sidebar-subgroup`}
                >
                  {t("sidebar.commercial.pricingGroup")}
                </div>
              </Link>
              <Link
                to="/dashboard/commercial/history"
                className={`${
                  pathname === "/dashboard/commercial/history"
                    ? "active-subgroup"
                    : ""
                } flex flex-row gap-2 relative items-center ml-[1.5rem] mr-[2rem] p-[0.4rem] justify-left`}
              >
                <img
                  src={
                    pathname === "/dashboard/commercial/history"
                      ? "/icons/nav/activeDot.svg"
                      : "/icons/nav/inactiveDot.svg"
                  }
                  alt=""
                  className="pl-[2rem]"
                />
                <div
                  className={`${
                    pathname === "/dashboard/commercial/history"
                      ? "active-subgroup-text"
                      : ""
                  } sidebar-subgroup`}
                >
                  {t("sidebar.commercial.billing")}
                </div>
              </Link>
              <Link
                to="/dashboard/commercial/roaming"
                className={`${
                  pathname === "/dashboard/commercial/roaming"
                    ? "active-subgroup"
                    : ""
                } flex flex-row gap-2 relative items-center ml-[1.5rem] mr-[2rem] p-[0.4rem] justify-left`}
              >
                <img
                  src={
                    pathname === "/dashboard/commercial/roaming"
                      ? "/icons/nav/activeDot.svg"
                      : "/icons/nav/inactiveDot.svg"
                  }
                  alt=""
                  className="pl-[2rem]"
                />
                <div
                  className={`${
                    pathname === "/dashboard/commercial/roaming"
                      ? "active-subgroup-text"
                      : ""
                  } sidebar-subgroup`}
                >
                  {t("sidebar.commercial.roaming")}
                </div>
              </Link>
            </>
          )}
        </div>

        {/* Operations */}
        <div className="flex flex-col items-left gap-2">
          <div
            className="flex flex-row gap-5 relative items-left pl-[1.2rem] cursor-pointer"
            onClick={() => handleSelected("Operations")}
          >
            <img src="/icons/nav/operationsIcon.svg" alt="" />
            <span
              className={`${
                pathname.startsWith("/dashboard/operations/") ? "active" : ""
              } sidebar-main-text`}
            >
              {t("sidebar.operations.title")}
            </span>
            <img
              src={
                selected === "Operations"
                  ? "/icons/arrow/arrowUp.svg"
                  : "/icons/arrow/arrowDown.svg"
              }
              alt=""
            />
          </div>
          {selected === "Operations" && (
            <>
              <Link
                to="/dashboard/operations/remote"
                className={`${
                  pathname === "/dashboard/operations/remote"
                    ? "active-subgroup"
                    : ""
                } flex flex-row gap-2 relative items-center ml-[1.5rem] mr-[2rem] p-[0.4rem] justify-left`}
              >
                <img
                  src={
                    pathname === "/dashboard/operations/remote"
                      ? "/icons/nav/activeDot.svg"
                      : "/icons/nav/inactiveDot.svg"
                  }
                  alt=""
                  className="pl-[2rem]"
                />
                <div
                  className={`${
                    pathname === "/dashboard/operations/remote"
                      ? "active-subgroup-text"
                      : ""
                  } sidebar-subgroup`}
                >
                  {t("sidebar.operations.remote")}
                </div>
              </Link>
              <Link
                to="/dashboard/operations/alarms"
                className={`${
                  pathname === "/dashboard/operations/alarms"
                    ? "active-subgroup"
                    : ""
                } flex flex-row gap-2 relative items-center ml-[1.5rem] mr-[2rem] p-[0.4rem] justify-left`}
              >
                <img
                  src={
                    pathname === "/dashboard/operations/alarms"
                      ? "/icons/nav/activeDot.svg"
                      : "/icons/nav/inactiveDot.svg"
                  }
                  alt=""
                  className="pl-[2rem]"
                />
                <div
                  className={`${
                    pathname === "/dashboard/operations/alarms"
                      ? "active-subgroup-text"
                      : ""
                  } sidebar-subgroup`}
                >
                  {t("sidebar.operations.alarms")}
                </div>
              </Link>
              <Link
                to="/dashboard/operations/tickets"
                className={`${
                  pathname === "/dashboard/operations/tickets"
                    ? "active-subgroup"
                    : ""
                } flex flex-row gap-2 relative items-center ml-[1.5rem] mr-[2rem] p-[0.4rem] justify-left`}
              >
                <img
                  src={
                    pathname === "/dashboard/operations/tickets"
                      ? "/icons/nav/activeDot.svg"
                      : "/icons/nav/inactiveDot.svg"
                  }
                  alt=""
                  className="pl-[2rem]"
                />
                <div
                  className={`${
                    pathname === "/dashboard/operations/tickets"
                      ? "active-subgroup-text"
                      : ""
                  } sidebar-subgroup`}
                >
                  {t("sidebar.operations.tickets")}
                </div>
              </Link>
              <Link
                to="/dashboard/operations/loadManagement"
                className={`${
                  pathname === "/dashboard/operations/loadManagement"
                    ? "active-subgroup"
                    : ""
                } flex flex-row gap-2 relative items-center ml-[1.5rem] mr-[2rem] p-[0.4rem] justify-left`}
              >
                <img
                  src={
                    pathname === "/dashboard/operations/loadManagement"
                      ? "/icons/nav/activeDot.svg"
                      : "/icons/nav/inactiveDot.svg"
                  }
                  alt=""
                  className="pl-[2rem]"
                />
                <div
                  className={`${
                    pathname === "/dashboard/operations/loadManagement"
                      ? "active-subgroup-text whitespace-nowrap"
                      : "whitespace-nowrap"
                  } sidebar-subgroup`}
                >
                  {t("sidebar.operations.load")}
                </div>
              </Link>
            </>
          )}
        </div>

        {/* User Management */}
        <Link
          to="/dashboard/userManagement"
          className="flex flex-row gap-5 items-left pl-[1.2rem]"
          onClick={() => handleSelected("")}
        >
          <img src="/icons/nav/managementIcon.svg" alt="" />
          <span
            className={
              pathname === "/dashboard/userManagement"
                ? "active sidebar-main-text no-width"
                : "sidebar-main-text no-width"
            }
          >
            {t("sidebar.userManagement")}
          </span>
        </Link>

        {/* Fees and billing */}
        <Link
          to="/dashboard/fees-billing"
          className="flex flex-row gap-5 items-left pl-[1.2rem]"
          onClick={() => handleSelected("")}
        >
          <img src="/icons/nav/fees&billingIcon.svg" alt="" />
          <span
            className={
              pathname === "/dashboard/fees-billing"
                ? "active sidebar-main-text no-width"
                : "sidebar-main-text no-width"
            }
          >
            {t("sidebar.fees")}
          </span>
        </Link>

        <div className="flex justify-center items-center mt-2">
          <hr className="line" />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
