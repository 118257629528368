import { useState } from "react";
import { loginHandler, onSuccess } from "../../stores/authStore";
import Spinner from "../../shared/spinner/Spinner";
import Popup from "../../shared/popup/Popup";
import LoginLayout from "../LoginLayout";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import "./Login.css";
import LanguageDropdown from "../../shared/LanguageDropdown/LanguageDropdown";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

const Login = () => {
  const { t } = useTranslation();
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const object = {};
    data.forEach((value, key) => (object[key] = value));
    object["rememberMe"] = rememberMe;
    const json = JSON.stringify(object);
    const res = await loginHandler(json, rememberMe);
    if (res.error === "") {
      navigate("/dashboard/overview");
    } else {
      setLoading(false);
      setLoginFailed(true);
    }
  };

  const handleGoogleLogin = async (googleResponse) => {
    setLoading(true);
    console.log("Success: ", googleResponse);
    const result = await onSuccess(googleResponse);
    result.error ? setLoading(false) : navigate("/dashboard/overview");
  };

  const loginRenderPopup = () => {
    return (
      <>
        <img
          src="/icons/correct-incorrect-icons/incorrect-icon.svg"
          alt=""
          className="w-[81px]"
        />
        <div className="popup-main-text">{t("login.popup.password")}</div>
        <div className="popup-other-text">{t("login.popup.tryAgain")}</div>
      </>
    );
  };

  return (
    <LoginLayout>
      <div className="flex flex-col gap-10 items-center flex-1 self-center w-[30rem]">
        <h1 className="log-in">{t("login.header")}</h1>
        <form
          className="flex flex-col gap-6 md:w-[30rem]"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col gap-1">
            <label htmlFor="email" className="login-email-password">
              {t("login.emailLabel")}
            </label>
            <input
              name="email"
              required
              id="email"
              type="email"
              className="login-input"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="password" className="login-email-password">
              {t("login.passwordLabel")}
            </label>
            <div className="relative">
              <input
                name="password"
                required
                id="password"
                type={visiblePassword ? "text" : "password"}
                className="login-input w-[100%]"
              />
              <img
                src={
                  visiblePassword
                    ? "/icons/eye/eye-closed.svg"
                    : "/icons/eye/eye-open.svg"
                }
                alt=""
                className="login-visible-passwords"
                onClick={() => setVisiblePassword(!visiblePassword)}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between items-center">
            <label className="remember-me">
              <input
                type="checkbox"
                name="rememberMe"
                className="mr-1"
                onClick={() => setRememberMe(!rememberMe)}
              />
              {t("login.remember")}
            </label>
            <p className="forgot-password">
              {t("login.forgot")}{" "}
              <a
                className="forgot-password-click-here"
                href="/auth/forgotPassword"
              >
                {t("login.click")}
              </a>
            </p>
          </div>
          <div className="flex flex-col gap-4 justify-center items-center">
            <button type="submit" className="login-button">
              {t("login.continue")}
            </button>
            <GoogleLogin
              text="continue_with"
              type="standard"
              onSuccess={handleGoogleLogin}
              onError={() => {
                console.log("Login Failed");
              }}
              locale={i18n.language}
              auto_select={false}
            />
          </div>
        </form>
        {loginFailed && (
          <Popup
            mainText={loginRenderPopup()}
            closeText="Close"
            confirmFunction={() => undefined}
            closeFunction={() => setLoginFailed(false)}
          ></Popup>
        )}
        {loading && <Spinner />}
        <LanguageDropdown header={false} />
      </div>
    </LoginLayout>
  );
};

export default Login;
