import React, { createContext, ReactNode, useContext } from "react";
import { toast } from "react-toastify";

interface ToastMessageProps {
  text: string;
  type: "success" | "error";
}

const ToastContent: React.FC<ToastMessageProps> = ({ text, type }) => {
  const iconSrc =
    type === "error"
      ? "/icons/toast/toast-negative.svg"
      : "/icons/toast/toast-positive.svg";

  return (
    <div className="flex items-center gap-2.5">
      <img src={iconSrc} alt={type} />
      <p className="text-[16px] font-medium">{text}</p>
    </div>
  );
};


type ToastType = "success" | "error";

interface ToastContextType {
  showToast: (message: string, type?: ToastType) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const showToast = (message: string, type: ToastType = "success") => {
    toast[type](<ToastContent text={message} type={type} />, {
      icon: false,
      closeButton: false,
      style: {
        width: "auto",
        whiteSpace: "nowrap",
      },
    });
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};
