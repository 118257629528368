import { HubConnection } from "@microsoft/signalr";
import { closeConnection, startConnection } from "./signalRHelper";
import { StepState } from "../shared/loadingPopup/LoadingPopup";
import { sleep } from "./timeHelper";

const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;
const TIMEOUT_TIME = 10000;
var newConnection: HubConnection | null = null;

export const closeNewConnection = () => {
    closeConnection(newConnection);
};

export const handleChargerReset = async (selected: number, isHardReset: boolean, setLoadingResetSteps: Function) => {
    try {

        setLoadingResetSteps(prev => {
            prev[0].status = StepState.Progress;
            return [...prev];
        });
        await sleep(1000);
        setLoadingResetSteps(prev => {
            prev[0].status = StepState.Completed;
            prev[1].status = StepState.Progress;
            return [...prev];
        });


        let timeout: NodeJS.Timeout | null = null;

        newConnection = await startConnection(
            `${PUBLIC_BASE_URL}hubs/operations`
        );

        newConnection.on("chargerReset", async (chargerResetSignalRDto) => {
            console.log("chargerReset: ", chargerResetSignalRDto);
            timeout && clearTimeout(timeout);
            await sleep(2000);
            setLoadingResetSteps(prev => {
                prev[0].status = StepState.Completed;
                prev[1].status = StepState.Completed;
                prev[1].progressReady = true;
                return [...prev];
            });
            closeConnection(newConnection);
        });

        const resetChargerDto = {
            chargerId: selected,
            isHardReset: isHardReset,
        };

        await newConnection
            .invoke("SubscribeToReset", resetChargerDto)
            .then(() => {
                console.log('subscription successful')
                timeout = setTimeout(() => {
                    setLoadingResetSteps(prev => {
                        prev[0].status = StepState.Completed;
                        prev[1].status = StepState.Failed;
                        prev[1].progressReady = true;
                        return [...prev];
                    });
                    console.log("Connection closed due to timeout")
                    closeConnection(newConnection);
                }, TIMEOUT_TIME);
            })
            .catch((err) => {
                console.error("Subscription error:", err);
                setLoadingResetSteps(prev => {
                    prev[0].status = StepState.Completed;
                    prev[1].status = StepState.Failed;
                    prev[1].progressReady = true;
                    return [...prev];
                });
                closeConnection(newConnection);
            });
    } catch (err) {
        console.log("Error: ", err);
    }
};

export const handleAvailabilityChange = async (chargerId: number, connectorId: number, availability: 0 | 1, setLoadingAvailabilitySteps: Function) => {
    try {
        setLoadingAvailabilitySteps(prev => {
            prev[0].status = StepState.Progress;
            return [...prev];
        });
        await sleep(1000);
        setLoadingAvailabilitySteps(prev => {
            prev[0].status = StepState.Completed;
            prev[1].status = StepState.Progress;
            return [...prev];
        });

        let midStepError = false;
        let timeout1: NodeJS.Timeout | null = null;
        newConnection = await startConnection(
            `${PUBLIC_BASE_URL}hubs/operations`
        );
        let connectorStatusConnection = await startConnection(
            `${PUBLIC_BASE_URL}hubs/connectorStatus`
        );

        const changeAvailabilityRequestDto = {
            chargerId: chargerId,
            connectorId: connectorId,
            availabilityType: availability,
        };
        await newConnection
            .invoke("SubscribeToChangeAvailability", changeAvailabilityRequestDto)
            .then(() => (timeout1 = setTimeout(() => {
                // setLoadingAvailabilitySteps(prev => {
                //     prev[0].status = StepState.Completed;
                //     prev[1].status = StepState.Failed;
                //     prev[1].progressReady = true;
                //     return [...prev];
                // });
                setLoadingAvailabilitySteps(prev => {
                    prev.forEach(el => {
                        if (el.status === StepState.Progress) {
                            el.status = StepState.Failed;
                            el.progressReady = true;
                        }
                    });
                    return [...prev];
                });
                console.log("Connection closed due to timeout1");
                closeConnection(newConnection);
                closeConnection(connectorStatusConnection);
            }, TIMEOUT_TIME))
            ).then(() => console.log('SubscribeToChangeAvailability, ', changeAvailabilityRequestDto))
            .catch((err) => {
                console.error("Subscription error:", err);
                setLoadingAvailabilitySteps(prev => {
                    prev[0].status = StepState.Completed;
                    prev[1].status = StepState.Failed;
                    prev[1].progressReady = true;
                    return [...prev];
                });
                closeConnection(newConnection);
                closeConnection(connectorStatusConnection);
                midStepError = true;
            });

        if (!midStepError) {
            newConnection.on(
                "availabilityChanged",
                async (availabilityChangedSignalRDto) => {
                    console.log("availabilityChanged:", availabilityChangedSignalRDto);
                    await sleep(2000);
                    setLoadingAvailabilitySteps(prev => {
                        prev[0].status = StepState.Completed;
                        prev[1].status = StepState.Completed;
                        prev[1].progressReady = true;
                        return [...prev];
                    });
                    timeout1 && clearTimeout(timeout1);
                    closeConnection(newConnection);
                }
            );

            await connectorStatusConnection
                .invoke("Subscribe", [connectorId,])
                .then(() => {
                    console.log("Subscribed connector Id: " + connectorId);
                })
                .catch((err) => {
                    console.error("Error while subscribing", err);
                    setLoadingAvailabilitySteps(prev => {
                        prev[0].status = StepState.Completed;
                        prev[1].status = StepState.Completed;
                        prev[1].progressReady = true;
                        prev[2].status = StepState.Failed;
                        prev[2].progressReady = true;
                        return [...prev];
                    });
                    closeConnection(connectorStatusConnection);
                });

            connectorStatusConnection.on("updateConnectorStatus", (updateConnectorStatusSignalRDto) => {
                console.log(`Status for connector ${updateConnectorStatusSignalRDto.connectorId}: ${updateConnectorStatusSignalRDto.status}`);
                setLoadingAvailabilitySteps(prev => {
                    prev[0].status = StepState.Completed;
                    prev[1].status = StepState.Completed;
                    prev[1].progressReady = true;
                    prev[2].status = StepState.Completed;
                    prev[2].progressReady = true;
                    return [...prev];
                });
                closeConnection(connectorStatusConnection);
            });
        }
    } catch (err) {
        console.log('Error: ', err);
    }
};

export const handleUnlock = async (connectorId: number, setLoadingUnlockSteps: Function) => {
    try {
        setLoadingUnlockSteps(prev => {
            prev[0].status = StepState.Progress;
            return [...prev];
        });
        await sleep(1000);
        setLoadingUnlockSteps(prev => {
            prev[0].status = StepState.Completed;
            prev[1].status = StepState.Progress;
            return [...prev];
        });

        let timeout: NodeJS.Timeout | null = null;
        newConnection = await startConnection(
            `${PUBLIC_BASE_URL}hubs/operations`
        );

        await newConnection
            .invoke("SubscribeToUnlockConnector", connectorId)
            .then(
                () => (timeout = setTimeout(() => {
                    setLoadingUnlockSteps(prev => {
                        prev[0].status = StepState.Completed;
                        prev[1].status = StepState.Failed;
                        prev[1].progressReady = true;
                        return [...prev];
                    });
                    console.log("Connection closed due to timeout");
                    closeConnection(newConnection);
                }, TIMEOUT_TIME))
            )
            .catch((err) => {
                console.error("Subscription error:", err);
                setLoadingUnlockSteps(prev => {
                    prev[0].status = StepState.Completed;
                    prev[1].status = StepState.Failed;
                    prev[1].progressReady = true;
                    return [...prev];
                });
                console.log("Connection closed due to timeout");
                closeConnection(newConnection);
            });

        newConnection.on(
            "connectorUnlocked",
            async (connectorUnlockedSignalRDto) => {
                console.log("connectorUnlocked: ", connectorUnlockedSignalRDto);
                timeout && clearTimeout(timeout);
                setLoadingUnlockSteps(prev => {
                    prev[0].status = StepState.Completed;
                    prev[1].status = StepState.Completed;
                    prev[1].progressReady = true;
                    return [...prev];
                });
                closeConnection(newConnection);
            }
        );
    } catch (err) {
        console.log('Error: ', err);
    }
};



export const handleStopSession = async (selected: number, setLoadingStopSessonSteps: Function) => {
    try {
        setLoadingStopSessonSteps(prev => {
            prev[0].status = StepState.Progress;
            return [...prev];
        });
        await sleep(1000);

        let timeout: NodeJS.Timeout | null = null;
        timeout = setTimeout(() => {
            setLoadingStopSessonSteps(prev => {
                prev.forEach(el => {
                    if (el.status === StepState.Progress) {
                        el.status = StepState.Failed;
                        el.progressReady = true;
                    }
                });
                return [...prev];
            });
            console.log("Connection closed due to timeout");
            closeConnection(newConnection);
        }, TIMEOUT_TIME);

        newConnection = await startConnection(
            `${PUBLIC_BASE_URL}hubs/sessions`
        );

        await newConnection
            .invoke("SubscribeToStopSession", selected)
            .then(() => {
                console.log("Subscribing to stop sessionId " + selected);
            })
            .catch((err) => {
                console.error("Subscription error:", err);
                closeConnection(newConnection);
            });

        newConnection.on("sendingStopSessionToCharger", async (transactionStatus) => {
            console.log("sending stop session to charger: ", transactionStatus);
            await sleep(1000);
            setLoadingStopSessonSteps(prev => {
                prev[0].status = transactionStatus.transactionStatus ? StepState.Completed : StepState.Failed;
                prev[1].status = transactionStatus.transactionStatus ? StepState.Progress : StepState.Pending;
                prev[1].progressReady = transactionStatus.transactionStatus ? true : false;
                return [...prev];
            });
        });

        newConnection.on("chargerStoppingSession", async (transactionStatus) => {
            console.log("charger stopping session: ", transactionStatus);
            await sleep(2000);
            setLoadingStopSessonSteps(prev => {
                prev[0].status = StepState.Completed;
                prev[1].status = transactionStatus.transactionStatus ? StepState.Completed : StepState.Failed;
                prev[1].progressReady = true;
                prev[2].status = transactionStatus.transactionStatus ? StepState.Progress : StepState.Pending;
                prev[2].progressReady = transactionStatus.transactionStatus ? true : false;
                return [...prev];
            });
        });

        newConnection.on("paymentInProcess", async (transactionStatus) => {
            await sleep(3000);
            console.log("payment in process: ", transactionStatus);
            setLoadingStopSessonSteps(prev => {
                prev[0].status = StepState.Completed;
                prev[1].status = StepState.Completed;
                prev[1].progressReady = true;
                prev[2].status = transactionStatus.transactionStatus ? StepState.Completed : StepState.Failed;
                prev[2].progressReady = true;
                prev[3].status = transactionStatus.transactionStatus ? StepState.Progress : StepState.Pending;
                prev[3].progressReady = transactionStatus.transactionStatus ? true : false;
                return [...prev];
            });
        });

        newConnection.on("sendingInvoice", async (transactionStatus) => {
            await sleep(4000);
            console.log("sending invoice: ", transactionStatus);
            timeout && clearTimeout(timeout);
            setLoadingStopSessonSteps(prev => {
                prev[0].status = StepState.Completed;
                prev[1].status = StepState.Completed;
                prev[1].progressReady = true;
                prev[2].status = StepState.Completed;
                prev[2].progressReady = true;
                prev[3].status = transactionStatus.transactionStatus ? StepState.Completed : StepState.Failed;
                prev[3].progressReady = true;
                return [...prev];
            });
            closeConnection(newConnection);
        });

    } catch (err) {
        console.log("Error while stopping session: ", err);
    }
};