import React, { ReactNode, useEffect, useRef, useState } from "react";
import "./Threedots.css";
import { useOutsideAlerter } from "../../helpers/componentsHelper";
import { useTranslation } from "react-i18next";

interface Actions {
  text: ReactNode;
  handleClick: Function;
}

enum Size {
  Small,
  Large,
}

interface ThreeDotsProps {
  actions: Actions[];
  size?: Size;
}

const ThreeDots: React.FC<ThreeDotsProps> = ({
  actions,
  size = Size.Small,
}) => {
  const [expanded, setExpanded] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setExpanded(false));

  return (
    <div
      className="flex flex-col justify-start items-end gap-3 relative"
      ref={wrapperRef}
    >
      {expanded ? (
        <img
          src="/icons/threeDots/three-dots-blue.svg"
          alt=""
          className={`threeDots-img ${
            size === Size.Large && "threeDots-img-L"
          }`}
          onClick={() => setExpanded((prev) => !prev)}
        />
      ) : (
        <img
          src="/icons/threeDots/three-dots.svg"
          alt=""
          className={`threeDots-img ${
            size === Size.Large && "threeDots-img-L"
          }`}
          onClick={() => setExpanded((prev) => !prev)}
        />
      )}
      {expanded && (
        <div
          className={`actions-container ${
            size === Size.Large && "actions-container-L"
          }`}
        >
          {actions.map((action, index) => (
            <div
              key={index}
              className="action-hoverable"
              onClick={() => action.handleClick()}
            >
              {action.text}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export { ThreeDots, Size };
