import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AuthStore, checkIfRemembered } from "../stores/authStore";

const LANGUAGE_CACHE_KEY = "user-info";
const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;

const meHandler = async () => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    if (!jwtString) jwtString = checkIfRemembered();
    if (!jwtString) return false;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow" as RequestRedirect,
    };

    // @ts-ignore
    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Users/GetUserInfo`,
      requestOptions
    );
    // if(response.status)
    const userData = await response.json();
    if (!userData || !response.ok) return false;

    AuthStore.setState({ ...AuthStore.state, user: userData });

    return userData;
  } catch (e) {
    console.log("Caught error: ", e);
    return null;
  }
};
const defaultUserInfoQueryOptions: UseQueryOptions<string | null> = {
  queryKey: [LANGUAGE_CACHE_KEY],
  queryFn: meHandler,
  staleTime: 10 * 60 * 1000,
  retry: 1,
  refetchOnWindowFocus: false,
};

type CustomUserInfoQueryOptions = Omit<
  UseQueryOptions<string | null>,
  "queryKey" | "queryFn"
>;

export function useGetUserInfoQuery(options?: CustomUserInfoQueryOptions) {
  return useQuery({
    ...defaultUserInfoQueryOptions,
    ...options,
  });
}
