import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./locales/en/translation.json";
import hr from "./locales/hr/translation.json";
import { AuthStore } from "./stores/authStore";

const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;

export const setAccountLanguage = async (lang: string) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);
    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Users/SetAccountLanguage?lang=${lang}`,
      {
        method: "POST",
        headers: myHeaders,
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to set language: ${response.statusText}`);
    }

    console.log(`Language successfully updated to ${lang}`);
  } catch (error) {
    console.error("Error setting account language:", error);
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      hr: { translation: hr },
    },
    fallbackLng: "en",
    supportedLngs: ["en", "hr"],
    detection: {
      order: ["navigator", "localStorage", "querystring"],
      caches: ["localStorage"],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
