import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./SetPassword.css";
import { setNewPassword } from "../../stores/authStore";
import LoginLayout from "../LoginLayout";
import { useTranslation } from "react-i18next";

const ResetPassword = () => {
  const { t } = useTranslation();
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleConfirmedPassword, setVisibleConfirmedPassword] =
    useState(false);
  const [equalPasswords, setEqualPasswords] = useState(false);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [userId, setUserId] = useState(0);
  const [activationCode, setActivationCode] = useState("");
  const [name, setName] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const userIdParam = Number(searchParams.get("userId"));
    const activationCodeParam = searchParams.get("activationCode");
    const nameParam = searchParams.get("name");

    if (userIdParam && activationCodeParam && nameParam) {
      setUserId(userIdParam);
      setActivationCode(activationCodeParam);
      setName(nameParam);
    } else {
      navigate("/auth/login");
    }
  }, [location.search, navigate]);

  const handlePasswordInput = (name, event) => {
    const value = event.target.value;
    if (name === "password1") {
      setPassword1(value);
    } else if (name === "password2") {
      setPassword2(value);
    }
  };

  useEffect(() => {
    if (
      password1 === password2 &&
      password1.length >= 8 &&
      /[A-Z]/.test(password1) &&
      /[a-z]/.test(password1)
    ) {
      setEqualPasswords(true);
    } else {
      setEqualPasswords(false);
    }
  }, [password1, password2]);

  const handleConfirm = async (event) => {
    event.preventDefault();
    if (equalPasswords) {
      const data = {
        userId: userId,
        activationCode: activationCode,
        newPassword: password1,
        newPasswordRepeated: password2,
      };
      await setNewPassword(JSON.stringify(data));
      navigate("/auth/login");
    }
  };

  return (
    <LoginLayout>
      <div className="flex flex-col gap-5 items-center flex-1 self-center w-[30rem]">
        <h1 className="welcome-name-surname">
          {t("setPassword.welcome")} {name}
        </h1>
        <p className="type-new-password md:w-[30rem]">
          {t("setPassword.subheader")}
        </p>
        <form
          className="flex flex-col gap-6 md:w-[30rem]"
          onSubmit={handleConfirm}
        >
          <div className="flex flex-col gap-1">
            <label htmlFor="newPassword" className="password-reset-password">
              {t("setPassword.newPasswordLabel")}
            </label>
            <div className="relative">
              <input
                name="newPassword"
                required
                id="newPassword"
                type={visiblePassword ? "text" : "password"}
                className="new-password-password-input w-[100%]"
                value={password1}
                onChange={(e) => handlePasswordInput("password1", e)}
              />
              <img
                src={
                  visiblePassword
                    ? "/icons/eye/eye-closed.svg"
                    : "/icons/eye/eye-open.svg"
                }
                alt=""
                className="newpassword-login-visible-passwords"
                onClick={() => setVisiblePassword(!visiblePassword)}
              />
            </div>
            <div className="relative">
              <p className="password-requirements">
                {t("setPassword.condition")}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <label
              htmlFor="confirmedPassword"
              className="password-reset-password"
            >
              {t("setPassword.confirmLabel")}
            </label>
            <div className="relative">
              <input
                name="confirmedPassword"
                required
                id="confirmedPassword"
                type={visibleConfirmedPassword ? "text" : "password"}
                className="new-password-password-input w-[100%]"
                value={password2}
                onChange={(e) => handlePasswordInput("password2", e)}
              />
              <img
                src={
                  visibleConfirmedPassword
                    ? "/icons/eye/eye-closed.svg"
                    : "/icons/eye/eye-open.svg"
                }
                alt=""
                className="newpassword-login-visible-passwords"
                onClick={() =>
                  setVisibleConfirmedPassword(!visibleConfirmedPassword)
                }
              />
            </div>
          </div>
          <div className="flex flex-row justify-center items-center">
            <button
              type="submit"
              className={
                equalPasswords
                  ? "password-reset-btn-active password-reset-btn"
                  : "password-reset-btn"
              }
              disabled={!equalPasswords}
            >
              {t("setPassword.confirm")}
            </button>
          </div>
        </form>
      </div>
    </LoginLayout>
  );
};

export default ResetPassword;
