import React, { useState, useEffect } from "react";
import "./EvseStatusPopup.css";
import Table from "../../shared/table/Table";
import Paging from "../../shared/paging/Paging";
import { EVSEStatus } from "./DashboardOverview";
import { useTranslation } from "react-i18next";
import { useStore } from "react-stores";
import {
  DashboardStore,
  handleGetConnectorsByEVSEStatus,
} from "../../stores/dashboardStore";

interface Props {
  handleExit: () => void;
  data: any[];
  status: EVSEStatus;
  marketIds: number[];
}

const EvseStatusPopup: React.FC<Props> = ({
  handleExit,
  status,
  data,
  marketIds,
}) => {
  const { t } = useTranslation();

  const { connectorsEVSECount, connectorsEVSE } = useStore(DashboardStore);
  const [paging, setPaging] = useState<{ page: number; perPage: number }>({
    page: 1,
    perPage: 10,
  });

  const tableHeaders = [
    t("dashboard.evsePopup.tableHeaders.evseId"),
    t("dashboard.evsePopup.tableHeaders.currentType"),
    t("dashboard.evsePopup.tableHeaders.connectorType"),
    t("dashboard.evsePopup.tableHeaders.locationName"),
  ];

  useEffect(() => {
    if (paging) {
      handleGetConnectorsByEVSEStatus(
        status,
        marketIds,
        paging.page,
        paging.perPage
      );
    }
  }, [paging.page, paging.perPage]);

  return (
    <>
      <div className="modal-background" onClick={() => handleExit()} />
      <div className="absolute-evse-status pt-2">
        <div className="flex flex-col items-center justify-start gap-3">
          <div className="flex justify-end items-center w-full p-5">
            <img
              src="/icons/exit/cross-big.svg"
              alt=""
              onClick={() => handleExit()}
              className="cursor-pointer"
            />
          </div>
          <div className="horizontal-gray-line w-full"></div>
          <div className="flex flex-col justify-start items-stretch w-full p-10 gap-6">
            <div className="flex justify-start items-center gap-3">
              <div
                className={`evse-circle ${
                  status === "Available"
                    ? "evse-green-circle"
                    : status === "Unavailable"
                    ? "evse-yellow-circle"
                    : status === "OutOfService"
                    ? "evse-red-circle"
                    : ""
                }`}
              ></div>
              <p className="availableText">
                {status === "Available"
                  ? t("dashboard.evsePopup.availableLabel")
                  : status === "Unavailable"
                  ? t("dashboard.evsePopup.occupiedLabel")
                  : status === "OutOfService"
                  ? t("dashboard.evsePopup.outOfOrderLabel")
                  : ""}{" "}
                EVSE{t("dashboard.evsePopup.s")}
              </p>
            </div>
            <div className="h-[2.5px] bg-[#B5BAC680] w-full mt-3"></div>
            <div className="flex flex-col justify-start items-stretch w-full">
              <Table
                tableHeaders={tableHeaders}
                tableRowOrder={tableRowOrder}
                selected={0}
                handleSelection={() => undefined}
                tableRows={connectorsEVSE}
                preloadRowNumber={10}
                noMargin={true}
              />
              <Paging
                tableSize={connectorsEVSECount}
                pageChangeFunc={(page, perPage) =>
                  setPaging({ page: page, perPage: perPage })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EvseStatusPopup;

const tableRowOrder = [
  "evseId",
  "electricityCurrent",
  "connectorType",
  "locationName",
];
