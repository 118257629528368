import React, { useEffect, useRef, useState } from "react";
import "./CurrencyDropdown.css";
import { useOutsideAlerter } from "../../helpers/componentsHelper";
import {
  CommonStore,
  GetCurrenciesForDropdown,
} from "../../stores/commonStore";
import { useStore } from "react-stores";
import { useTranslation } from "react-i18next";

interface CurrencyDropdownProps {
  handleSelection: Function;
  availableCurrencies: string[];
}

const CurrencyDropdown: React.FC<CurrencyDropdownProps> = ({
  handleSelection,
  availableCurrencies,
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [selectedCurrency, setSelectedCurrencies] = useState("");
  const { currenciesForDropdown } = useStore(CommonStore);

  useEffect(() => {
    GetCurrenciesForDropdown();
  }, []);

  useEffect(() => {
    if (
      availableCurrencies.length > 0 &&
      (!selectedCurrency || !availableCurrencies.includes(selectedCurrency))
    ) {
      setSelectedCurrencies(availableCurrencies[0]);
      handleSelection(availableCurrencies[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableCurrencies]);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setExpanded(false));

  const handleCurrencyChange = (currency: string) => {
    if (selectedCurrency && selectedCurrency !== currency) {
      setSelectedCurrencies(currency);
      handleSelection(currency);
    }
  };

  return (
    <div
      className="flex flex-col justify-start items-end gap-3 relative"
      ref={wrapperRef}
    >
      <button
        className={`currency-selection-btn ${
          expanded ? "currency-selection-btn-active" : ""
        }`}
        onClick={() => {
          setExpanded((prev) => !prev);
        }}
      >
        <p>{t("currency")}</p>
        <img
          src={
            expanded
              ? "/icons/arrow/arrowUpWhite.svg"
              : "/icons/arrow/arrowDownDarkBlue.svg"
          }
          alt=""
        />
      </button>
      {expanded && (
        <div className="currency-dropdown-container">
          {currenciesForDropdown
            .filter((curr) => availableCurrencies.includes(curr.iSO))
            .map((currency) => (
              <div
                key={currency.id}
                className={`currency-dropdown-hoverable ${
                  selectedCurrency === currency.iSO &&
                  " currency-dropdown-selected"
                }`}
                onClick={() => handleCurrencyChange(currency.iSO)}
              >
                <div className="flex flex-row justify-start items-center gap-2">
                  {selectedCurrency === currency.iSO ? (
                    <div className="radio-button-border radio-button-border-active">
                      <div className="radio-button-circle" />
                    </div>
                  ) : (
                    <div className="radio-button-border" />
                  )}
                  <p>{currency.iSO}</p>
                </div>
                <p
                  className={`${
                    selectedCurrency === currency.iSO
                      ? "currency-symbol-blue"
                      : ""
                  }`}
                >
                  {currency.symbol}
                </p>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default CurrencyDropdown;
