import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  PricingStore,
  handleGetConnectorsForMarket,
  handlePricingGroupCreation,
} from "../../../stores/pricingStore";
import {
  MarketStore,
  handleGetMarketsForDropdown,
} from "../../../stores/marketStore";
import { useStore } from "react-stores";
import Popup from "../../../shared/popup/Popup";
import { useNavigate } from "react-router-dom";
import "./PricingPageNew.css";
import Table from "../../../shared/table/Table";
import { selectStyling } from "../../../helpers/componentsHelper";
import {
  IPricingOption,
  IPricingOptionValues,
} from "../../../interfaces/IPrices";
import { el } from "date-fns/locale";
import { useToast } from "../../../shared/toast/ToastContent";
import Spinner from "../../../shared/spinner/Spinner";
import Paging from "../../../shared/paging/Paging";
import { useTranslation } from "react-i18next";

interface PricingGroup {
  companyMarketId: number;
  name: string;
  pricePerKwh?: number;
  gracePeriod?: number;
  startFee?: number;
  parkingFeePrice?: number;
  connectorIds: number[];
}

const PricingPageNew: React.FC = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<number[]>([]);
  const [paging, setPaging] = useState({ page: 1, perPage: 10 });
  const [sort, setSort] = useState({ field: "", descending: true });
  const [currencies, setCurrencies] = useState<{ [key: number]: string }>({});
  const [newPricingGroup, setNewPricingGroup] = useState<PricingGroup>({
    companyMarketId: 0,
    name: "",
    pricePerKwh: undefined,
    gracePeriod: undefined,
    parkingFeePrice: undefined,
    startFee: undefined,
    connectorIds: [],
  });
  const [createPricingGroupPopup, setCreatePricingGroupPopup] = useState(false);
  const [requirementsForSaveNew, setRequirementsForSaveNew] = useState(false);
  const [selectedPricingOptions, setSelectedPricingOptions] = useState<
    IPricingOption[]
  >([]);

  const { unassignedConnectorsSize, unassignedConnectors } =
    useStore(PricingStore);
  const { marketsForDropdown } = useStore(MarketStore);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { showToast } = useToast();

  const availablePricingOptions: ("pricePerMinute" | "sessionStartPrice")[] = [
    "pricePerMinute",
    "sessionStartPrice",
  ];

  const tableHeaders = [
    t("commercial.pricingPage.tableHeaders.connectorId"),
    t("commercial.pricingPage.tableHeaders.market"),
    t("commercial.pricingPage.tableHeaders.connectorType"),
    t("commercial.pricingPage.tableHeaders.electricityCurrent"),
    t("commercial.pricingPage.tableHeaders.address"),
  ];

  const tableRowOrder = [
    "connectorName",
    "companyMarketName",
    "connectorType",
    "current",
    "street",
  ];

  useEffect(() => {
    const getConnectors = async () => {
      if (newPricingGroup.companyMarketId) {
        await handleGetConnectorsForMarket(
          newPricingGroup.companyMarketId,
          paging.page,
          paging.perPage,
          sort.field,
          sort.descending
        );
      } else {
        PricingStore.setState({
          ...PricingStore.state,
          unassignedConnectors: [],
          unassignedConnectorsSize: 0,
        });
      }
    };
    getConnectors();
  }, [newPricingGroup.companyMarketId, paging, sort]);

  useEffect(() => {
    handleGetMarketsForDropdown();
    return () => {
      PricingStore.setState({
        ...PricingStore.state,
        unassignedConnectors: [],
        unassignedConnectorsSize: 0,
      });
    };
  }, []);

  useEffect(() => {
    if (marketsForDropdown.length > 0) {
      let tempCurrencies: { [key: number]: string } = {};
      marketsForDropdown.forEach((market) => {
        tempCurrencies[market.id] = market.currencyIso;
      });
      setCurrencies(tempCurrencies);
    }
  }, [marketsForDropdown]);

  useEffect(() => {
    if (
      newPricingGroup.companyMarketId &&
      newPricingGroup.name.length > 0 &&
      newPricingGroup.pricePerKwh
    ) {
      setRequirementsForSaveNew(true);
    } else {
      setRequirementsForSaveNew(false);
    }
  }, [newPricingGroup]);

  const handleSelection = (id: number) => {
    if (!selected.includes(id)) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((s) => s !== id));
    }
  };

  const saveNewPricingGroup = async () => {
    // console.log(selectedPricingOptions);
    setCreatePricingGroupPopup(false);
    setLoading(true);
    let temp = { ...newPricingGroup };
    temp.pricePerKwh = Number(temp.pricePerKwh);
    temp.connectorIds = selected.length > 0 ? selected : [];
    if (selectedPricingOptions.length > 0) {
      const pricePerMinComponent = selectedPricingOptions.find(
        (el) => el.type === "pricePerMinute"
      );
      if (
        pricePerMinComponent &&
        pricePerMinComponent.values.gracePeriod &&
        pricePerMinComponent.values.parkingFee
      ) {
        temp.gracePeriod = Number(pricePerMinComponent.values.gracePeriod);
        temp.parkingFeePrice = Number(pricePerMinComponent.values.parkingFee);
      }
      const sessionStartComponent = selectedPricingOptions.find(
        (el) => el.type === "sessionStartPrice"
      );
      if (
        sessionStartComponent &&
        sessionStartComponent.values.sessionStartPrice
      )
        temp.startFee = Number(sessionStartComponent.values.sessionStartPrice);
    }
    const res = await handlePricingGroupCreation(JSON.stringify(temp));
    setLoading(false);
    if (res) {
      showToast(t("commercial.pricingPage.toast.success"), "success");
      navigate("/dashboard/commercial/pricing");
    } else {
      showToast(t("commercial.pricingPage.toast.success"), "error");
    }
  };

  const mainText = () => {
    return (
      <>
        <div className="flex flex-col items-start gap-2">
          <div className="remote-popup-main-text">
            {t("commercial.pricingPage.createPopup.text")}
          </div>
          <div className="flex flex-row justify-start items-center gap-3">
            <img src="/icons/nav/activeDot.svg" alt="" />
            <div className="text-[16px] font-medium text-[#111F47]">
              {t("commercial.pricingPage.createPopup.groupName")}{" "}
              {newPricingGroup.name}
            </div>
          </div>
          <div className="h-[2.5px] bg-[#E7E9ED] w-[100%] my-2"></div>
        </div>
      </>
    );
  };

  const handlePriceChange = (value) => {
    if (/^\d*\.?\d{0,2}$/.test(value))
      setNewPricingGroup((prevState) => ({
        ...prevState,
        pricePerKwh: value,
      }));
  };

  const handleErrorMessaging = () => {
    const gracePeriodValue = gracePeriodInput.value.trim();
    const parkingFeeValue = parkingFeeInput.value.trim();

    const messageDiv1 = document.getElementById("parkingFeeDiv");
    const messageDiv2 = document.getElementById("gracePeriodDiv");

    if (gracePeriodValue && !parkingFeeValue) {
      messageDiv1!.style.visibility = "visible";
    } else if (!gracePeriodValue && parkingFeeValue) {
      messageDiv2!.style.visibility = "visible";
    } else {
      messageDiv1!.style.visibility = "hidden";
      messageDiv2!.style.visibility = "hidden";
    }
  };

  const handleMarketChange = (e) => {
    setNewPricingGroup((prevState) => ({
      ...prevState,
      companyMarketId: e?.value || 0,
    }));
    setSelected([]);
  };

  const handleSort = async (sortingField, sortingMethod) => {
    if (sortingMethod === "default") {
      setSort({ field: "", descending: true });
    } else {
      setSort({
        field: sortingField,
        descending: sortingMethod === "descending" ? true : false,
      });
    }
  };

  const addNewPricingComponent = () => {
    if (selectedPricingOptions.length < availablePricingOptions.length) {
      const option = availablePricingOptions.find(
        (option) => !selectedPricingOptions.find((el) => el.type === option)
      );
      if (option) {
        const newOption: IPricingOption = {
          type: option,
          values: {} as IPricingOptionValues,
        };
        const prev = [...selectedPricingOptions];
        prev.push(newOption);
        setSelectedPricingOptions((prev) => prev.concat(newOption));
      }
    }
  };

  const removePricingComponent = (index: number) => {
    const optionsCopy = [...selectedPricingOptions];
    optionsCopy.splice(index, 1);
    setSelectedPricingOptions(optionsCopy);
  };

  const changePricingOption = (
    index: number,
    newType: "pricePerMinute" | "sessionStartPrice"
  ) => {
    const optionsCopy = [...selectedPricingOptions];
    optionsCopy[index].type = newType;
    optionsCopy[index].values = {} as IPricingOptionValues;
    setSelectedPricingOptions(optionsCopy);
  };

  const handlePricingComponentChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      const optionsCopy = [...selectedPricingOptions];
      optionsCopy[index].values[name] = value;
      setSelectedPricingOptions(optionsCopy);
    }
  };

  const gracePeriodInput = document.getElementById(
    "gracePeriod"
  ) as HTMLInputElement;
  const parkingFeeInput = document.getElementById(
    "parkingFee"
  ) as HTMLInputElement;
  gracePeriodInput?.addEventListener("input", handleErrorMessaging);
  parkingFeeInput?.addEventListener("input", handleErrorMessaging);

  return (
    <div className="flex-1 p-3.5 flex flex-col gap-3.5 items-stretch main-wrapper">
      <div className="bg-white card p-5">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row justify-start items-center flex-wrap gap-6">
            <div
              className="cursor-pointer"
              onClick={() => navigate(`/dashboard/commercial/pricing`)}
            >
              <img src="/icons/arrow/arrowLeft.svg" alt="" />
            </div>
            <div className="pricing-groups-span">
              {t("commercial.pricingPage.title")}
            </div>
          </div>
          <button
            className={`${
              requirementsForSaveNew && "create-new-pricing-group-btn-active"
            } create-new-pricing-group-btn`}
            disabled={!requirementsForSaveNew}
            onClick={() => setCreatePricingGroupPopup(true)}
          >
            {t("commercial.pricingPage.confirm")}
          </button>
        </div>
      </div>
      <div className="bg-white card p-5">
        <div className="flex flex-col gap-5">
          <div className="flex flex-row items-center gap-5">
            <div className="flex flex-col justify-stretch items-center gap-2 w-64">
              <div className="create-edit-header">
                {t("commercial.pricingPage.market")}
              </div>
              <div className="w-full">
                <Select
                  options={marketsForDropdown.map((market) => {
                    return {
                      value: market.id,
                      label: market.name,
                    };
                  })}
                  placeholder="Select Market"
                  onChange={(e) => handleMarketChange(e)}
                  styles={selectStyling}
                />
              </div>
            </div>
            <div className="flex flex-col justify-stretch items-center gap-2 w-64">
              <div className="create-edit-header">
                {t("commercial.pricingPage.groupName")}
              </div>
              <input
                type="text"
                value={newPricingGroup.name}
                onChange={(e) =>
                  setNewPricingGroup((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }))
                }
                placeholder="Group Name"
                className="create-edit-input"
              />
            </div>
            <div className="flex flex-col justify-stretch items-center gap-2 w-64">
              <div className="create-edit-header">
                {t("commercial.pricingPage.electricityPrice")}
              </div>
              <div className="create-edit-input flex flex row justif-start items-center">
                <input
                  type="number"
                  name="pricePerKwh"
                  placeholder="Electricity Price"
                  min="0"
                  value={newPricingGroup.pricePerKwh}
                  onChange={(e) => handlePriceChange(e.target.value)}
                  className="eletr-price-input"
                />
                <div>
                  {currencies[newPricingGroup.companyMarketId]
                    ? currencies[newPricingGroup.companyMarketId] + "/kWh"
                    : ""}
                </div>
              </div>
            </div>
          </div>
          {selectedPricingOptions.map((option, index) => (
            <div
              className="flex flex-col relative border-2 border-[#B5BAC680] p-4 gap-4"
              key={index}
            >
              <div className="flex items-center gap-5 mb-1">
                <p className="select-pricing-component-text">
                  {t("commercial.pricingPage.selectComponent")}
                </p>
                <div className="flex gap-2 items-center">
                  {option.type === "pricePerMinute" ? (
                    <img
                      src="/icons/correct-incorrect-icons/correct-icon-squared.svg"
                      alt=""
                    />
                  ) : selectedPricingOptions.find(
                      (el) => el.type === "pricePerMinute"
                    ) ? (
                    <div className="checker-border-disabled" />
                  ) : (
                    <div
                      className="checker-border"
                      onClick={() =>
                        changePricingOption(index, "pricePerMinute")
                      }
                    />
                  )}
                  <p
                    className={`pricing-component-text ${
                      option.type === "pricePerMinute"
                        ? "text-[#1E4CDC] "
                        : selectedPricingOptions.find(
                            (el) => el.type === "pricePerMinute"
                          )
                        ? "text-[#B5BAC6]"
                        : "text-[#111F47]"
                    }`}
                  >
                    {t("commercial.pricingPage.pricePerMin")}
                  </p>
                </div>
                <div className="flex gap-2 items-center">
                  {option.type === "sessionStartPrice" ? (
                    <img
                      src="/icons/correct-incorrect-icons/correct-icon-squared.svg"
                      alt=""
                    />
                  ) : selectedPricingOptions.find(
                      (el) => el.type === "sessionStartPrice"
                    ) ? (
                    <div className="checker-border-disabled" />
                  ) : (
                    <div
                      className="checker-border"
                      onClick={() =>
                        changePricingOption(index, "sessionStartPrice")
                      }
                    />
                  )}
                  <p
                    className={`pricing-component-text ${
                      option.type === "sessionStartPrice"
                        ? "text-[#1E4CDC] "
                        : selectedPricingOptions.find(
                            (el) => el.type === "sessionStartPrice"
                          )
                        ? "text-[#B5BAC6]"
                        : "text-[#111F47]"
                    }`}
                  >
                    {t("commercial.pricingPage.sessionStartPrice")}
                  </p>
                </div>
              </div>
              {option.type === "pricePerMinute" ? (
                <div className="flex flex-row gap-5">
                  <div className="flex flex-col justify-stretch items-center gap-2 w-64">
                    <div className="create-edit-header">
                      {t("commercial.pricingPage.gracePeriod")}
                    </div>
                    <div className="create-edit-input flex flex row justif-start items-center">
                      <input
                        id="gracePeriod"
                        type="number"
                        name="gracePeriod"
                        value={option.values.gracePeriod}
                        min={0}
                        onChange={(e) => handlePricingComponentChange(index, e)}
                        placeholder={t("commercial.pricingPage.gracePeriod")}
                        className="eletr-price-input mr-auto"
                      />
                      <div>min</div>
                    </div>
                    <div className="text-[10px] flex flex-col gap-1 pl-1">
                      <p className="text-[#B5BAC6]">
                        {t("commercial.pricingPage.graceText")}
                      </p>
                      <p
                        id="gracePeriodDiv"
                        className="text-[#ED1F0E] font-semibold invisible"
                      >
                        {t("commercial.pricingPage.bothInputs")}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col justify-stretch items-center gap-2 w-64">
                    <div className="create-edit-header">
                      {t("commercial.pricingPage.perMinuteText")}
                    </div>
                    <div className="create-edit-input flex flex row justif-start items-center">
                      <input
                        id="parkingFee"
                        type="number"
                        min={0}
                        name="parkingFee"
                        value={option.values.parkingFee}
                        onChange={(e) => handlePricingComponentChange(index, e)}
                        placeholder={t("commercial.pricingPage.parkingFee")}
                        className="eletr-price-input mr-auto"
                      />
                      <div>
                        {currencies[newPricingGroup.companyMarketId] || ""}
                      </div>
                    </div>
                    <div className="text-[10px] flex flex-col gap-1 ml-1 w-full">
                      <p className="text-[#B5BAC6]">
                        <br />
                      </p>
                      <p
                        id="parkingFeeDiv"
                        className="text-[#ED1F0E] font-semibold invisible"
                      >
                        {t("commercial.pricingPage.bothInputs")}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-row gap-5">
                  <div className="flex flex-col justify-stretch items-center gap-2 w-64">
                    <div className="create-edit-header">
                      {t("commercial.pricingPage.sessionStartPriceLower")}
                    </div>
                    <div className="create-edit-input flex flex row justif-start items-center">
                      <input
                        type="number"
                        min={0}
                        name="sessionStartPrice"
                        value={option.values.sessionStartPrice}
                        onChange={(e) => handlePricingComponentChange(index, e)}
                        placeholder={t(
                          "commercial.pricingPage.sessionStartPriceLower"
                        )}
                        className="eletr-price-input mr-auto"
                      />
                      <div>
                        {currencies[newPricingGroup.companyMarketId] || ""}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                className="absolute bottom-4 right-5 flex items-center gap-1 cursor-pointer"
                onClick={() => removePricingComponent(index)}
              >
                <img src="/icons/delete/trashIconBlue.svg" alt="alt" />
                <div className="text-[14px] text-[#1E4CDC]">
                  {t("commercial.pricingGroup.delete")}
                </div>
              </div>
            </div>
          ))}
          {selectedPricingOptions.length < availablePricingOptions.length && (
            <div
              className="flex justify-start items-center gap-2 cursor-pointer px-0.5"
              onClick={addNewPricingComponent}
            >
              <img src="/icons/plus/plusDarkBlue.svg" alt="" />
              <p className="add-pricing-component-text">
                {t("commercial.pricingPage.addNewComponent")}
              </p>
            </div>
          )}
          <div className="flex flex-row justify-between items-center flex-wrap p-4 bg-[#F3F4F6]">
            <div className="conn-overview-span">
              {t("commercial.pricingPage.selectBelow")}
            </div>
            {selected.length > 0 && (
              <div className="flex flex-row justify-start items-center gap-2">
                <img src="/icons/threeDots/notification-dot.svg" alt="" />
                <div className="selected-x-connectors">
                  {t("commercial.pricingPage.selected")}{" "}
                  <span className="num-of-connectors">{selected.length}</span>{" "}
                  {t("commercial.pricingPage.connector")}(
                  {t("commercial.pricingPage.s")})
                </div>
              </div>
            )}
          </div>
          <Table
            tableHeaders={tableHeaders}
            tableRows={unassignedConnectors}
            tableRowOrder={tableRowOrder}
            sortColumns={[
              "Connector ID",
              "Connector Type",
              "Eletricity Current",
              "Address",
            ]}
            selected={selected}
            handleSelection={(id) => handleSelection(id)}
            handleSort={handleSort}
          />
          <Paging
            tableSize={unassignedConnectorsSize}
            pageChangeFunc={(page, perPage) =>
              setPaging({ page: page, perPage: perPage })
            }
          />
        </div>
      </div>

      {createPricingGroupPopup && (
        <Popup
          mainText={mainText()}
          confirmText="Yes - Create"
          closeText="No - Continue editing"
          confirmFunction={saveNewPricingGroup}
          closeFunction={setCreatePricingGroupPopup}
        />
      )}
      {loading && <Spinner />}
    </div>
  );
};

export default PricingPageNew;
